import styled from 'styled-components';

import {black, darkGrey} from '../../../style/colors';

const Button = styled.button`
  background-color: transparent;
  transition: all 0.3s ease-in-out 0s;
  color: ${black};
  font-size: 20px;
  border: none;
  cursor: pointer;
  height: 30px;
  &:hover {
    color: ${darkGrey};
  }
`;

export default Button;
