import styled from 'styled-components';

import * as colors from '../../../style/colors';

const Option = styled.div`
  width: 100%;
  padding: ${({noPadding}) => (noPadding ? '10px 15px' : '15px')};
  cursor: pointer;
  color: ${colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};

  &:hover {
    color: ${({hoverColor}) => colors[hoverColor] || hoverColor};
    background: ${({hoverBackground}) =>
      colors[hoverBackground] || hoverBackground};
  }
`;

export default Option;
