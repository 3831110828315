const columns = [
  {
    key: 'check',
    span: 1,
  },
  {
    key: 'catalogNo',
    label: 'Kataloška številka',
    sortable: false,
    span: 2,
  },
  {
    key: 'name',
    label: 'Naziv',
    sortable: true,
    span: 3,
  },
  {
    key: 'requestedQuantity',
    label: 'Zahtevana količina',
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'price',
    label: 'Skupna cena z ddv',
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'confirmedQuantity',
    label: 'Potrjena količina',
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'deliveryDate',
    label: 'Datum predvidene dostave',
    sortable: true,
    align: 'center',
    span: 2,
  },
  {
    key: 'actions',
    label: '',
    span: 1,
  },
];

export default columns;
