import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Icon from './components/Icon';

const EmailButton = ({id, onClick}) => (
  <Container>
    <Icon
      onClick={() => {
        onClick(id);
      }}
      className="mdi mdi-email-send-outline"
    />
  </Container>
);

EmailButton.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export default EmailButton;
