import styled from "styled-components";

// Colors
import { darkGrey, white } from "../../../style/colors";

const SortIcon = styled.i`
  display: inline-block;
  margin-left: 12px;
  color: ${({ selected }) => (selected ? white : darkGrey)};
  font-size: 16px;
  vertical-align: top;
  pointer-events: none;
  transform: translateY(-2px);
`;

export default SortIcon;
