import styled from "styled-components";
import PropTypes from "prop-types";

// Colors
import * as colors from "../../style/colors";

const Text = styled.span`
  color: ${({ color }) => (color === "inherit" ? "inherit" : colors[color])};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ fontSize }) => fontSize};
  text-align: ${({ align }) => align};
`;

Text.propTypes = {
  color: PropTypes.oneOf(["inherit", ...Object.keys(colors)]),
  weight: PropTypes.oneOf(["inherit", "normal", "bold"]),
  fontSize: PropTypes.string,
  align: PropTypes.oneOf(["inherit", "left", "center", "right"]),
};

Text.defaultProps = {
  color: "inherit",
  weight: "inherit",
  fontSize: "inherit",
  align: "inherit",
};

export default Text;
