import doc1 from '../../assets/documents/Continental_zimske_202324.pdf';
import doc2 from '../../assets/documents/Semperit_zimske_202324.pdf';
import doc3 from '../../assets/documents/Barum_zimske_202324.pdf';
import doc4 from '../../assets/documents/Continental_gume_2024_celoletne.pdf';

const documents = [
  {
    name: 'Continental (zima)',
    url: doc1,
    type: 'pdf',
  },
  {
    name: 'Continental (celoletne)',
    url: doc4,
    type: 'pdf',
  },
  {
    name: 'Semperit (zima)',
    url: doc2,
    type: 'pdf',
  },
  {
    name: 'Barum (zima)',
    url: doc3,
    type: 'pdf',
  },
];

export default documents;
