// Error
import serverError from '../errors/server.error.api';

// Lib
import getResponseData from '../lib/getResponseData.lib.api';

const isApiError = async (response) => {
  if (response.status < 200 || response.status >= 300) {
    const data = await getResponseData(response);
    throw serverError(data);
  }
};

export default isApiError;
