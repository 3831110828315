import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { transitions } from "polished";

// Colors
import { black, orange, red, darkGrey } from "../../../style/colors";

// Components
const Container = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ hasValue }) => (hasValue ? black : darkGrey)};
  position: relative;
  overflow: hidden;
`;

const Highlighter = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ error }) => (error ? red : orange)};
  position: absolute;
  top: 0px;
  left: 0px;
  transform: ${({ error, focused }) =>
    error || focused ? "none" : "translateX(-100%)"};
  ${transitions("transform .3s ease-in-out")};
`;

const Border = ({ hasValue, focused, error }) => (
  <Container hasValue={hasValue}>
    <Highlighter focused={focused} error={error} />
  </Container>
);

Border.propTypes = {
  hasValue: PropTypes.bool,
  focused: PropTypes.bool,
  error: PropTypes.bool,
};

export default Border;
