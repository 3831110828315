const getHighestDeliveryDate = (orderLines) => {
  // console.log(orderLines);
  // const dates = orderLines.map((o) => {
  //   console.log(o.deliveryDate);
  //   const d = toDate(o.deliveryDate);
  //   return d;
  // });
  // console.log(dates);
  return orderLines[0].deliveryDate;
};

export default getHighestDeliveryDate;
