import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const vehicle = ({
  vehicleBrand = '',
  vehicleModel = '',
  vehicleYear = '',
  vehicleVersion = '',
  vehicleFitment = '',
  eanNumbers = [],
}) =>
  set({
    vehicleBrand,
    vehicleModel,
    vehicleYear,
    vehicleVersion,
    vehicleFitment,
    eanNumbers,
  });
