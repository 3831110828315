const storageSetup = (storage) => (key) => ({
  get: () => {
    try {
      return storage.getItem(key);
    } catch (error) {
      /* eslint-disable  no-console */
      console.error(`Could not get "${key}" from storage.`);
      /* eslint-enable  no-console */
      return null;
    }
  },
  add: (value) => {
    try {
      storage.setItem(key, value);
    } catch (error) {
      /* eslint-disable  no-console */
      console.error(`Could not save "${key}" to storage.`);
      /* eslint-enable  no-console */
    }
  },
  remove: () => {
    try {
      storage.removeItem(key);
    } catch (error) {
      /* eslint-disable  no-console */
      console.error(`Could not remove "${key}" from storage.`);
      /* eslint-enable  no-console */
    }
  },
});

export default storageSetup;
