import styled, {css} from 'styled-components';

// Colors
import {grey, transparent, red, darkGrey} from '../../../style/colors';

const InputWrap = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  ${({square, error, disabled}) =>
    square
      ? css`
          height: 56px;
          position: relative;
          border-style: solid;
          border-width: 1px;
          border-color: ${error ? red : darkGrey};
          pointer-events: ${disabled ? 'none' : 'auto'};
          background: ${disabled ? grey : transparent};
          opacity: ${disabled ? 0.6 : 1};
        `
      : css`
          padding-top: 20px;
          opacity: ${disabled ? 0.6 : 1};
        `}
`;

export default InputWrap;
