import styled from 'styled-components';
import {black} from '../../../style/colors';

const Name = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: ${black};
`;

export default Name;
