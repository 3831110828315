import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  position: relative;
  padding: ${({dynamicError}) =>
    dynamicError ? '20px 0px 0px 0px' : '20px 0px'};
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  opacity: ${({disabled}) => (disabled ? 0.7 : 1)};
`;

export default Container;
