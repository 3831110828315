import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';
import Icon from './components/Icon';
import Action from './components/Action';

const getIcon = (type, loading) => {
  if (loading) return 'loading mdi-spin';

  switch (type) {
    case 'error':
      return 'close-circle';
    case 'warning':
      return 'alert-circle';
    case 'info':
      return 'information';
    case 'success':
      return 'check-circle';
    default:
      return 'close-circle';
  }
};

const Alert = ({
  type,
  children,
  action,
  label,
  outline,
  flat,
  loading,
  layout,
}) => (
  <Container type={type} flat={flat} boxStyle={layout}>
    <Icon
      className={`mdi mdi-${getIcon(type, loading)}${
        outline && !loading ? '-outline' : ''
      }`}
    />
    <Content>{children}</Content>
    {action !== null && <Action onClick={action}>{label}</Action>}
  </Container>
);

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
  children: PropTypes.node.isRequired,
  action: PropTypes.func,
  label: PropTypes.node,
  outline: PropTypes.bool,
  flat: PropTypes.bool,
  loading: PropTypes.bool,
  layout: PropTypes.oneOf(['round', 'square', 'flat']),
};

Alert.defaultProps = {
  action: null,
  label: 'undo',
  outline: false,
  flat: false,
  loading: false,
  layout: 'round',
};

export default Alert;
