import React, {Component} from 'react';

const subscription = (WrappedComponent) =>
  class extends Component {
    componentWillUnmount() {
      this.subscribers.forEach((unsubscribe) => unsubscribe());
    }

    subscribers = [];

    subscribe = (...events) => {
      this.subscribers = [...events];
    };

    render() {
      return <WrappedComponent {...this.props} subscribe={this.subscribe} />;
    }
  };

export default subscription;
