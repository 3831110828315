import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Table from '../../../components/Table/Table';
import {Row, Column} from '../../../components/Grid/Grid';

// Columns
import columns from './columns';

const Departments = ({
  search,
  department,
  departments,
  onBack,
  onDepartment,
  onSearch,
}) => (
  <Container>
    {!!department && (
      <Row margin>
        <Column>
          <Button onClick={onBack}>Nazaj</Button>
        </Column>
      </Row>
    )}
    <Row margin>
      <Column>Za nadaljevanje izberite svojo poslovalnico</Column>
    </Row>
    <Row margin>
      <Column>
        <Input value={search} onChange={onSearch} rounded={false}>
          Iskanje poslovalnic
        </Input>
      </Column>
    </Row>
    <Row>
      <Column>
        <Table
          columns={columns}
          size="medium"
          headerSize="large"
          noMargin={false}
          labelSpace={false}
          theme="grey"
        >
          {(TableRow) =>
            departments.map((availableDepartment) => (
              <TableRow
                key={availableDepartment._id}
                rowClick={onDepartment(availableDepartment)}
              >
                {(TableColumn) => [
                  <TableColumn key="name">
                    {availableDepartment.name}
                  </TableColumn>,
                ]}
              </TableRow>
            ))
          }
        </Table>
      </Column>
    </Row>
  </Container>
);

Departments.propTypes = {
  search: PropTypes.string,
  department: PropTypes.object,
  departments: PropTypes.array,
  onBack: PropTypes.func,
  onDepartment: PropTypes.func,
  onSearch: PropTypes.func,
};

export default Departments;
