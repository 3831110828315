import styled from 'styled-components';

const Container = styled.div`
  background: #fff;
  max-width: 150px;
  max-height: 50px;
  margin: 8px 0;
  margin-left: 10%;
`;

export default Container;
