import {Component} from 'react';
import PropTypes from 'prop-types';

import Page from '../../layout/containers/Page/Page';

// containers
import ModelsContainer from '../../containers/ModelsContainer/ModelsContainer';

// Constants
import page from './page';

class ModelsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {}

  render() {
    return (
      <Page page={page}>
        <ModelsContainer></ModelsContainer>
      </Page>
    );
  }
}

export default ModelsPage;
