import img1 from "../../assets/images/tyre1.png";
import img2 from "../../assets/images/tyre2.png";
import img3 from "../../assets/images/tyre3.png";
import img4 from "../../assets/images/tyre4.png";
import img5 from "../../assets/images/tyre5.png";

const tyres = [
  {
    id: 1,
    img: img1,
    title: "PremiumContact™ 6",
  },
  {
    id: 2,
    img: img2,
    title: "EcoContact™ 6",
  },
  {
    id: 3,
    img: img3,
    title: "AllSeasonContact™",
  },
  {
    id: 4,
    img: img4,
    title: "ContiEcoContact™ 5",
  },
  {
    id: 5,
    img: img5,
    title: "ContiSportContact™ 5 P",
  },
];

export default tyres;
