// ID
import {v4} from 'uuid';

// Constants
import KEY from './constants/key.constant.event';

class Event {
  constructor() {
    this.init();
  }

  init() {
    if (!!window[KEY]) return;
    window[KEY] = [];
  }

  subscribe(event, fn) {
    this.init();
    const id = v4();
    window[KEY] = [...window[KEY], {id, event, fn}];
    return this.unsubscribe.bind(this, id);
  }

  unsubscribe(subscriberID) {
    this.init();
    window[KEY] = [...window[KEY]].filter(({id}) => id !== subscriberID);
  }

  publish(event, ...data) {
    this.init();
    window[KEY].forEach((subscriber) => {
      if (subscriber.event !== event) return;
      subscriber.fn(...data);
    });
  }
}

const event = new Event();

const eventWrapper = (eventName) => ({
  subscribe: (fn) => event.subscribe(eventName, fn),
  publish: (...data) => event.publish(eventName, ...data),
});

export default eventWrapper;
