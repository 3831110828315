import getItemsFromCart from "./getItemsFromCart";
import localStorageConst from "../consts/localStorage.const";

const removeItemFromCart = (id) => {
  const items = getItemsFromCart().filter((i) => i._id !== id);
  localStorage.setItem(localStorageConst, JSON.stringify(items));
  return items;
};

export default removeItemFromCart;
