// Styles
import styled from 'styled-components';
import {clearFix} from 'polished';

const Container = styled.div`
  width: 100%;
  padding: 0px 36px;
  ${clearFix()};
`;

export default Container;
