import styled from "styled-components";

import { black, orange } from "../../../style/colors";

const Button = styled.button`
  background-color: ${orange};
  color: ${black};
  padding: 10px 30px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
`;

export default Button;
