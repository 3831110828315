import styled from "styled-components";

import greyTile from "../../assets/images/grey-tile.jpeg";

const SearchContainer = styled.div`
  padding: 0 10% 30px 10%;
  margin: auto;
  background-image: url(${greyTile});
  min-height: 20vh;
`;

export default SearchContainer;
