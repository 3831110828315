import {Component} from 'react';
import Text from '../../components/Text/Text';

class StatisticsContainer extends Component {
  render() {
    return (
      <div style={{textAlign: 'center', marginTop: '20px'}}>
        <Text align="center">V pripravi..</Text>
      </div>
    );
  }
}

export default StatisticsContainer;
