import styled from 'styled-components';

const AdvancedText = styled.div`
  &:hover {
    text-decoration: underline;
  }
  ${'span'} {
    color: ${({enabled}) => (enabled ? 'red' : 'inherit')};
    cursor: pointer;
  }
`;

export default AdvancedText;
