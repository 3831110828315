import {Component} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {search as searchAct} from '../../search/redux/actions';
import {seasons as seasonsAct} from '../../seasons/redux/actions';
import {brand as brandAct} from '../../brand/redux/actions';
import {vehicle as vehicleModelAct} from '../../vehicle/redux/actions';
import {chassis as chassisAct} from '../../chassis/redux/actions';

// components
import Content from '../../components/Content/Content';
import QuickSearch from '../../components/QuickSearch/QuickSearch';
import FeaturedTyres from '../../components/FeaturedTyres/FeaturedTyres';
import tyres from '../../components/FeaturedTyres/tyres';

// routes
import routeSearchPage from '../../pages/SearchPage/route';

// consts
import seasonButtons from '../../consts/seasonButtons';

// api
import listBrandsApi from '../../brand/api/list.api.brand';
import getSeasonsApi from '../../seasons/api/season.api.season';
import capitalize from '../../lib/capitalize';

class HomeContainer extends Component {
  static propTypes = {
    search: PropTypes.string,
    seasons: PropTypes.array,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  state = {
    selectedButton: 'catalogNumber',
    seasonButtons: [...seasonButtons],
    brands: [],
  };

  componentDidMount() {
    this.mounted = true;
    this.getBrands();
    this.getSeasons();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getBrands = async () => {
    const brands = [...this.state.brands];
    if (brands && brands.length > 0) return;
    try {
      const apiBrands = await listBrandsApi();
      const brands = apiBrands.map((brand) => ({
        label: capitalize(brand.name),
        value: brand._id,
      }));
      brands.unshift({
        label: 'Vsi',
        value: 'null',
      });
      this.setState({
        brands,
      });
    } catch (error) {}
  };

  getSeasons = async () => {
    const seasonButtons = [...this.state.seasonButtons];
    const selectedSeasons = this.props.seasons;
    try {
      const apiSeasons = await getSeasonsApi();

      const seasonBtns = seasonButtons.map((t) => {
        const seasonId = apiSeasons.find((s) => s.name === t.name)._id;
        t._id = seasonId;
        t.selected = selectedSeasons.includes(seasonId);
        return t;
      });

      this.setState({
        seasonButtons: seasonBtns,
      });
    } catch (error) {}
  };

  onQuickSearchButtonClick = (selectedButton) => {
    this.setState({
      selectedButton,
    });
  };

  onSearch = (search) => {
    this.props.dispatch(searchAct(search.replace(/\s/g, '')));
  };

  onTyreClick = (id) => {
    const selectedSeasonButtons = [...this.state.seasonButtons];
    const tyreBtn = selectedSeasonButtons.find((t) => t.id === id);
    selectedSeasonButtons.find((t) => t.id === id).selected = !tyreBtn.selected;
    this.setState({
      selectedSeasonButtons,
    });
    this.props.dispatch(
      seasonsAct(
        seasonButtons.filter((s) => s.selected === true).map((s) => s._id)
      )
    );
  };

  onBrandChange = (brand) => {
    this.props.dispatch(brandAct(brand));
  };

  onDimensionSearch = () => {
    this.props.history.push(routeSearchPage());
  };

  onModelSearch = ({brand, model, version, year, fitment, eanNumbers}) => {
    console.log('onModelSearch', brand, model, version, year, fitment);
    if (brand) {
      this.props.dispatch(
        vehicleModelAct({
          vehicleBrand: brand,
          vehicleModel: model,
          vehicleYear: year,
          vehicleVersion: version,
          vehicleFitment: fitment,
          eanNumbers,
        })
      );
      this.props.history.push(routeSearchPage());
    }
    // if (vehicleMakeId && vehicleModelId) {
    //   this.props.dispatch(vehicleModelAct(vehicleMakeId, vehicleModelId));
    //   this.props.history.push(routeSearchPage());
    // }
  };

  onChassisSearch = (chassis) => {
    if (chassis) {
      this.props.dispatch(chassisAct(chassis));
      this.props.history.push(routeSearchPage());
    }
  };

  render() {
    const {seasonButtons, brands} = this.state;
    const {search, brand} = this.props;
    return (
      <Content>
        <QuickSearch
          seasonButtons={seasonButtons}
          search={search}
          onQuickSearchButtonClick={this.onQuickSearchButtonClick}
          onSearch={this.onSearch}
          onDimensionSearch={this.onDimensionSearch}
          onTyreClick={this.onTyreClick}
          brands={brands}
          selectedBrand={brand}
          onBrandChange={this.onBrandChange}
          onModelSearch={this.onModelSearch}
          onChassisSearch={this.onChassisSearch}
        />
        <FeaturedTyres tyres={tyres} />
      </Content>
    );
  }
}

export default connect((state) => ({
  search: state.search.search,
  seasons: state.seasons.seasons,
  brand: state.brand.brand,
}))(withRouter(HomeContainer));
