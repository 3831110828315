import styled from 'styled-components';
import {black, darkishGrey} from '../../../style/colors';

const Label = styled.span`
  height: 100px;
  text-align: center;
  color: ${darkishGrey};
`;

export const IconLabel = styled.div`
  color: ${darkishGrey};
  cursor: pointer;
  height: 25px;
  width: 25px;
  font-size: 25px;
  margin: auto;
  &:hover {
    color: ${black};
  }
`;

export const PriceLabel = styled.span`
  margin-top: 5px;
  font-size: 12px;
  color: ${darkishGrey};
  text-align: center;
  cursor: ${({clickable}) => (clickable ? 'pointer' : '')};
`;

export const Price = styled.span`
  font-size: 16px;
  color: ${black};
  margin-left: 5px;
`;

export default Label;
