// Styles
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {clearFix} from 'polished';

const Row = styled.div`
  ${clearFix()};
  margin: 0px -10px;
  margin-bottom: ${({margin}) => (margin ? 20 : 0)}px;
  display: flex;
  flex-wrap: wrap;
  align-items: ${({align}) =>
    align === 'center'
      ? 'center'
      : align === 'bottom'
      ? 'flex-end'
      : 'flex-start'};
`;

Row.propTypes = {
  margin: PropTypes.bool,
  align: PropTypes.oneOf(['top', 'center', 'bottom']),
};

Row.defaultProps = {
  margin: false,
  align: 'top',
};

export default Row;
