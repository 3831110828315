import React from 'react';
import PropTypes from 'prop-types';

// Containers
import AuthCallbackContainer from '../../containers/AuthCallbackContainer/AuthCallbackContainer';

// Query
import qs from 'query-string';

const AuthCallbackPage = ({location}) => (
  <AuthCallbackContainer {...qs.parse(location.search)} />
);

AuthCallbackPage.propTypes = {
  location: PropTypes.object,
};

export default AuthCallbackPage;
