import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Alert from './components/Alert';

// Store
import alertStore from './store/store.alert';

class Alerts extends Component {
  static propTypes = {
    duration: PropTypes.number,
    pauseOnHover: PropTypes.bool,
  };

  static defaultProps = {
    duration: 4000,
    pauseOnHover: false,
  };

  state = {
    alerts: [],
  };

  componentDidMount() {
    this.unsub = alertStore.sub(this.onAlerts);
  }

  componentWillUnmount() {
    this.unsub();
  }

  onAlerts = (alerts) => this.setState({alerts});

  render() {
    const {duration, pauseOnHover} = this.props;
    const {alerts} = this.state;
    return (
      <Container>
        {alerts.map((alert) => (
          <Alert
            key={alert.id}
            pauseOnHover={pauseOnHover}
            duration={duration}
            {...alert}
          />
        ))}
      </Container>
    );
  }
}

export default Alerts;
