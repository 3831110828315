// Action constants
import { SET, GET } from "./constants";

const DEFAULT_STATE = {
  items: [],
};

const reducer = (state = DEFAULT_STATE, { type, data }) => {
  switch (type) {
    case SET: {
      return {
        ...state,
        ...data,
      };
    }

    case GET: {
      return {
        ...state,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
