import { SET, GET } from "./constants";

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const setCart = (items = []) => set({ items });

export const clearCart = () => set({ items: [] });

export const get = (data = {}) => ({
  type: GET,
  data,
});
