import React from 'react';
import PropTypes from 'prop-types';

// actions
import {set as setAct} from '../../redux/actions';

// components
import Button from './components/Button';
import ButtonContainer from './components/ButtonContainer';

// Redux
import {connect} from 'react-redux';

const DepartmentSelection = ({department, dispatch}) => (
  <ButtonContainer>
    <Button onClick={() => dispatch(setAct({showDepartments: true}))}>
      {department?.name || '-'}
    </Button>
  </ButtonContainer>
);

DepartmentSelection.propTypes = {
  dispatch: PropTypes.func,
};

export default connect((state) => ({department: state.department.department}))(
  DepartmentSelection
);
