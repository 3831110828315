import React, {Component, Children, cloneElement} from 'react';
import PropTypes from 'prop-types';

import Container from './components/Container';
import Header from './components/Header';
import HeaderColumns from './components/HeaderColumns';
import SortIcon from './components/SortIcon';
import TableRow from './components/TableRow';
import NoData from './components/NoData';
import Loader from './components/Loader';
import * as colors from '../../style/colors';

class Table extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(colors)),
    more: PropTypes.bool,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.node,
        sortable: PropTypes.bool,
        span: PropTypes.number,
        align: PropTypes.oneOf(['left', 'center', 'right']),
        bold: PropTypes.bool,
      })
    ),
    children: PropTypes.func.isRequired,
    sort: PropTypes.shape({
      key: PropTypes.string.isRequired,
      direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
    }),
    onSort: PropTypes.func,
    headless: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    headerSize: PropTypes.oneOf(['small', 'medium', 'large']),
    labelSize: PropTypes.oneOf(['small', 'medium', 'large']),
    labelSpace: PropTypes.bool,
    noDataContent: PropTypes.node,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      })
    ),
  };

  static defaultProps = {
    loading: false,
    more: false,
    headless: false,
    size: 'large',
    labelSpace: false,
    headerSize: 'small',
    noDataContent: 'Ni zadetkov',
  };

  state = {};

  portions = () =>
    this.props.columns.reduce((sum, c) => sum + (c.span || 1), 0) || 1;

  onSort = (column) => () => {
    const {onSort, sort} = this.props;
    if (!onSort || !column || !column.sortable) return;
    if (!sort || sort.key !== column.key)
      return onSort({key: column.key, direction: 'asc'});
    if (sort.direction === 'desc') return onSort(null);
    return onSort({key: column.key, direction: 'desc'});
  };

  render() {
    const {
      loading,
      children,
      columns,
      headless,
      sort,
      onSort,
      size,
      labelSize,
      labelSpace,
      theme,
      noDataContent,
      headerSize,
    } = this.props;
    const actualLabelSize = !!labelSize ? labelSize : size;
    const portions = this.portions();
    const rawRows = !!children ? children(TableRow) : null;
    const rowProps = {
      portions,
      size,
      labelSpace,
      theme,
      labelSize: actualLabelSize,
    };
    const rows = !rawRows
      ? null
      : Array.isArray(rawRows)
      ? Children.map(rawRows, (component, index) =>
          cloneElement(component, {...rowProps, index})
        )
      : cloneElement(rawRows, {...rowProps, index: 0});
    return (
      <Container theme={theme}>
        {!!columns.length && !headless && (
          <Header labelSpace={labelSpace} theme={theme} headerSize={headerSize}>
            {columns.map((column) => (
              <HeaderColumns
                key={column.key}
                portions={portions}
                onClick={this.onSort(column)}
                span={column.span || 1}
                align={column.align}
                sortable={column.sortable}
                sorted={
                  column.sortable && !!sort && onSort && sort.key === column.key
                }
                direction={
                  column.sortable &&
                  !!sort &&
                  !!onSort &&
                  sort.key === column.key
                    ? sort.direction
                    : 'desc'
                }
                size={size}
              >
                {column.label}
                {column.sortable && onSort && (
                  <SortIcon
                    className={`mdi mdi-chevron-${
                      !!sort &&
                      sort.key === column.key &&
                      sort.direction === 'asc'
                        ? 'up'
                        : 'down'
                    }`}
                    selected={!!sort && sort.key === column.key}
                  />
                )}
              </HeaderColumns>
            ))}
          </Header>
        )}
        {!loading && (!rows || (Array.isArray(rows) && !rows.length)) && (
          <NoData>{noDataContent}</NoData>
        )}
        {rows}
        {loading && (
          <Loader>
            <i className="mdi mdi-loading mdi-spin" />
          </Loader>
        )}
      </Container>
    );
  }
}

export default Table;
