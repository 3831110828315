import {summer, winter, darkishGrey} from '../style/colors';

const seasonButtons = [
  {
    id: 1,
    _id: '',
    name: 'summer',
    text: 'Poletje',
    icon: 'white-balance-sunny',
    color: summer,
    selected: false,
  },
  {
    id: 2,
    _id: '',
    name: 'winter',
    text: 'Zima',
    icon: 'snowflake',
    color: winter,
    selected: false,
  },
  {
    id: 3,
    _id: '',
    name: 'all season',
    text: 'Vsi letni časi',
    icon: 'weather-partly-snowy',
    color: darkishGrey,
    selected: false,
  },
];

export default seasonButtons;
