import styled from 'styled-components';
import PropTypes from 'prop-types';
import {transitions} from 'polished';

// Colors
import {red, darkGrey} from '../../../style/colors';

const Label = styled.div`
  width: 100%;
  padding: 0px 14px;
  font-size: ${({top}) => (top ? '8px' : '12px')};
  color: ${({error}) => (error ? red : darkGrey)};
  position: absolute;
  top: 16px;
  left: 0px;
  pointer-events: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transform: ${({top}) => (top ? 'translateY(calc(-100% - 4px))' : 'none')};
  ${transitions('transform .3s ease-in-out, font-size .3s ease-in-out')};
`;

Label.propTypes = {
  error: PropTypes.bool,
  top: PropTypes.bool,
};

export default Label;
