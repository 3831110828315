import styled from "styled-components";

import { black, darkishGrey, grey, white } from "../../../style/colors";

const Button = styled.button`
  display: flex;
  margin-top: 20px;
  margin-left: 0px;
  transition: all 0.3s ease-in-out 0s;
  background-color: ${grey};
  color: ${black};
  padding: 5px 10px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  &:hover {
    color: ${white};
    background-color: ${darkishGrey};
  }
`;

export default Button;
