import styled from 'styled-components';

const Image = styled.img`
  object-fit: contain;
  min-width: 100px;
  max-width: 200px;
  width: 100%;
  max-height: 35px;
`;

export default Image;
