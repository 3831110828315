import styled from 'styled-components';
import {darkishGrey} from '../../../../style/colors';

const Label = styled.span`
  text-align: center;
  color: ${darkishGrey};
  font-size: 12px;
`;

export default Label;
