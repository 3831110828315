import styled from 'styled-components';

// Colors
import {red} from '../../../../style/colors';

const Error = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  color: ${red};
`;

export default Error;
