import styled from 'styled-components';
import PropTypes from 'prop-types';

const horizontalPadding = (size) => {
  switch (size) {
    case 'small':
      return '5px';
    case 'medium':
      return '10px';
    case 'large':
      return '15px';
    default:
      return '0px';
  }
};

const verticalPadding = (size) => {
  switch (size) {
    case 'small':
      return '10px';
    case 'medium':
      return '20px';
    case 'large':
      return '30px';
    default:
      return '0px';
  }
};

const setPadding = ({size}) => {
  const horizontal = horizontalPadding(size);
  const vertical = verticalPadding(size);
  return `${vertical} ${horizontal}`;
};

const HeaderColumn = styled.div`
  padding: ${setPadding};
  width: ${({portions, span}) => span * (100 / portions)}%;
  max-width: ${({portions, span}) => span * (100 / portions)}%;
  min-width: ${({portions, span}) => span * (100 / portions)}%;
  flex: 1;
  cursor: ${({sortable}) => (sortable ? 'pointer' : 'initial')};
  text-align: ${({align}) => align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

HeaderColumn.propTypes = {
  portions: PropTypes.number,
  span: PropTypes.number,
  sortable: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  size: PropTypes.string,
};

HeaderColumn.defaultProps = {
  portions: 1,
  span: 1,
  sortable: false,
  align: 'left',
};

export default HeaderColumn;
