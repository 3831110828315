import {SET} from './constants';

export const set = (data = {}) => ({
  type: SET,
  data,
});

export const chassis = (chassis = '') =>
  set({
    chassis,
  });
