import styled from 'styled-components';

const Square = styled.i`
  font-size: 20px;
  position: relative;
  margin-top: 15px;
  &:before {
    color: ${({color}) => color};
  }

  span {
    position: absolute;
    left: 0;
    color: white;
    font-size: 10px;
    left: 6px;
    top: 6px;
    text-align: center;
    font-weight: 400;
    font-style: initial;
  }
`;

export default Square;
