// Colors
import { black } from "../../../style/colors";

const textareaStyle = ({ resize }) => ({
  width: "100%",
  height: "3em",
  display: "block",
  padding: "0px 14px",
  outline: "none",
  border: "none",
  color: black,
  background: "none",
  fontSize: "16px",
  marginBottom: "6px",
  fontFamily: "roboto",
  resize: resize,
});

export default textareaStyle;
