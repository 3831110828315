import styled from 'styled-components';

import {darkishGrey, green, red, white} from '../../../style/colors';

const OrderButton = styled.button`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-left: 0px;
  transition: all 0.3s ease-in-out 0s;
  background-color: ${({disabled}) => (disabled ? red : green)};
  color: ${white};
  padding: 15px 10px;
  font-size: 14px;
  border: none;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 100px;
  width: 100%;
  text-align: center;
  &:hover {
    color: ${white};
    background-color: ${darkishGrey};
  }
`;

export default OrderButton;
