import styled from 'styled-components';

const AlertSystemContainer = styled.div`
  width: 300px;
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100000000000000000;
`;

export default AlertSystemContainer;
