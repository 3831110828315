const ediErrorCodeHandler = (errorCode) => {
  switch (errorCode) {
    case undefined:
      return undefined;
    case '0':
      return null;
    case '933':
      return 'Izdelka ni mogoče naročiti';

    default:
      return `Prišlo je do napake - napaka ${errorCode}`;
  }
};

export default ediErrorCodeHandler;
