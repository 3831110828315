import styled from 'styled-components';
import * as colors from '../../../style/colors';

const Container = styled.div`
  text-align: center;
`;

export const InputButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StockText = styled.div`
  margin-top: -20px;
  margin-bottom: 5px;
  color: ${({color}) => colors[color]};
  font-weight: 400;
`;

export default Container;
