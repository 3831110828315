import { Component } from "react";
import PropTypes from "prop-types";

// containers
import Page from "../../layout/containers/Page/Page";

// Constants
import page from "./page";
import CartContainer from "../../containers/CartContainer/CartContainer";

class CartPage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    // this.props.history.replace(categoriesRoute());
  }

  render() {
    return (
      <Page page={page}>
        <CartContainer></CartContainer>
      </Page>
    );
  }
}

export default CartPage;
