import doc2 from '../../assets/documents/internanavodilazaservisdopolnilnocontinental.pdf';
import doc3 from '../../assets/documents/jamstvenizapisnik-11052018.pdf';

const documents = [
  {
    name: 'Interna navodila za servis',
    url: doc2,
    type: 'pdf',
  },
  {
    name: 'Jamstveni zapisnik',
    url: doc3,
    type: 'pdf',
  },
];

export default documents;
