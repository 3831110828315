import styled from 'styled-components';
import {white} from '../../../style/colors';

const AdvancedContainer = styled.div`
  margin-top: 10px;
  padding: 20px 0;
  background-color: ${white};
  width: 100%;
`;

export default AdvancedContainer;
