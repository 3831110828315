import {Component} from 'react';
import PropTypes from 'prop-types';

// containers
import Page from '../../layout/containers/Page/Page';

// Constants
import page from './page';
import OrdersContainer from '../../containers/OrdersContainer/OrdersContainer';

class OrdersPage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {}

  render() {
    return (
      <Page page={page}>
        <OrdersContainer></OrdersContainer>
      </Page>
    );
  }
}

export default OrdersPage;
