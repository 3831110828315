import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Actions
import {init as initAct} from '../../redux/actions';

// Alertify
import * as alertify from '../../../components/Alerts/lib/alertify';

// Api
import signInApi from '../../api/signIn.api.auth';

// Auth
import authCallbackRoute from '../../pages/AuthCallbackPage/route';
import performAuthRoute from '../../pages/PerformAuthPage/route';

// Components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';
import SignIn from '../../components/SignIn/SignIn';

// Events
import subscriptionHoc from '../../../event/hoc/subscription.hoc.event';
import forceAuthorizationEvent from '../../events/forceAuthorization.event.auth';

// Redux
import {connect} from 'react-redux';

class AuthContainer extends Component {
  static propTypes = {
    initialized: PropTypes.bool,
    user: PropTypes.object,
    dispatch: PropTypes.func,
    children: PropTypes.node,
    window: PropTypes.object,
    subscribe: PropTypes.func,
  };

  static defaultProps = {
    window,
  };

  state = {
    loading: false,
    error: false,
  };

  componentDidMount() {
    this.props.dispatch(initAct());
    this.props.subscribe(forceAuthorizationEvent.subscribe(this.reset));
  }

  reset = () => {
    this.setState({error: true});
  };

  isOnAuthPage = () => {
    const {window} = this.props;
    return [authCallbackRoute(), performAuthRoute()].includes(
      window.location.pathname
    );
  };

  signIn = async () => {
    const {window} = this.props;
    this.setState({loading: true, error: null});
    try {
      const {redirectUrl} = await signInApi({
        redirectTo: window.location.origin,
      });
      window.location.href = redirectUrl;
    } catch (error) {
      alertify.error('Something went wrong. Refresh your browser.');
      this.setState({loading: false});
    }
  };

  render() {
    const {initialized, user, children} = this.props;
    const {loading, error} = this.state;
    return !!user || this.isOnAuthPage() ? (
      children
    ) : loading || !initialized ? (
      <FullScreenLoader />
    ) : (
      <SignIn error={error} onSignIn={this.signIn} />
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({...state.auth}))(AuthContainer)
);
