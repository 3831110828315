import styled from 'styled-components';

import {black, darkishGrey, grey, white} from '../../../style/colors';

const Button = styled.button`
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 300px;
  text-align: center;
  transition: all 0.3s ease-in-out 0s;
  background-color: ${grey};
  color: ${black};
  padding: 20px 10px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  &:hover {
    color: ${white};
    background-color: ${darkishGrey};
  }
`;

export default Button;
