import React, {Component} from 'react';
import PropTypes from 'prop-types';

// ui components

// Components
import Border from './components/Border';
import Container from './components/Container';
import ErrorLabel from './components/ErrorLabel';
import Icon from './components/Icon';
import InputWrap from './components/InputWrap';
import Label from './components/Label';
import Value from './components/Value';
import DropDown from './../DropDown/DropDown';

class MultipleSelect extends Component {
  static propTypes = {
    children: PropTypes.node,
    required: PropTypes.bool,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      })
    ),
    error: PropTypes.string,
    disabled: PropTypes.bool,
    dynamicError: PropTypes.bool,
    unknown: PropTypes.string,
    square: PropTypes.bool,
    onChange: PropTypes.func,
    displayMode: PropTypes.bool,
    onClick: PropTypes.func,
    disableSearch: PropTypes.bool,
    dropDownProps: PropTypes.object,
    multiple: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
    error: null,
    disabled: false,
    unknown: 'Unknown value',
    dynamicError: true,
    square: false,
    displayMode: false,
    onClick: () => {},
    dropDownProps: {},
  };

  state = {
    visible: false,
  };

  onChange = (value) => {
    const {disabled, value: currentValue, multiple, onChange} = this.props;
    if (disabled) return;
    const newValue =
      multiple && Array.isArray(currentValue)
        ? currentValue.includes(value)
          ? [...currentValue].filter((v) => v !== value)
          : [...currentValue, value]
        : value;
    onChange(newValue);
  };

  value = () => {
    const {value, options, unknown, displayMode, multiple} = this.props;
    if (!value) return '';
    if (displayMode) return value;
    return multiple && Array.isArray(value)
      ? [...value]
          .map(
            (singleValue) =>
              [...options].find((opt) => opt.value === singleValue)?.label ||
              unknown
          )
          .join(', ')
      : [...options].find((opt) => opt.value === value)?.label || unknown;
  };

  options = () => {
    const {options, value, multiple} = this.props;
    return multiple && Array.isArray(value)
      ? [...options].map((option) => ({
          ...option,
          icon: value.includes(option.value)
            ? 'checkbox-marked-outline'
            : 'checkbox-blank-outline',
        }))
      : options;
  };

  dropDown = (dom) => {
    const {
      displayMode,
      disableSearch,
      error,
      dynamicError,
      dropDownProps,
      multiple,
    } = this.props;
    return displayMode ? (
      dom
    ) : (
      <DropDown
        height="254px"
        {...dropDownProps}
        stopCloseOnSelect={multiple}
        options={this.options()}
        onOption={this.onChange}
        searchable={!disableSearch}
        top={!!error || !dynamicError ? 'bellow' : 'withMargin'}
        syncVisibility={this.syncVisibility}
      >
        {dom}
      </DropDown>
    );
  };

  syncVisibility = (visible) => this.setState({visible});

  onClick = () => {
    const {displayMode, onClick} = this.props;
    if (!displayMode) return;
    onClick();
  };

  isLabelOnTop = () => {
    const {value, multiple} = this.props;
    return (
      (!multiple && !!value) ||
      (multiple && Array.isArray(value) && !!value.length)
    );
  };

  render() {
    const {children, required, error, disabled, dynamicError, square} =
      this.props;
    const {visible} = this.state;
    return this.dropDown(
      <Container
        dynamicError={dynamicError}
        error={!!error}
        onClick={this.onClick}
      >
        <InputWrap square={square} error={!!error} disabled={disabled}>
          <Label error={!!error} top={this.isLabelOnTop()} square={square}>
            {children}
            {required && ` *`}
          </Label>
          <Value disabled={disabled} square={square}>
            {this.value()}
          </Value>
          <Icon
            visible={visible}
            className={`mdi mdi-chevron-${visible ? 'up' : 'down'}`}
          />
        </InputWrap>
        {!square && (
          <Border
            visible={visible}
            error={!!error}
            hasValue={this.isLabelOnTop()}
          />
        )}
        {!!error && <ErrorLabel>{error}</ErrorLabel>}
      </Container>
    );
  }
}

export default MultipleSelect;
