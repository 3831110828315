import styled, { css } from "styled-components";

// Colors
import { white, orange, transparent, darkGrey } from "../../../style/colors";

const Check = styled.div`
  ${({ large }) =>
    large
      ? css`
          width: 32px;
          height: 32px;
          line-height: 32px;
          font-size: 20px;
        `
      : css`
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 14px;
        `}
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-color: ${({ selected }) => (selected ? orange : darkGrey)};
  color: ${({ selected }) => (selected ? white : transparent)};
  background: ${({ selected }) => (selected ? orange : transparent)};
  pointer-events: none;
`;

export default Check;
