import {Component} from 'react';

// assets
import miniCenik from '../../assets/documents/mini_cenik_2023.zip';

// components
import GuaranteeDocuments from '../../components/GuaranteeDocuments/GuaranteeDocuments';
import ModelsDocuments from '../../components/ModelsDocuments/ModelsDocuments';
import WarrantyDocuments from '../../components/WarrantyDocuments/WarrantyDocuments';

// style components
import Button from './style/Button';
import Icon from './style/Icon';
import DocumentTypeContainer from './style/DocumentTypeContainer';
import Title from './style/Title.style';
import Container from './style/Container.style';

class DocumentsContainer extends Component {
  downloadPriceList = () => {
    window.open(miniCenik, '_blank');
  };
  render() {
    return (
      <Container>
        <DocumentTypeContainer>
          <Title>Mini cenik</Title>
          <Button
            onClick={() => {
              this.downloadPriceList();
            }}
          >
            Mini cenik
            <Icon className={`mdi mdi-file-pdf-outline`}></Icon>
          </Button>
        </DocumentTypeContainer>

        <DocumentTypeContainer>
          <Title>Dopolnilno jamstvo</Title>
          <GuaranteeDocuments />
        </DocumentTypeContainer>
        <DocumentTypeContainer>
          <Title>Garancija</Title>
          <WarrantyDocuments />
        </DocumentTypeContainer>
        <DocumentTypeContainer>
          <Title>Aktualni letaki</Title>
          <ModelsDocuments />
        </DocumentTypeContainer>
      </Container>
    );
  }
}

export default DocumentsContainer;
