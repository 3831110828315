import styled from 'styled-components';
import {black, orange} from '../../../style/colors';

const FeaturedTitleContainer = styled.div`
  color: ${black};
  padding: 20px 0;
  background-color: ${orange};
  font-weight: 300;
  font-style: normal;
  font-size: 36px;
  line-height: 1.35em;
  min-width: 33.33333%;
  display: inline-block;
  box-sizing: content-box;
`;

export const FeaturedTitle = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export default FeaturedTitleContainer;
