import localStorageConst from '../consts/localStorage.const';
import getItemsFromCart from './getItemsFromCart';

const addItemtoCart = (cartItem) => {
  const cartItems = getItemsFromCart();
  const cItem = cartItems.find((c) => c._id === cartItem._id);
  if (cItem) {
    cItem.cartQuantity = cItem.cartQuantity
      ? cItem.cartQuantity + cartItem.cartQuantity
      : 0;
    cartItems[cartItems.findIndex((el) => el._id === cartItem._id)] = cItem;
  } else {
    cartItems.push(cartItem);
  }
  localStorage.setItem(localStorageConst, JSON.stringify(cartItems));
  return cartItems;
};

export const addItemsToCart = (cartItems) => {
  localStorage.setItem(localStorageConst, JSON.stringify(cartItems));
  return cartItems;
};

export default addItemtoCart;
