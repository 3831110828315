import styled from "styled-components";

// Colors
import { white, black } from "../../../style/colors";

const Container = styled.div`
  display: inline-block;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: pointer;
  user-select: none;
  color: ${({ light }) => (light ? white : black)};
`;

export default Container;
