import styled from 'styled-components';

import {black, darkGrey, white} from '../../../style/colors';

const Button = styled.button`
  transition: all 0.3s ease-in-out 0s;
  background-color: ${black};
  color: ${white};
  padding: 0 10px;
  font-size: 20px;
  border: none;
  cursor: ${({disabled}) => (disabled ? 'not-allowed' : 'pointer')};
  height: 30px;
  border-radius: 100px;
  &:hover {
    background-color: ${darkGrey};
    color: ${white};
  }
`;

export default Button;
