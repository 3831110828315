import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { transitions } from "polished";

// Components
import Alert from "../../Alert/Alert";

// Libs
import * as alertify from "../lib/alertify";

const Container = styled.div`
  width: 100%;
  margin: 10px 0px;
  position: relative;
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) =>
    visible ? "none" : "translateY(calc(100% + 10px))"};
  ${transitions(
    "visibility .3s ease-in-out, opacity .3s ease-in-out, transform .3s ease-in-out"
  )};
`;

class AlertSystemNode extends Component {
  static propTypes = {
    duration: PropTypes.number.isRequired,
    pauseOnHover: PropTypes.bool,
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
    content: PropTypes.node.isRequired,
    action: PropTypes.func,
    label: PropTypes.node,
  };

  static defaultProps = {
    pauseOnHover: false,
  };

  state = {
    visible: false,
  };

  timer = null;

  componentDidMount() {
    setTimeout(() => this.setState({ visible: true }), 20);
    this.startTiming();
  }

  componentWillUnmount() {
    this.stopTiming();
  }

  startTiming = () => {
    const { duration } = this.props;
    this.stopTiming();
    this.timer = setTimeout(this.close, duration);
  };

  close = () => {
    this.stopTiming();
    this.setState({ visible: false });
    setTimeout(() => alertify.remove(this.props.id), 310);
  };

  stopTiming = () => {
    if (!this.timer) return;
    clearTimeout(this.timer);
    this.timer = null;
  };

  hoverIn = () => {
    if (!this.props.pauseOnHover) return;
    this.stopTiming();
  };

  hoverOut = () => {
    if (!this.props.pauseOnHover) return;
    this.startTiming();
  };

  action = () => {
    const { action } = this.props;
    if (!action) return null;
    return (...args) => {
      this.close();
      action(...args);
    };
  };

  render() {
    const { visible } = this.state;
    const { type, content, label } = this.props;
    return (
      <Container
        visible={visible}
        onMouseEnter={this.hoverIn}
        onMouseLeave={this.hoverOut}
      >
        <Alert type={type} action={this.action()} label={label}>
          {content}
        </Alert>
      </Container>
    );
  }
}

export default AlertSystemNode;
