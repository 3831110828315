import styled from 'styled-components';

const Container = styled.div`
  padding-top: 30px;
  display: flex;
  height: 110px;
  gap: 20px;
`;

export const InputContainer = styled.div`
  height: 45px;
`;

export const ButtonContainer = styled.div`
  height: 50px;
`;

export const SearchContainer = styled.div`
  width: 90%;
`;

export default Container;
