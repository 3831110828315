import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import AdvancedText from './components/AdvancedText';
import AdvancedContainer from './components/AdvancedContainer';
import Select from '../Select/Select';
import Column from '../Grid/Column/Column';
import Row from '../Grid/Row/Row';
import Button from './components/Button';
import ButtonContainer from './components/ButtonContainer';
import Input from '../Input/Input';
import InputContainer from './components/InputContainer';
import ClearFilters from './components/ClearFilters';
import SeasonButtons from '../SeasonButtons/SeasonButtons';
import {connect} from 'react-redux';
import SearchByModel from '../QuickSearch/Search/SearchByModel/SearchByModel';

const AdvancedSearchFilters = ({
  advancedSearch,
  brands,
  advancedSearchFilters,
  onAdvancedSearchClick,
  onAdvancedFiltersChange,
  onAdvancedSearchSubmit,
  clearFilters,
  seasonButtons,
  onSeasonButtonClick,
  chassis,
  vehicleBrand,
  vehicleModel,
  vehicleYear,
  vehicleVersion,
  vehicleFitment,
  eanNumbers,
}) => {
  const [chassisSearch, setChassisSearch] = useState(chassis);
  const [vehicle, setVehicle] = useState({
    vehicleBrand,
    vehicleModel,
    vehicleYear,
    vehicleVersion,
    vehicleFitment,
    eanNumbers: [],
  });

  useEffect(() => {
    setChassisSearch(chassis);
  }, [chassis]);

  const onValueChange = (type, value) => {
    setVehicle({
      ...vehicle,
      [type]: value,
    });
  };

  const onSubmit = () => {
    onAdvancedSearchSubmit({
      chassis: chassisSearch,
      vehicle,
    });
  };

  return (
    <Container>
      <AdvancedText
        enabled={
          eanNumbers.length > 0 ||
          chassisSearch ||
          advancedSearchFilters.brand ||
          advancedSearchFilters.type ||
          advancedSearchFilters.name ||
          advancedSearchFilters.rimSize ||
          advancedSearchFilters.width ||
          advancedSearchFilters.profile
        }
      >
        <span
          onClick={() => {
            onAdvancedSearchClick();
          }}
        >
          Napredno iskanje
        </span>
      </AdvancedText>
      {advancedSearch ? (
        <AdvancedContainer>
          <Row>
            <Column size={1.5 / 12} />
            <Column size={3 / 12}>
              <SeasonButtons
                seasonButtons={seasonButtons}
                onClick={onSeasonButtonClick}
              />
            </Column>
          </Row>
          {brands && brands.length > 0 ? (
            <>
              <Row>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <Select
                    value={advancedSearchFilters.brand}
                    onChange={(value) => {
                      onAdvancedFiltersChange('brand', value);
                    }}
                    options={brands}
                  >
                    Izbrana znamka
                  </Select>
                </Column>
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.type}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('type', value);
                      }}
                    >
                      Design
                    </Input>
                  </InputContainer>
                </Column>
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.name}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('name', value);
                      }}
                    >
                      Naziv
                    </Input>
                  </InputContainer>
                </Column>
              </Row>
              <Row margin>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.ean}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('ean', value);
                      }}
                    >
                      EAN
                    </Input>
                  </InputContainer>
                </Column>
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={advancedSearchFilters.dimensions}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        onAdvancedFiltersChange('dimensions', value);
                      }}
                    >
                      Dimenzije
                    </Input>
                  </InputContainer>
                </Column>
              </Row>
              <Row margin>
                <Column size={1.5 / 12} />
                <Column size={9 / 12}>
                  <SearchByModel
                    onValueChange={onValueChange}
                    hideSearchButton
                  />
                </Column>
              </Row>
              <Row margin>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <InputContainer>
                    <Input
                      rounded={true}
                      value={chassisSearch}
                      enterPressed={onSubmit}
                      onChange={(value) => {
                        setChassisSearch(value);
                      }}
                    >
                      Številka šasije
                    </Input>
                  </InputContainer>
                </Column>
              </Row>
              <Row margin>
                <Column size={1.5 / 12} />
                <Column size={3 / 12}>
                  <ClearFilters>
                    <span
                      onClick={() => {
                        clearFilters();
                        setChassisSearch(chassis);
                        setVehicle({
                          vehicleBrand: '',
                          vehicleModel: '',
                          vehicleYear: '',
                          vehicleVersion: '',
                          vehicleFitment: '',
                          eanNumbers: [],
                        });
                      }}
                    >
                      Počisti iskanje
                    </span>
                  </ClearFilters>
                </Column>
              </Row>
            </>
          ) : (
            ''
          )}
          <ButtonContainer>
            <Button
              onClick={() => {
                onSubmit();
              }}
            >
              <i className="mdi mdi-magnify" /> Napredno iskanje
            </Button>
          </ButtonContainer>
        </AdvancedContainer>
      ) : (
        ''
      )}
    </Container>
  );
};

AdvancedSearchFilters.propTypes = {
  advancedSearch: PropTypes.bool,
  advancedSearchFilters: PropTypes.object,
  brands: PropTypes.array,
  seasonButtons: PropTypes.array,
  onAdvancedSearchClick: PropTypes.func,
  onAdvancedFiltersChange: PropTypes.func,
  onAdvancedSearchSubmit: PropTypes.func,
  onSeasonButtonClick: PropTypes.func,
  clearFilters: PropTypes.func,
};

export default connect((state) => ({
  chassis: state.chassis.chassis,
  vehicleBrand: state.vehicle.vehicleBrand,
  vehicleModel: state.vehicle.vehicleModel,
  vehicleYear: state.vehicle.vehicleYear,
  vehicleVersion: state.vehicle.vehicleVersion,
  vehicleFitment: state.vehicle.vehicleFitment,
  eanNumbers: state.vehicle.eanNumbers,
}))(AdvancedSearchFilters);
