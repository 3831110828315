import React, {Component, Children, cloneElement} from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

// Components
import TableCol from './TableCol';

// Colors
import * as colors from '../../../style/colors';

// Helpers
const labelWidth = ({size}) => {
  switch (size) {
    case 'large':
      return '12px';
    case 'medium':
      return '8px';
    case 'small':
      return '4px';
    default:
      return '15px';
  }
};

// Helper Components
const Container = styled.div`
  width: 100%;
  margin-bottom: ${({noMargin}) => (noMargin ? '0px' : '1px')};
  position: relative;
  ${({isDropdown, label, open}) =>
    isDropdown
      ? css`
          border-top: 1px solid ${colors.grey};
        `
      : css``}
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  cursor: ${({onClick, rowClick, pointer}) =>
    onClick || !!rowClick || pointer ? 'pointer' : 'initial'};
  color: ${colors.black};
  font-size: 15px;
  color: ${({color}) => colors[color]};
  background: ${({theme}) => colors[theme]};
  padding-left: ${({labelSpace}) => (labelSpace ? '50px' : '0px')};

  &:hover {
    transition: all 0.2s ease-in-out 0s;
    background-color: ${colors.white};
  }
`;

const Label = styled.div`
  width: ${labelWidth};
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background: ${({theme}) => colors[theme]};
`;

const DropDown = styled.div`
  width: 100%;
  /* margin-top: 4px; */
  background: ${({theme}) => colors[theme]};
`;

// const SelectedFrame = styled.div`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0px;
//   left: 0px;
//   pointer-events: none;
//   border: ${({label}) =>
//     `1px solid ${!!label ? colors[label] : colors.darkGrey}`};
// `;

class TableRow extends Component {
  static propTypes = {
    id: PropTypes.any,
    children: PropTypes.func.isRequired,
    portions: PropTypes.number.isRequired,
    pointer: PropTypes.bool,
    noMargin: PropTypes.bool,
    theme: PropTypes.oneOf(Object.keys(colors)),
    color: PropTypes.oneOf(Object.keys(colors)),
    label: PropTypes.oneOf(Object.keys(colors)),
    size: PropTypes.string,
    labelSize: PropTypes.string,
    labelSpace: PropTypes.bool,
    dropdown: PropTypes.node,
    isDropdown: PropTypes.bool,
    index: PropTypes.number,
    rowClick: PropTypes.func,
  };

  static defaultProps = {
    theme: 'white',
    color: 'darkGrey',
    portions: 1,
    labelSpace: false,
    dropdown: [],
    isDropdown: false,
    clickable: true,
  };

  state = {
    open: false,
  };

  onClick = (e) => {
    if (
      e.target.type === 'number' ||
      e.target.type === 'submit' ||
      e.target.className.includes('mdi')
    )
      return;
    const {open} = this.state;
    this.setState({
      open: !open,
    });
    const {rowClick, id} = this.props;
    if (rowClick) rowClick(id, !open);
  };

  render() {
    const {
      id,
      children,
      noMargin,
      portions,
      label,
      theme,
      size,
      labelSize,
      dropdown,
      labelSpace,
      isDropdown,
      index,
      clickable,
      ...props
    } = this.props;
    const {open} = this.state;
    const rawCols = children(TableCol);
    const colProps = {portions, size};
    const cols = Array.isArray(rawCols)
      ? Children.map(rawCols, (component) => cloneElement(component, colProps))
      : cloneElement(rawCols, colProps);
    // const dropdownRows = Children.map(dropdown, (component) =>
    //   cloneElement(component, {
    //     portions,
    //     theme,
    //     label: null,
    //     size,
    //     labelSpace: labelSpace,
    //     noMargin: true,
    //     isDropdown: true,
    //   })
    // );
    return (
      <Container
        noMargin={noMargin}
        open={open}
        label={label}
        theme={theme}
        isDropdown={isDropdown}
      >
        <Row
          {...props}
          theme={theme}
          labelSpace={labelSpace}
          onClick={clickable ? (e) => this.onClick(e) : null}
        >
          {cols}
        </Row>
        {open && <DropDown theme={theme}>{dropdown}</DropDown>}
        {!!label && <Label theme={label} size={labelSize} />}
      </Container>
    );
  }
}

export default TableRow;
