import {profile} from 'f3tch';

// Errors
import isApiError from '../validation/isApiError.validation.api';
import isUnauthorized from '../validation/isUnauthorized.validation.api';

// Profiles
import headers from '../headers/json.header.api';

const jsonProfile = ({url = '', authorize = true} = {}) =>
  profile(url)
    .headers(headers({authorize}))
    .bodyParser((data) => JSON.stringify(data))
    .responder(async (response) => {
      isUnauthorized({response, authorize});
      await isApiError(response);
      const data = await response.json();
      return data;
    });

export default jsonProfile;
