import styled from 'styled-components';

// Colors
import {grey} from '../../../style/colors';

const SearchIcon = styled.i`
  font-size: 20px;
  pointer-events: none;
  color: ${grey};
  display: block;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
`;

export default SearchIcon;
