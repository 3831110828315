const generatePosition = ({
  containerPosition,
  containerSize,
  dropDownSize,
  windowDimensions,
}) => {
  const leftPoint = containerPosition.x;
  const rightPoint = containerPosition.x + containerSize.width;
  const leftCutoff = 0;
  const rightCutoff = windowDimensions.width;

  return rightPoint - dropDownSize.width < leftCutoff ||
    leftPoint + dropDownSize.width <= rightCutoff
    ? 'left'
    : 'right';
};

export default generatePosition;
