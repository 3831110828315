import styled from 'styled-components';
import PropTypes from 'prop-types';
import {transitions} from 'polished';

import * as colors from '../../../style/colors';

const topAttribute = ({top}) => {
  const settings = {
    bellow: 'calc(100% - 18px)',
    withMargin: 'calc(100% + 2px)',
    default: 'calc(100%)',
  };
  return settings[top] || settings.default;
};

const DropDown = styled.div`
  ${({width}) => (!!width ? `width: ${width}` : '')};
  background: ${colors.white};
  border: ${({border}) =>
    `1px solid ${colors[border] || border || colors.darkGrey}`};
  padding: ${({noPadding}) => (noPadding ? '0px' : '8px 0px')};
  position: absolute;
  ${({position}) => `${position}: 0px;`};
  top: ${topAttribute};
  font-size: 13px;
  z-index: 1;
  pointer-events: ${({visible}) => (visible ? 'auto' : 'none')};
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
  transform: ${({visible}) => (visible ? 'none' : 'translateY(10px)')};
  opacity: ${({visible}) => (visible ? 1 : 0)};
  ${transitions(
    'transform .3s ease-in-out, visibility .3s ease-in-out, opacity .3s ease-in-out'
  )}
`;

DropDown.propTypes = {
  visible: PropTypes.bool,
};

export default DropDown;
