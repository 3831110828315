import styled from 'styled-components';

// Colors
import {grey, black} from '../../../style/colors';

const Input = styled.input`
  width: 100%;
  height: 100%;
  display: block;
  margin: 0px;
  padding: 3px 40px 2px 14px;
  font-size: 12px;
  background: none;
  border-style: none none solid none;
  border-width: 2px;
  border-color: ${grey};
  color: ${black};
  outline: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    background: none;
    color: ${black};
  }
`;

export default Input;
