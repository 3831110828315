import {Component} from 'react';
import PropTypes from 'prop-types';

// containers
import Page from '../../layout/containers/Page/Page';

// Constants
import page from './page';
import StatisticsContainer from '../../containers/StatisticsContainer/StatisticsContainer';

class StatisticsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {}

  render() {
    return (
      <Page page={page}>
        <StatisticsContainer></StatisticsContainer>
      </Page>
    );
  }
}

export default StatisticsPage;
