export const black = '#000';
export const white = '#fff';
export const orange = 'orange';
export const grey = '#efefef';
export const darkishGrey = '#9c9d9d';
export const red = 'red';
export const darkGrey = '#6e6e6e';
export const blue = 'blue';
export const green = 'green';
export const lightGreen = '#0dee9e';
export const transparent = 'transparent';
export const winter = '#acd3dc';
export const summer = '#FDB813';
export const contiOrange = '#FFA500';
