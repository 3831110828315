import styled from 'styled-components';

export const GrossPriceContainer = styled.div`
  position: relative;
`;

export const EVIcon = styled.img`
  position: absolute;
  top: 32px;
  left: -4px;
  height: 14px;
  width: 30px;
`;
