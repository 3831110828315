import {Component} from 'react';
import PropTypes from 'prop-types';

// containers
import Page from '../../layout/containers/Page/Page';

// Constants
import page from './page';
import GuaranteeContainer from '../../containers/GuaranteeContainer/GuaranteeContainer';

class GuaranteePage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {}

  render() {
    return (
      <Page page={page}>
        <GuaranteeContainer></GuaranteeContainer>
      </Page>
    );
  }
}

export default GuaranteePage;
