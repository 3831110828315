import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.constant.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const signInApi = ({redirectTo}) =>
  f3tch('/auth/sign-in')
    .profile(profile({authorize: false, url: API_URL()}))
    .body({redirectTo})
    .post();

export default signInApi;
