import React from 'react';
import PropTypes from 'prop-types';

// components
import Button from './components/Button';
import ButtonContainer from './components/ButtonContainer';

// Components

const Logout = ({onLogout}) => (
  <ButtonContainer>
    <Button onClick={onLogout}>Odjava</Button>
  </ButtonContainer>
);

Logout.propTypes = {
  onLogout: PropTypes.func,
};

export default Logout;
