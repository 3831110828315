import styled from 'styled-components';
import {grey} from '../../../../style/colors';

const OrderRow = styled.div`
  width: 80%;
  padding: 20px 10px;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  border-top: 1px solid ${grey};

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;

export default OrderRow;
