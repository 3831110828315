import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import $ from 'jquery';

// Components
import Container from './components/Container';
import Border from './components/Border';
import Label from './components/Label';
import ErrorLabel from './components/ErrorLabel';
import DropDown from './components/DropDown';
import OptionsContainer from './components/OptionsContainer';
import Option from './components/Option';
import Value from './components/Value';
import Icon from './components/Icon';
import InputContainer from './components/InputContainer';
import Input from './components/Input';
import SearchIcon from './components/SearchIcon';

class Select extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
      })
    ),
    error: PropTypes.string,
    disabled: PropTypes.bool,
    dynamicError: PropTypes.bool,
    unknown: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    showBorder: PropTypes.bool,
  };

  static defaultProps = {
    required: false,
    error: null,
    disabled: false,
    unknown: 'Unknown value',
    dynamicError: false,
    showBorder: true,
  };

  state = {
    visible: false,
    search: '',
  };

  input = null;

  handleClickOutside = () => this.hide();

  display = () => {
    this.setState({visible: true, search: ''});
    if (!this.input) return;
    setTimeout(() => $(this.input).focus(), 110);
  };

  hide = () => this.setState({visible: false});

  onInput = (input) => {
    this.input = input;
  };

  onSearch = ({target: {value: search}}) => {
    if (!this.state.visible) return;
    this.setState({search});
  };

  onChange = (value) => () => {
    if (this.props.disabled) return;
    this.props.onChange(value);
    this.hide();
  };

  value = () => {
    const {value, options, unknown} = this.props;
    if (!value && value !== 0) return '';
    const option = [...options].find((opt) => opt.value === value);
    return !!option ? option.label : unknown;
  };

  options = () => {
    const {options} = this.props;
    const {search} = this.state;
    return !search.trim().length
      ? options
      : [...options].filter(({label}) =>
          `${label}`.toLowerCase().includes(search.toLowerCase())
        );
  };

  render() {
    const {
      children,
      required,
      value,
      error,
      disabled,
      dynamicError,
      showBorder,
    } = this.props;
    const {visible, search} = this.state;
    return (
      <Container disabled={disabled} dynamicError={dynamicError}>
        <Label
          empty={value ? false : true}
          error={!!error}
          top={value === 0 || !!value}
        >
          {children}
          {required && ` *`}
        </Label>
        <Value onClick={this.display} disabled={disabled}>
          {this.value()}
        </Value>
        <Icon
          visible={visible}
          className={`mdi mdi-chevron-${visible ? 'up' : 'down'}`}
        />
        {showBorder ? (
          <Border visible={visible} error={!!error} hasValue={!!value} />
        ) : (
          ''
        )}
        {!!error && (
          <ErrorLabel dynamicError={dynamicError}>{error}</ErrorLabel>
        )}
        <DropDown dynamicError={dynamicError} visible={visible}>
          <InputContainer>
            <Input ref={this.onInput} value={search} onChange={this.onSearch} />
            <SearchIcon className="mdi mdi-magnify" />
          </InputContainer>
          <OptionsContainer>
            {this.options().map((option) => (
              <Option key={option.value} onClick={this.onChange(option.value)}>
                {option.label}
              </Option>
            ))}
          </OptionsContainer>
        </DropDown>
      </Container>
    );
  }
}

export default onClickOutside(Select);
