const columns = (showAdminRows) => [
  {
    key: 'orderNo',
    label: 'Številka naročila',
    sortable: false,
    span: 2,
    align: 'center',
  },
  {
    key: 'ediOrderNo',
    label: 'Interna številka naročila',
    sortable: false,
    span: 2,
    align: 'center',
  },
  ...(showAdminRows
    ? [
        {
          key: 'admin',
          label: 'Ustvaril',
          sortable: false,
          span: 2,
          align: 'center',
        },
      ]
    : []),
  {
    key: 'createdAt',
    label: 'Datum naročila',
    sortable: true,
    align: 'center',
    span: 3,
  },
  {
    key: 'estimatedDelivery',
    label: 'Predviden datum dostave',
    sortable: false,
    span: 2,
    align: 'center',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    span: 2,
    align: 'center',
  },
  {
    key: 'price',
    label: 'Skupen znesek',
    sortable: false,
    span: 2,
    align: 'center',
  },
];

export default columns;
