import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.constant.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateUserApi = (user) =>
  f3tch('/users')
    .profile(profile({url: API_URL()}))
    .body(user)
    .patch();

export default updateUserApi;
