import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.constant.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const inquiryApi = (inquiry) =>
  f3tch('/order/inquiry')
    .profile(profile({url: API_URL()}))
    .body(inquiry)
    .post();

export default inquiryApi;
