import styled from 'styled-components';

const SelectContainer = styled.div`
  width: 50%;
  margin-right: 30px;
  border: 1px solid #000;
  background: #fff;
  opacity: ${({disabled}) => (disabled ? 0.6 : 1)};
`;

export default SelectContainer;
