import React, {Component, Fragment} from 'react';

// alerts
import Alerts from './components/Alerts/Alerts';

// Components
import GlobalStyle from './layout/components/GlobalStyle/GlobalStyle';

// Containers
import AuthContainer from './auth/containers/AuthContainer/AuthContainer';

// Departments
import DepartmentsContainer from './department/containers/DepartmentsContainer/DepartmentsContainer';

// Routes
import Routes from './routes/Routes';

// Redux
import {Provider} from 'react-redux';
import store from './redux/store';

class App extends Component {
  render() {
    return (
      <Fragment>
        <Provider store={store}>
          <GlobalStyle />
          <AuthContainer>
            <DepartmentsContainer>
              <Routes />
            </DepartmentsContainer>
          </AuthContainer>
          <Alerts />
        </Provider>
      </Fragment>
    );
  }
}

export default App;
