import styled from 'styled-components';

// Colors
import {black} from '../../../../style/colors';

const LoginContainer = styled.div`
  position: absolute;
  margin: auto;
  width: 254px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: hidden;
  background: ${black};
  border-radius: 15px;
  padding: 10px;
`;

export default LoginContainer;
