import { v4 } from "uuid";

class Store {
  subs = [];

  sub(fn) {
    const id = v4();
    this.subs = [...this.subs, { id, fn }];
    return this.unsub.bind(this, id);
  }

  unsub(id) {
    this.subs = [...this.subs].filter((sub) => sub.id !== id);
  }

  pub(data) {
    this.subs.forEach(({ fn }) => fn(data));
  }
}

const stores = {};
const store = (store) => {
  if (stores[store] === undefined) stores[store] = new Store();
  return stores[store];
};

export default store;
