import {green, lightGreen, orange, red} from '../../style/colors';

const getStatus = (statusCode) => {
  switch (statusCode) {
    case 0:
      return {
        text: 'Zaključeno',
        color: green,
      };

    case -1:
      return {
        text: 'Potrjeno',
        color: lightGreen,
      };
    case -2:
      return {
        text: 'V pripravi',
        color: orange,
      };
    case -3:
      return {
        text: 'Preklicano',
        color: orange,
      };
    default:
      return {
        text: 'Napaka',
        color: red,
      };
  }
};

export default getStatus;
