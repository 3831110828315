// Events
import unauthorizedEvent from '../../auth/events/unauthorized.event.auth';

const isUnauthorized = ({response: {status}, authorize = true}) => {
  if ([401, 403].includes(status)) {
    if (authorize) unauthorizedEvent.publish();
    throw new Error('User is unauthorised');
  }
};

export default isUnauthorized;
