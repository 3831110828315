import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Content from './components/Content';
import Check from './components/Check';
import Label from './components/Label';
import Input from './components/Input';

const CheckBox = ({
  children,
  name,
  disabled,
  selected,
  light,
  large,
  noInput,
  onSelect,
}) => (
  <Container
    light={light}
    disabled={disabled}
    onClick={() => onSelect(!selected)}
  >
    <Content>
      <Check selected={selected} large={large} light={light}>
        <i className="mdi mdi-check" />
      </Check>
      {!!children && <Label>{children}</Label>}
    </Content>
    {!noInput && (
      <Input
        type="hidden"
        value={`${selected}`}
        name={name}
        onChange={() => null}
      />
    )}
  </Container>
);

CheckBox.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  light: PropTypes.bool,
  large: PropTypes.bool,
  noInput: PropTypes.bool,
  onSelect: PropTypes.func,
};

CheckBox.defaultProps = {
  noInput: false,
};

export default CheckBox;
