import React from "react";
import PropTypes from "prop-types";

// Components
import Container from "./components/Container";

const CartContentTable = ({ children }) => <Container>{children}</Container>;

CartContentTable.propTypes = {
  children: PropTypes.node,
};

export default CartContentTable;
