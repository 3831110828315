import styled from "styled-components";

import { black, orange, white } from "../../../style/colors";

const SquareButton = styled.button`
  transition: all 0.5s ease-in-out 0s;
  padding: 20px 40px;
  margin-right: 10px;
  background-color: ${({ selected }) => (selected ? orange : black)};
  color: ${({ selected }) => (selected ? black : white)};
  font-size: 20px;
  border: none;
  cursor: pointer;
  position: relative;
  box-shadow: ${({ selected }) =>
    selected ? "-3px 3px 3px 1px rgb(0 0 0 / 10%)" : ""};
  &:after {
    transition: all 0.5s ease-in-out 0s;
    transition: visibility 0s, opacity 0.5s linear;
    visibility: ${({ selected }) => (selected ? "visible" : "hidden")};
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    border-top: solid 10px ${({ selected }) => (selected ? orange : black)};
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
`;

export default SquareButton;
