import styled from "styled-components";

// Colors
import { red } from "../../../style/colors";

const ErrorLabel = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${red};
  bottom: 0px;
  left: 0px;
  padding: 0 14px;
  position: absolute;
`;

export default ErrorLabel;
