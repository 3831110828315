import styled from "styled-components";

import { white } from "../../../style/colors";

const FeaturedTyreContainer = styled.div`
  margin-right: 20px;
  max-width: 150px;
`;

export const Image = styled.img`
  object-fit: contain;
  min-width: 150px;
  width: 100%;
  height: 300px;
`;

export const Title = styled.div`
  color: ${white};
  text-align: center;
  margin-top: 15px;
  height: 40px;
`;

export default FeaturedTyreContainer;
