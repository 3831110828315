const columns = [
  {
    key: 'catalogNumber',
    label: 'Kataloška številka',
    sortable: false,
    align: 'center',
    span: 3,
  },
  {
    key: 'name',
    label: 'Naziv in lastnosti',
    sortable: false,
    span: 5,
    align: 'center',
  },
  {
    key: 'season',
    label: 'Sezona',
    sortable: false,
    span: 1,
    align: 'center',
  },
  {
    key: 'grossPrice',
    label: 'Cena',
    sortable: false,
    span: 2,
    align: 'center',
  },
  {
    key: 'mail',
    label: '',
    span: 1,
  },
  {
    key: 'buy',
    label: 'Nakup',
    align: 'center',
    span: 2,
  },
];

export default columns;
