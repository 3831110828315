export const parseBrands = (apiResponse) => {
  if (apiResponse && apiResponse.filters && apiResponse.filters.vendor) {
    const brands = apiResponse.filters.vendor.map((brand) => ({
      label: brand,
      value: brand,
    }));
    // sort brands alphabetically
    brands.sort((a, b) => a.label.localeCompare(b.label));
    return brands;
  } else {
    return [];
  }
};

export const parseModels = (apiResponse) => {
  if (apiResponse && apiResponse.filters && apiResponse.filters.model) {
    const models = apiResponse.filters.model.map((model) => ({
      label: model,
      value: model,
    }));
    // sort models alphabetically
    models.sort((a, b) => a.label.localeCompare(b.label));
    return models;
  } else {
    return [];
  }
};

export const parseYears = (apiResponse) => {
  if (apiResponse && apiResponse.filters && apiResponse.filters.year) {
    const years = apiResponse.filters.year.map((year) => ({
      label: year,
      value: year,
    }));
    // sort years numerically
    years.sort((a, b) => a.label - b.label);
    return years;
  } else {
    return [];
  }
};

export const parseVersions = (apiResponse) => {
  if (apiResponse && apiResponse.filters && apiResponse.filters.version) {
    const versions = apiResponse.filters.version.map((version) => ({
      label: version,
      value: version,
    }));
    // sort versions alphabetically
    versions.sort((a, b) => a.label.localeCompare(b.label));
    return versions;
  } else {
    return [];
  }
};

export const parseFitments = (apiResponse) => {
  if (
    apiResponse &&
    apiResponse.filters &&
    apiResponse.filters.frontSizeDesignation
  ) {
    const fitments = apiResponse.filters.frontSizeDesignation.map(
      (fitment) => ({
        label: fitment,
        value: fitment,
      })
    );
    // sort fitments alphabetically
    fitments.sort((a, b) => a.label.localeCompare(b.label));
    return fitments;
  } else {
    return [];
  }
};

export const getEanFromProducts = (apiResponse) => {
  // get all EAN numbers from the response - nested articles array
  if (apiResponse && apiResponse.products) {
    const eanNumbers = apiResponse.products.flatMap((product) => {
      return product.articles.map((article) => article.ean);
    });

    // get all unique EAN numbers
    return [...new Set(eanNumbers)];
  } else {
    return [];
  }
};

// const response = {
//   current_filters: {
//     vendor: 'SKODA',
//     model: 'Octavia RS',
//     version: '2.0 TDI 184',
//     year: '2013 - 2019',
//     frontSizeDesignation: '205/50 R 17',
//   },
//   fittings: [
//     {
//       frontSizeDesignation: '225/35 R 19',
//       frontLoadSpeedIndex: '088Y',
//       rearSizeDesignation: '225/35 R 19',
//       rearLoadSpeedIndex: '088Y',
//     },
//     {
//       frontSizeDesignation: '205/50 R 17',
//       frontLoadSpeedIndex: '089H',
//       rearSizeDesignation: '205/50 R 17',
//       rearLoadSpeedIndex: '089H',
//     },
//     {
//       frontSizeDesignation: '225/40 R 18',
//       frontLoadSpeedIndex: '092Y',
//       rearSizeDesignation: '225/40 R 18',
//       rearLoadSpeedIndex: '092Y',
//     },
//     {
//       frontSizeDesignation: '225/45 R 17',
//       frontLoadSpeedIndex: '091V',
//       rearSizeDesignation: '225/45 R 17',
//       rearLoadSpeedIndex: '091V',
//     },
//   ],
//   num_articles: 17,
//   filters: {
//     pogSegment1MappingDTacs: ['passenger_car'],
//     loadSpeedIndex: ['93H', '89Y', '93Y', '93W', '93V', '89H', '89V'],
//     season: ['All Season', 'Winter', 'Summer'],
//   },
//   products: [
//     {
//       ID: '53975',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger Winter non-nordic',
//       zmi_mkt_text_long: 'WinterContact TS 870 P',
//       ev_compatible: 'no',
//       exportName_approved: 'WinterContact TS 870 P',
//       headline_approved:
//         'Zima vam prina\u0161a de\u017e, poledico in sneg. Mi vam prina\u0161amo nadzor.',
//       introText_approved:
//         'Nova pnevmatika WinterContact\u2122 TS 870 P.V Nem\u010diji zasnovana varnost, ki premaga prav vsake zimske razmere.Pri razvoju prav vsake nove pnevmatike WinterContact\u2122 se prednostno posve\u010damo konstantnemu izpopolnjevanju njenih bistvenih varnostnih lastnosti, kot so vodljivost na snegu, oprijem na snegu in zaviranje na ledu. Dodatno se osredoto\u010damo \u0161e na izbolj\u0161ane zmogljivosti na mokri in suhi podlagi, veliko kilometra\u017eo in majhen kotalni upor. In ko je rezultat tako prepri\u010dljiv kot tokrat, lahko ponosno govorimo o pravi mojstrici za vse razmere.',
//       techHighlightsDescriptionsB2C1_approved:
//         'Naj vas pozimi nikdar ne zmrazi.Ali ste vedeli, da se s snegom najbolje sprime kar sam sneg? Predstavljamo vam pnevmatiko, ki to v\u00e9denje prena\u0161a na cesto. Zaradi izbolj\u0161ane konfiguracije strmih vzporednih lamel pnevmatika s tal lahko pobere ve\u010d snega. To zagotavlja mo\u010dnej\u0161i oprijem in bolj nadzorovano vodljivost. Ko pa morate zavirati, dolgi brisalni robovi poskrbijo za izjemno hitro odvajanje vode in sne\u017ene brozge, s \u010dimer se skraj\u0161a zavorna pot na poledeneli podlagi.',
//       techHighlightsDescriptionsB2C2_approved:
//         'Ko de\u017e nemoteno odteka.Zimski dnevi lahko presenetijo tudi z de\u017ejem, zato smo razvili tehnolo\u0161ko re\u0161itev, ki vam zagotovi odli\u010dne vozne lastnosti na mokrih podlagah. Zaradi izpopolnjene recepture na\u0161e gumene zmesi CoolChili\u2122, ki vsebuje nove sonaravne sestavine, se boste vedno lahko zana\u0161ali na u\u010dinkovitej\u0161e zaviranje na mokrem, posebni kanali za odvajanje vode pa bodo poskrbeli tudi za izjemno varnost pred akvaplaningom. V mokrem vremenu ste zato lahko pomirjeni, saj ste za take razmere odli\u010dno pripravljeni.',
//       techHighlightsDescriptionsB2C3_approved:
//         'Kar vozite se naprej. In dalje.In \u0161e dalje.Manj\u0161i kotalni upor pomeni manj\u0161o porabo goriva, manj\u0161a obraba pa ve\u010d prevo\u017eenih kilometrov. To je teorija. V praksi pa smo opisano dosegli tako: Po meri prilagojena pro\u017ena polimerna struktura z mo\u010dnimi molekulskimi verigami zagotavlja manj\u0161o obrabo na cesti in podalj\u0161a \u017eivljenjsko dobo va\u0161e pnevmatike. In kdo bi se odrekel dolgo obstojni pnevmatiki, ki vam pomaga prihraniti denar?',
//       bulletpointsIntern1_approved:
//         'Inteligentna zasnova lamel in profila vam pomaga natan\u010dno krmiliti avtomobil na snegu in ledu.',
//       bulletpointsIntern2_approved:
//         'Nova receptura na\u0161e gumene zmesi CoolChili\u2122 poskrbi za do\u017eivetje odli\u010dnih zmogljivosti na mokrem.',
//       bulletpointsIntern3_approved:
//         'Velika kilometra\u017ea in majhen kotalni upor, ki pripomore k manj\u0161i porabi goriva, izbolj\u0161ata ekonomi\u010dnost.',
//       articles: [
//         {
//           ID: '53977',
//           artNo11Digit: '03556550000',
//           ean: '4019238054149',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'WinterContact TS 870 P',
//           pogSegment1: 'Passenger Winter non-nordic',
//           pogSegment1Key: 'PWI',
//           pogSegment2: "Pass. WI non-nordic 17''",
//           pogSegment2Key: 'P20D',
//           tyreTypeKey: 'TL',
//           season: 'Winter',
//           seasonKey: 'WI',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '71',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '558360',
//           REG_REF_E2: '2020/740',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93H',
//           speed1: 'H',
//           tyreType: 'TUBELESS',
//           treadDepth: '8.6',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.603',
//           maxSpeed: '210',
//           statusTog: '2',
//           modificationDate: '05/19/22',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'Winter',
//           seasonAllWeather: 'Winter',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'WinterContact TS 870 P',
//           ppmStatus: '2',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03556550000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '25/05/2021',
//         },
//         {
//           ID: '53976',
//           artNo11Digit: '03556540000',
//           ean: '4019238054156',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'WinterContact TS 870 P',
//           pogSegment1: 'Passenger Winter non-nordic',
//           pogSegment1Key: 'PWI',
//           pogSegment2: "Pass. WI non-nordic 17''",
//           pogSegment2Key: 'P20D',
//           tyreTypeKey: 'TL',
//           season: 'Winter',
//           seasonKey: 'WI',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '71',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '558374',
//           REG_REF_E2: '2020/740',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '8.6',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.603',
//           maxSpeed: '240',
//           statusTog: '2',
//           modificationDate: '05/19/22',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'Winter',
//           seasonAllWeather: 'Winter',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'WinterContact TS 870 P',
//           ppmStatus: '2',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03556540000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '27/05/2021',
//         },
//       ],
//     },
//     {
//       ID: '22989',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger Summer',
//       zmi_mkt_text_long: 'PremiumContact 6',
//       ev_compatible: 'no',
//       technicalSpecifications: [
//         'ContiSeal\u2122',
//         'ContiSilent\u2122',
//         'SSR System',
//         'ContiRe.Tex',
//       ],
//       exportName_approved: 'PremiumContact 6',
//       headline_approved: 'Naj bo va\u0161a pot varna.',
//       introText_approved: 'V Nem\u010diji zasnovana varnost za vse situacije.',
//       techHighlightsDescriptionsB2C1_approved:
//         'Varnost:Ta pnevmatika poskrbi za u\u010dinkovito ustavljanje tudi v de\u017eevnem vremenu.Vremena ne moremo nadzorovati, lahko pa poskrbimo, da boste vi imeli stvari pod nadzorom. PremiumContact\u2122 6 vsebuje gumeno zmes s siliko "Safety Silica Compound", zato zagotavlja konstanten oprijem na mokrih in suhih cestah, kar pomeni, da se lahko zanesete, da se bo va\u0161 avtomobil tudi v de\u017eevnem vremenu brez te\u017eav ustavil.',
//       techHighlightsDescriptionsB2C2_approved:
//         'Udobje:Va\u0161e dolgo potovanje se bo s to pnevmatiko zdelo kraj\u0161e.Na\u0161a izpopolnjena nale\u017ena ploskev in tog profil zmanj\u0161ata kotalni hrup v potni\u0161kem prostoru, obenem pa prispevata k ve\u010d prevo\u017eenim kilometrom in izbolj\u0161anemu udobju. Temu pravimo nale\u017ena ploskev za ve\u010dje udobje. Odslej lahko preprosto u\u017eivate v potovanju, kolikor dolgo \u017eelite.',
//       techHighlightsDescriptionsB2C3_approved:
//         'Vodljivost:Ko \u017eivljenje pospe\u0161i, vam ta pnevmatika pomaga obdr\u017eati vse pod nadzorom.Inteligentna zasnova velikih profilnih blokov zagotavlja maksimalen nadzor in stabilnost bo\u010dnega vodenja tudi pri ve\u010djih hitrostih, izbolj\u0161a oprijem za \u0161portno vo\u017enjo in prena\u0161a ve\u010d mo\u010di z avtomobila na asfalt. To preprosto pomeni, da imate vse pod nadzorom.',
//       techHighlightsDescriptionsB2B1_approved:
//         'Maksimalno zaviranje na mokrem in obenem ve\u010d prevo\u017eenih kilometrov po zaslugi gumenih zmesi s siliko.Namenski gumeni zmesi, iz katerih je izdelana tekalna plast, vsebujeta kristale silike, ki omogo\u010da vrhunsko zavorno u\u010dinkovitost, zlasti na mokri cesti. Pnevmatika zagotavlja izjemno varnost in obenem dosega ve\u010d prevo\u017eenih kilometrov, kar pomeni, da pnevmatika uspe\u0161no re\u0161uje konflikt, s katerim se proizvajalci sre\u010dujejo pri razvoju pnevmatik.',
//       techHighlightsDescriptionsB2B2_approved:
//         'Ve\u010d prevo\u017eenih kilometrov zaradi zmogljive nale\u017ene ploskve, ki ponuja visoko raven udobja.Polimerna gumena zmes, zasnovana za manj\u0161o obrabo, poskrbi za izjemno, ve\u010d kot 15-odstotno izbolj\u0161anje \u0161tevila prevo\u017eenih kilometrov. Z novimi polimeri izbolj\u0161ana gumena zmes zagotavlja ve\u010djo obstojnost tekalne plasti v voznih pogojih, ki so jim izpostavljeni avtomobili posameznih razredov. Ob tem enakomerno tog profil izbolj\u0161a udobje vo\u017enje in \u0161tevilo prevo\u017eenih kilometrov ter zmanj\u0161a kotalni hrup.',
//       techHighlightsDescriptionsB2B3_approved:
//         '\u0160portna vo\u017enja z vsakim avtomobilom po zaslugi zasnove profila, ki zagotavlja odli\u010dno vodljivost.Napredna zasnova z velikimi profilnimi bloki zagotavlja maksimalen nadzor nad vozilom in stabilnost bo\u010dnega vodenja. Tak\u0161na kompleksna struktura blokov v ramenskem delu omogo\u010da vrhunski prenos pre\u010dnih sil. Asimetri\u010dna geometrija profilnih reber poskrbi za ve\u010djo togost profila in manj deformacij blokov, rezultat pa je bolj\u0161i oprijem med delovanjem bo\u010dnih sil.',
//       bulletpointsIntern1_approved:
//         'Zanesljiva zavorna u\u010dinkovitost v vsakem vremenu.',
//       bulletpointsIntern2_approved:
//         'U\u017eivajte v udobni vo\u017enji po zaslugi na\u0161e izpopolnjene zasnove profila.',
//       bulletpointsIntern3_approved:
//         'Ohranite nadzor z na\u0161o tehnologijo profilnih blokov Macro Block.',
//       articles: [
//         {
//           ID: '38446',
//           artNo11Digit: '03570320000',
//           ean: '4019238760743',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'PremiumContact 6',
//           pogSegment1: 'Passenger Summer',
//           pogSegment1Key: 'PSU',
//           pogSegment2: "Pass. SU 17''",
//           pogSegment2Key: 'P10D',
//           tyreTypeKey: 'TL',
//           season: 'Summer',
//           seasonKey: 'SU',
//           FUEL_EFFI_BR: 'C',
//           WET_GRIP_BR: 'A',
//           ROLL_NOISE_BR: '71',
//           NOISE_UNIT_BR: 'dB',
//           NOISE_LEV_BR: '2',
//           TIRE_CLASS_BR: 'C2',
//           REG_REF_BR: '544/2012',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'A',
//           ROLL_NOISE_E2: '71',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           EPREL_REG_E2: '482198',
//           REG_REF_E2: '2020/740',
//           TREADWEAR: '280',
//           TRACTION: 'AA',
//           TEMPERATURE: 'A',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '89V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.4',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.453',
//           maxSpeed: '240',
//           statusTog: '2',
//           modificationDate: '05/19/22',
//           seasonAllSeason3PMSF: 'Summer',
//           seasonAllWeather: 'Summer',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'PremiumContact 6',
//           ppmStatus: '2',
//           dChain: 'U1',
//           headline_approved:
//             'Nova raven udobja, ki jo omogo\u010da nem\u0161ka tehnologija.',
//           introText_approved: 'Resni\u010dno vrhunsko do\u017eivetje.',
//           techHighlightsDescriptionsB2C1_approved:
//             'Maksimalno zaviranje na mokrem in obenem ve\u010d prevo\u017eenih kilometrov po zaslugi gumenih zmesi s siliko.Kristali silike v gumeni zmesi omogo\u010dajo vrhunsko zavorno u\u010dinkovitost, zlasti na mokri cesti. Pnevmatika zagotavlja izjemno varnost in obenem dosega ve\u010d prevo\u017eenih kilometrov, kar pomeni, da pnevmatika uspe\u0161no re\u0161uje konflikt, s katerim se proizvajalci sre\u010dujejo pri razvoju pnevmatik.',
//           techHighlightsDescriptionsB2C2_approved:
//             'Ve\u010d prevo\u017eenih kilometrov zaradi zmogljive nale\u017ene ploskve, ki ponuja visoko raven udobja.Polimerna gumena zmes, zasnovana za manj\u0161o obrabo, poskrbi za izjemno, ve\u010d kot 15-odstotno izbolj\u0161anje \u0161tevila prevo\u017eenih kilometrov. Ob tem enakomerno tog profil izbolj\u0161a udobje vo\u017enje in \u0161tevilo prevo\u017eenih kilometrov ter zmanj\u0161a kotalni hrup.',
//           techHighlightsDescriptionsB2C3_approved:
//             '\u0160portna vo\u017enja z vsakim avtomobilom po zaslugi zasnove profila, ki zagotavlja odli\u010dno vodljivost.Napredna zasnova z velikimi profilnimi bloki zagotavlja maksimalen nadzor nad vozilom in stabilnost bo\u010dnega vodenja. Asimetri\u010dna geometrija profilnih reber poskrbi za bolj\u0161i oprijem med delovanjem bo\u010dnih sil.',
//           bulletpointsIntern1_approved:
//             'Maksimalno zaviranje na mokrem in obenem ve\u010d prevo\u017eenih kilometrov po zaslugi gumenih zmesi s siliko',
//           bulletpointsIntern2_approved:
//             'Ve\u010d prevo\u017eenih kilometrov zaradi zmogljive nale\u017ene ploskve, ki ponuja visoko raven udobja',
//           bulletpointsIntern3_approved:
//             '\u0160portna vo\u017enja z vsakim avtomobilom po zaslugi zasnove profila, ki zagotavlja odli\u010dno vodljivost',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03570320000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/11/2016',
//         },
//         {
//           ID: '38667',
//           artNo11Digit: '03574850000',
//           ean: '4019238772340',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'PremiumContact 6',
//           pogSegment1: 'Passenger Summer',
//           pogSegment1Key: 'PSU',
//           pogSegment2: "Pass. SU 17''",
//           pogSegment2Key: 'P10D',
//           tyreTypeKey: 'TL',
//           season: 'Summer',
//           seasonKey: 'SU',
//           FUEL_EFFI_BR: 'C',
//           WET_GRIP_BR: 'A',
//           ROLL_NOISE_BR: '71',
//           NOISE_UNIT_BR: 'dB',
//           NOISE_LEV_BR: '2',
//           TIRE_CLASS_BR: 'C2',
//           REG_REF_BR: '544/2012',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'A',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           EPREL_REG_E2: '483452',
//           REG_REF_E2: '2020/740',
//           TREADWEAR: '280',
//           TRACTION: 'AA',
//           TEMPERATURE: 'A',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93Y',
//           speed1: 'Y',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.4',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.679',
//           maxSpeed: '300',
//           statusTog: '7',
//           modificationDate: '05/19/22',
//           loadCapacityKey: 'XL',
//           seasonAllSeason3PMSF: 'Summer',
//           seasonAllWeather: 'Summer',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'PremiumContact 6',
//           ppmStatus: '7',
//           dChain: 'U1',
//           headline_approved:
//             'Nova raven udobja, ki jo omogo\u010da nem\u0161ka tehnologija.',
//           introText_approved: 'Resni\u010dno vrhunsko do\u017eivetje.',
//           techHighlightsDescriptionsB2C1_approved:
//             'Maksimalno zaviranje na mokrem in obenem ve\u010d prevo\u017eenih kilometrov po zaslugi gumenih zmesi s siliko.Kristali silike v gumeni zmesi omogo\u010dajo vrhunsko zavorno u\u010dinkovitost, zlasti na mokri cesti. Pnevmatika zagotavlja izjemno varnost in obenem dosega ve\u010d prevo\u017eenih kilometrov, kar pomeni, da pnevmatika uspe\u0161no re\u0161uje konflikt, s katerim se proizvajalci sre\u010dujejo pri razvoju pnevmatik.',
//           techHighlightsDescriptionsB2C2_approved:
//             'Ve\u010d prevo\u017eenih kilometrov zaradi zmogljive nale\u017ene ploskve, ki ponuja visoko raven udobja.Polimerna gumena zmes, zasnovana za manj\u0161o obrabo, poskrbi za izjemno, ve\u010d kot 15-odstotno izbolj\u0161anje \u0161tevila prevo\u017eenih kilometrov. Ob tem enakomerno tog profil izbolj\u0161a udobje vo\u017enje in \u0161tevilo prevo\u017eenih kilometrov ter zmanj\u0161a kotalni hrup.',
//           techHighlightsDescriptionsB2C3_approved:
//             '\u0160portna vo\u017enja z vsakim avtomobilom po zaslugi zasnove profila, ki zagotavlja odli\u010dno vodljivost.Napredna zasnova z velikimi profilnimi bloki zagotavlja maksimalen nadzor nad vozilom in stabilnost bo\u010dnega vodenja. Asimetri\u010dna geometrija profilnih reber poskrbi za bolj\u0161i oprijem med delovanjem bo\u010dnih sil.',
//           bulletpointsIntern1_approved:
//             'Maksimalno zaviranje na mokrem in obenem ve\u010d prevo\u017eenih kilometrov po zaslugi gumenih zmesi s siliko',
//           bulletpointsIntern2_approved:
//             'Ve\u010d prevo\u017eenih kilometrov zaradi zmogljive nale\u017ene ploskve, ki ponuja visoko raven udobja',
//           bulletpointsIntern3_approved:
//             '\u0160portna vo\u017enja z vsakim avtomobilom po zaslugi zasnove profila, ki zagotavlja odli\u010dno vodljivost',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03574850000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/12/2016',
//         },
//       ],
//     },
//     {
//       ID: '90678',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger All Season',
//       zmi_mkt_text_long: 'AllSeasonContact 2',
//       ev_compatible: 'yes',
//       exportName_approved: 'AllSeasonContact 2',
//       headline_approved:
//         'Vremena ne moremo nadzorovati. Lahko pa se dobro pripravimo.',
//       bulletpointsIntern1_approved:
//         'Do\u017eivite ve\u010djo u\u010dinkovitost po zaslugi na\u0161ih naprednih materialov in tehnologije izdelave tekalne plasti.',
//       bulletpointsIntern2_approved:
//         'Po\u010dutite se varno v vsakem vremenu zahvaljujo\u010d bolj\u0161i vodljivosti in u\u010dinkovitej\u0161emu zaviranju.',
//       bulletpointsIntern3_approved:
//         'U\u017eivajte v na\u0161i novo zasnovani prilagodljivi tekalni plasti, ki vam vse leto nudi enakomerno in udobno vo\u017enjo.',
//       articles: [
//         {
//           ID: '90906',
//           artNo11Digit: '03558120000',
//           ean: '4019238092691',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'AllSeasonContact 2',
//           pogSegment1: 'Passenger All Season',
//           pogSegment1Key: 'PAS',
//           pogSegment2: "Pass. AS 17''",
//           pogSegment2Key: 'P15D',
//           tyreTypeKey: 'TL',
//           season: 'All Season',
//           seasonKey: 'AS',
//           FUEL_EFFI_E2: 'B',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '1230972',
//           REG_REF_E2: '2020/740',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93W',
//           speed1: 'W',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.6',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.813',
//           maxSpeed: '270',
//           statusTog: '1',
//           modificationDate: '04/24/22',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'All Season',
//           seasonAllWeather: 'All Weather',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'AllSeasonContact 2',
//           ppmStatus: '1',
//           pogSegment1MappingDTacs: 'passenger_car',
//         },
//         {
//           ID: '90905',
//           artNo11Digit: '03558110000',
//           ean: '4019238092684',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'AllSeasonContact 2',
//           pogSegment1: 'Passenger All Season',
//           pogSegment1Key: 'PAS',
//           pogSegment2: "Pass. AS 17''",
//           pogSegment2Key: 'P15D',
//           tyreTypeKey: 'TL',
//           season: 'All Season',
//           seasonKey: 'AS',
//           FUEL_EFFI_E2: 'B',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '1230971',
//           REG_REF_E2: '2020/740',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.9',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.813',
//           maxSpeed: '240',
//           statusTog: '1',
//           modificationDate: '04/24/22',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'All Season',
//           seasonAllWeather: 'All Weather',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'AllSeasonContact 2',
//           ppmStatus: '1',
//           pogSegment1MappingDTacs: 'passenger_car',
//         },
//       ],
//     },
//     {
//       ID: '37152',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger Winter non-nordic',
//       zmi_mkt_text_long: 'ContiWinterContact TS 830 P',
//       ev_compatible: 'no',
//       activate: true,
//       exportName_approved: 'ContiWinterContact TS 830 P',
//       headline_approved:
//         'Zmogljiva zimska pnevmatika za visoko zmogljive avtomobile',
//       techHighlightsDescriptionsB2C1_approved:
//         'Izjemna mo\u010d zaviranja na ledu in snegu.Zelo togi bloki v sredini profila pnevmatike in \u0161tevilne lamele, ki tvorijo veliko oprijemnih robov, poskrbijo za bolj\u0161o zavorno mo\u010d na mokrih in poledenelih cestah.',
//       techHighlightsDescriptionsB2C2_approved:
//         'Bolj\u0161i oprijem na snegu.Veliko \u0161tevilo blokov in lamel na ramenih pnevmatike ustvarja \u0161tevilne oprijemne robove in posledi\u010dno u\u010dinkovitej\u0161i oprijem na snegu.',
//       techHighlightsDescriptionsB2C3_approved:
//         'Ve\u010d prevo\u017eenih kilometrov. Zaradi enakomerno porazdeljene togosti blokov in posledi\u010dno enakomerne razporeditve pritiska po nale\u017eni ploskvi v stiku s podlago ima pnevmatika manj\u0161o in enakomernej\u0161o obrabo, rezultat pa je ve\u010d prevo\u017eenih kilometrov.',
//       techHighlightsDescriptionsB2B1_approved:
//         'Izjemna mo\u010d zaviranja na ledu in snegu.Zelo togi bloki v sredini profila pnevmatike in \u0161tevilne lamele, ki tvorijo veliko oprijemnih robov, poskrbijo za bolj\u0161o zavorno mo\u010d na mokrih in poledenelih cestah.',
//       techHighlightsDescriptionsB2B2_approved:
//         'Bolj\u0161i oprijem na snegu.Veliko \u0161tevilo blokov in lamel na ramenih pnevmatike ustvarja \u0161tevilne oprijemne robove in posledi\u010dno u\u010dinkovitej\u0161i oprijem na snegu.',
//       techHighlightsDescriptionsB2B3_approved:
//         'Ve\u010d prevo\u017eenih kilometrov. Zaradi enakomerno porazdeljene togosti blokov in posledi\u010dno enakomerne razporeditve pritiska po nale\u017eni ploskvi v stiku s podlago ima pnevmatika manj\u0161o in enakomernej\u0161o obrabo, rezultat pa je ve\u010d prevo\u017eenih kilometrov.',
//       bulletpointsIntern1_approved:
//         'Izjemna mo\u010d zaviranja na ledu in snegu',
//       bulletpointsIntern2_approved: 'Bolj\u0161i oprijem na snegu',
//       bulletpointsIntern3_approved: 'Ve\u010d prevo\u017eenih kilometrov',
//       articles: [
//         {
//           ID: '37164',
//           artNo11Digit: '03531270000',
//           ean: '4019238454239',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'ContiWinterContact TS 830 P',
//           pogSegment1: 'Passenger Winter non-nordic',
//           pogSegment1Key: 'PWI',
//           pogSegment2: "Pass. WI non-nordic 17''",
//           pogSegment2Key: 'P20D',
//           tyreTypeKey: 'TL',
//           season: 'Winter',
//           seasonKey: 'WI',
//           FUEL_EFFI_E2: 'D',
//           WET_GRIP_E2: 'C',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '479570',
//           REG_REF_E2: '2020/740',
//           oeManufacture: 'MERCEDES-BENZ',
//           oeKey: 'MO',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93H',
//           speed1: 'H',
//           tyreType: 'TUBELESS',
//           treadDepth: '8.8',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '9.144',
//           maxSpeed: '210',
//           statusTog: '2',
//           modificationDate: '09/20/19',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'Winter',
//           seasonAllWeather: 'Winter',
//           ev_compatible: 'no',
//           zmi_mkt_text_long: 'ContiWinterContact TS 830 P',
//           ppmStatus: '2',
//           dChain: 'UE',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl/03531270000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/03/2011',
//         },
//         {
//           ID: '37159',
//           artNo11Digit: '03531090000',
//           ean: '4019238447668',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'ContiWinterContact TS 830 P',
//           pogSegment1: 'Passenger Winter non-nordic',
//           pogSegment1Key: 'PWI',
//           pogSegment2: "Pass. WI non-nordic 17''",
//           pogSegment2Key: 'P20D',
//           tyreTypeKey: 'TL',
//           season: 'Winter',
//           seasonKey: 'WI',
//           FUEL_EFFI_E2: 'E',
//           WET_GRIP_E2: 'C',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '506057',
//           REG_REF_E2: '2020/740',
//           oeManufacture: 'VW',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           sealant: 'X',
//           loadSpeedIndex: '93H',
//           speed1: 'H',
//           tyreType: 'TUBELESS',
//           treadDepth: '8.8',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '10.701',
//           maxSpeed: '210',
//           statusTog: '2',
//           modificationDate: '09/20/19',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           extMobilitySysKey: 'C_S',
//           seasonAllSeason3PMSF: 'Winter',
//           seasonAllWeather: 'Winter',
//           ev_compatible: 'no',
//           zmi_mkt_text_long: 'ContiWinterContact TS 830 P',
//           ppmStatus: '2',
//           dChain: 'UE',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl/03531090000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/09/2009',
//         },
//       ],
//     },
//     {
//       ID: '23003',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger Summer',
//       zmi_mkt_text_long: 'ContiEcoContact 5',
//       ev_compatible: 'no',
//       exportName_approved: 'ContiEcoContact\u2122 5',
//       headline_approved: 'V prid varnosti in okolju.',
//       introText_approved: 'Idealna za varno in var\u010dno vo\u017enjo.',
//       techHighlightsDescriptionsB2C1_approved:
//         'Optimiziran kotalni upor za manj\u0161o porabo goriva.Ta najsodobnej\u0161a pnevmatika je idealna za voznike, ki vozijo na dolge relacije, in vse tiste, ki pazijo na porabo goriva in skrbijo za okolje.',
//       techHighlightsDescriptionsB2C2_approved:
//         'Izjemna varnost in kratka zavorna pot na mokri cesti.Pnevmatika ima zasnovo za izbolj\u0161an oprijem na mokrem, zato zagotavlja varno zaviranje.',
//       techHighlightsDescriptionsB2C3_approved:
//         'Zmagovalne pnevmatike.Pnevmatiko ContiEcoContact\u2122 5 je revija Which? maja 2014 izbrala za najbolj\u0161i nakup.',
//       techHighlightsDescriptionsB2B1_approved:
//         'Optimiziran kotalni upor za manj\u0161o porabo goriva.Pnevmatiko ContiEcoContact\u2122 5 smo razvili v tesnem sodelovanju z vodilnimi evropskimi avtomobilskimi proizvajalci. To je pnevmatika s povsem novo zasnovo, namenjena zlasti voznikom, ki vozijo na dolge relacije, a tudi voznikom, ki pazijo na porabo goriva in skrbijo za okolje. Najve\u010dji izziv je bil, kako zmanj\u0161ati kotalni upor pnevmatike in hkrati zagotoviti visoko raven varnosti med zaviranjem. Za to smo potrebovali povsem nov pristop do vseh \u0161tirih komponent pnevmatike: zasnove, konture, gumene zmesi in profila tekalne plasti. Rezultat so manj\u0161e emisije CO2 in manj\u0161a poraba goriva ob \u0161e vedno odli\u010dni zavorni u\u010dinkovitosti.',
//       techHighlightsDescriptionsB2B2_approved:
//         'Zelo varno zaviranje in kratka zavorna pot na mokri cesti.Kombinacija posebnih aditivov in optimiziranih robov profilnih blokov poskrbi za izbolj\u0161an oprijem na mokrem, kar zagotavlja kratko, varno zavorno pot na mokri cesti. Poleg tega namenske polimerne verige, ki so trdno povezane s siliko, pomenijo manj\u0161i kotalni upor. Izjemno tanke lamele pomagajo zmanj\u0161ati deformiranje profila in tako zmanj\u0161ujejo energetske izgube.',
//       techHighlightsDescriptionsB2B3_approved:
//         'Najbolj\u0161a vodljivost na mokri in suhi cesti.Vi\u0161je jedro v nogi pnevmatike in uporaba dodatnih oja\u010ditev noge pnevmatike zagotavljata varnej\u0161i in bolj neposreden prenos bo\u010dnih sil, ki se pojavijo med vo\u017enjo skozi zavoje na mokri in suhi cesti. Bo\u010dnica je la\u017eja ter po zasnovi pro\u017enej\u0161a, kar ravno tako prispeva k manj\u0161emu kotalnemu uporu.',
//       bulletpointsIntern1_approved:
//         'Optimiziran kotalni upor za manj\u0161o porabo goriva',
//       bulletpointsIntern2_approved:
//         'Zelo varno zaviranje in kratka zavorna pot na mokri cesti',
//       bulletpointsIntern3_approved:
//         'Najbolj\u0161a vodljivost na mokri in suhi cesti',
//       articles: [
//         {
//           ID: '38241',
//           artNo11Digit: '03565670000',
//           ean: '4019238583298',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'ContiEcoContact 5',
//           pogSegment1: 'Passenger Summer',
//           pogSegment1Key: 'PSU',
//           pogSegment2: "Pass. SU 17''",
//           pogSegment2Key: 'P10D',
//           tyreTypeKey: 'TL',
//           season: 'Summer',
//           seasonKey: 'SU',
//           FUEL_EFFI_BR: 'B',
//           WET_GRIP_BR: 'B',
//           ROLL_NOISE_BR: '71',
//           NOISE_UNIT_BR: 'dB',
//           NOISE_LEV_BR: '2',
//           TIRE_CLASS_BR: 'C2',
//           REG_REF_BR: '544/2012',
//           FUEL_EFFI_E2: 'B',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           EPREL_REG_E2: '483494',
//           REG_REF_E2: '2020/740',
//           oeManufacture: 'VW',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           sealant: 'X',
//           loadSpeedIndex: '93V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.4',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '10.055',
//           maxSpeed: '240',
//           statusTog: '2',
//           modificationDate: '05/17/22',
//           loadCapacityKey: 'XL',
//           extMobilitySysKey: 'C_S',
//           seasonAllSeason3PMSF: 'Summer',
//           seasonAllWeather: 'Summer',
//           ev_compatible: 'no',
//           zmi_mkt_text_long: 'ContiEcoContact 5',
//           ppmStatus: '2',
//           exportName_approved: 'ContiEcoContact\u2122 5',
//           headline_approved: 'V prid varnosti in okolju.',
//           introText_approved: 'Idealna za varno in var\u010dno vo\u017enjo.',
//           techHighlightsDescriptionsB2C1_approved:
//             'Optimiziran kotalni upor za manj\u0161o porabo goriva.Ta najsodobnej\u0161a pnevmatika je idealna za voznike, ki vozijo na dolge relacije, in vse tiste, ki pazijo na porabo goriva in skrbijo za okolje.',
//           techHighlightsDescriptionsB2C2_approved:
//             'Izjemna varnost in kratka zavorna pot na mokri cesti.Pnevmatika ima zasnovo za izbolj\u0161an oprijem na mokrem, zato zagotavlja varno zaviranje.',
//           techHighlightsDescriptionsB2C3_approved:
//             'Zmagovalne pnevmatike.Pnevmatiko ContiEcoContact\u2122 5 je revija Which? maja 2014 izbrala za najbolj\u0161i nakup.',
//           techHighlightsDescriptionsB2B1_approved:
//             'Optimiziran kotalni upor za manj\u0161o porabo goriva.Pnevmatiko ContiEcoContact\u2122 5 smo razvili v tesnem sodelovanju z vodilnimi evropskimi avtomobilskimi proizvajalci. To je pnevmatika s povsem novo zasnovo, namenjena zlasti voznikom, ki vozijo na dolge relacije, a tudi voznikom, ki pazijo na porabo goriva in skrbijo za okolje. Najve\u010dji izziv je bil, kako zmanj\u0161ati kotalni upor pnevmatike in hkrati zagotoviti visoko raven varnosti med zaviranjem. Za to smo potrebovali povsem nov pristop do vseh \u0161tirih komponent pnevmatike: zasnove, konture, gumene zmesi in profila tekalne plasti. Rezultat so manj\u0161e emisije CO2 in manj\u0161a poraba goriva ob \u0161e vedno odli\u010dni zavorni u\u010dinkovitosti.',
//           techHighlightsDescriptionsB2B2_approved:
//             'Zelo varno zaviranje in kratka zavorna pot na mokri cesti.Kombinacija posebnih aditivov in optimiziranih robov profilnih blokov poskrbi za izbolj\u0161an oprijem na mokrem, kar zagotavlja kratko, varno zavorno pot na mokri cesti. Poleg tega namenske polimerne verige, ki so trdno povezane s siliko, pomenijo manj\u0161i kotalni upor. Izjemno tanke lamele pomagajo zmanj\u0161ati deformiranje profila in tako zmanj\u0161ujejo energetske izgube.',
//           techHighlightsDescriptionsB2B3_approved:
//             'Najbolj\u0161a vodljivost na mokri in suhi cesti.Vi\u0161je jedro v nogi pnevmatike in uporaba dodatnih oja\u010ditev noge pnevmatike zagotavljata varnej\u0161i in bolj neposreden prenos bo\u010dnih sil, ki se pojavijo med vo\u017enjo skozi zavoje na mokri in suhi cesti. Bo\u010dnica je la\u017eja ter po zasnovi pro\u017enej\u0161a, kar ravno tako prispeva k manj\u0161emu kotalnemu uporu.',
//           bulletpointsIntern1_approved:
//             'Optimiziran kotalni upor za manj\u0161o porabo goriva',
//           bulletpointsIntern2_approved:
//             'Zelo varno zaviranje in kratka zavorna pot na mokri cesti',
//           bulletpointsIntern3_approved:
//             'Najbolj\u0161a vodljivost na mokri in suhi cesti',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03565670000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/11/2013',
//         },
//       ],
//       microsite_eulabel_link:
//         'https://www.contimediacenter.com/eulabel/sl_SL/03565670000/',
//       pogSegment1MappingDTacs: 'passenger_car',
//       ppmProdSince: '01/11/2013',
//       ppmStatus: '2',
//     },
//     {
//       ID: '24039',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger Summer',
//       zmi_mkt_text_long: 'ContiPremiumContact 2',
//       ev_compatible: 'no',
//       exportName_approved: 'ContiPremiumContact\u2122 2',
//       headline_approved:
//         'Bolj\u0161e zaviranje za srednje velike in luksuzne avtomobile',
//       techHighlightsDescriptionsB2C1_approved:
//         'Izjemna zavorna u\u010dinkovitost v mokrih in suhih razmerah.Prostorsko oblikovani (3D) kanali pomenijo optimalno zavorno u\u010dinkovitost v mokrih in suhih razmerah. Zaradi enakomerne razporeditve pritiska pnevmatika zagotavlja zelo kratko zavorno pot.',
//       techHighlightsDescriptionsB2C2_approved:
//         'Odli\u010dna odpornost proti akvaplaningu.Zaradi pove\u010danega \u0161tevila profilnih blokov in odprto oblikovanega notranjega ramenskega dela pnevmatika u\u010dinkoviteje odvaja vodo. Skladna okrogla oblika nale\u017ene ploskve zagotavlja odli\u010dno za\u0161\u010dito pred akvaplaningom.',
//       techHighlightsDescriptionsB2C3_approved:
//         'Natan\u010dna vodljivost in nepremagljiva stabilnost v vo\u017enji.Asimetri\u010den profil je izdelan tako, da je ramenski del bolj tog, kar zagotavlja optimalen stik s cesto.',
//       techHighlightsDescriptionsB2B1_approved:
//         'Izjemna zavorna u\u010dinkovitost v mokrih in suhih razmerah.Prostorsko oblikovani (3D) kanali pomenijo optimalno zavorno u\u010dinkovitost v mokrih in suhih razmerah. Zaradi enakomerne razporeditve pritiska pnevmatika zagotavlja zelo kratko zavorno pot.',
//       techHighlightsDescriptionsB2B2_approved:
//         'Odli\u010dna odpornost proti akvaplaningu.Zaradi pove\u010danega \u0161tevila profilnih blokov in odprto oblikovanega notranjega ramenskega dela pnevmatika u\u010dinkoviteje odvaja vodo. Skladna okrogla oblika nale\u017ene ploskve zagotavlja odli\u010dno za\u0161\u010dito pred akvaplaningom.',
//       techHighlightsDescriptionsB2B3_approved:
//         'Natan\u010dna vodljivost in nepremagljiva stabilnost v vo\u017enji.Asimetri\u010den profil je izdelan tako, da je ramenski del bolj tog, kar zagotavlja optimalen stik s cesto.',
//       bulletpointsIntern1_approved:
//         'Izjemna zavorna u\u010dinkovitost v mokrih in suhih razmerah',
//       bulletpointsIntern2_approved: 'Odli\u010dna odpornost proti akvaplaningu',
//       bulletpointsIntern3_approved:
//         'Natan\u010dna vodljivost in nepremagljiva stabilnost v vo\u017enji',
//       articles: [
//         {
//           ID: '24146',
//           artNo11Digit: '03508180000',
//           ean: '4019238469899',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'ContiPremiumContact 2',
//           pogSegment1: 'Passenger Summer',
//           pogSegment1Key: 'PSU',
//           pogSegment2: "Pass. SU 17''",
//           pogSegment2Key: 'P10D',
//           tyreTypeKey: 'TL',
//           season: 'Summer',
//           seasonKey: 'SU',
//           FUEL_EFFI_BR: 'E',
//           WET_GRIP_BR: 'B',
//           ROLL_NOISE_BR: '72',
//           NOISE_UNIT_BR: 'dB',
//           NOISE_LEV_BR: '2',
//           TIRE_CLASS_BR: 'C2',
//           REG_REF_BR: '544/2012',
//           FUEL_EFFI_E2: 'D',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '71',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           EPREL_REG_E2: '482080',
//           REG_REF_E2: '2020/740',
//           TREADWEAR: '280',
//           TRACTION: 'AA',
//           TEMPERATURE: 'A',
//           oeManufacture: 'BMW',
//           oeKey: '*',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '89Y',
//           speed1: 'Y',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.4',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '10.522',
//           maxSpeed: '300',
//           statusTog: '2',
//           modificationDate: '05/16/22',
//           extMobilitySysKey: 'SSR',
//           seasonAllSeason3PMSF: 'Summer',
//           seasonAllWeather: 'Summer',
//           ev_compatible: 'no',
//           zmi_mkt_text_long: 'ContiPremiumContact 2',
//           ppmStatus: '2',
//           dChain: 'U1',
//           exportName_approved: 'ContiPremiumContact\u2122 2',
//           techHighlightsDescriptionsB2C1_approved:
//             'Izjemna zavorna u\u010dinkovitost v mokrih in suhih razmerah.Prostorsko oblikovani (3D) kanali pomenijo optimalno zavorno u\u010dinkovitost v mokrih in suhih razmerah. Zaradi enakomerne razporeditve pritiska pnevmatika zagotavlja zelo kratko zavorno pot.',
//           techHighlightsDescriptionsB2C3_approved:
//             'Natan\u010dna vodljivost in nepremagljiva stabilnost v vo\u017enji.Asimetri\u010den profil je izdelan tako, da je ramenski del bolj tog, kar zagotavlja optimalen stik s cesto.',
//           techHighlightsDescriptionsB2B1_approved:
//             'Izjemna zavorna u\u010dinkovitost v mokrih in suhih razmerah.Prostorsko oblikovani (3D) kanali pomenijo optimalno zavorno u\u010dinkovitost v mokrih in suhih razmerah. Zaradi enakomerne razporeditve pritiska pnevmatika zagotavlja zelo kratko zavorno pot.',
//           techHighlightsDescriptionsB2B3_approved:
//             'Natan\u010dna vodljivost in nepremagljiva stabilnost v vo\u017enji.Asimetri\u010den profil je izdelan tako, da je ramenski del bolj tog, kar zagotavlja optimalen stik s cesto.',
//           bulletpointsIntern1_approved:
//             'Izjemna zavorna u\u010dinkovitost v mokrih in suhih razmerah',
//           bulletpointsIntern3_approved:
//             'Natan\u010dna vodljivost in nepremagljiva stabilnost v vo\u017enji',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03508180000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/03/2011',
//         },
//       ],
//       microsite_eulabel_link:
//         'https://www.contimediacenter.com/eulabel/sl_SL/03508180000/',
//       pogSegment1MappingDTacs: 'passenger_car',
//       ppmProdSince: '01/03/2011',
//       ppmStatus: '2',
//       dChain: 'U1',
//     },
//     {
//       ID: '22975',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger Summer',
//       zmi_mkt_text_long: 'EcoContact 6 ',
//       ev_compatible: 'no',
//       exportName_approved: 'EcoContact 6',
//       headline_approved: 'Odkrijte ve\u010d s pnevmatiko, ki potrebuje manj.',
//       bulletpointsIntern1_approved: 'Manj\u0161a poraba goriva in emisije CO2.',
//       bulletpointsIntern2_approved:
//         'Prevozite ve\u010d po zaslugi gumene zmesi Green Chilli\u2122 2.0.',
//       bulletpointsIntern3_approved:
//         'Obvladajte vsak zavoj in ovinek z optimiziranima oprijemom in vodljivostjo.',
//       articles: [
//         {
//           ID: '83519',
//           artNo11Digit: '03132300000',
//           ean: '4019238079845',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'EcoContact 6',
//           pogSegment1: 'Passenger Summer',
//           pogSegment1Key: 'PSU',
//           pogSegment2: "Pass. SU 17''",
//           pogSegment2Key: 'P10D',
//           tyreTypeKey: 'TL',
//           season: 'Summer',
//           seasonKey: 'SU',
//           FUEL_EFFI_E2: 'A',
//           WET_GRIP_E2: 'A',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           EPREL_REG_E2: '849102',
//           REG_REF_E2: '2020/740',
//           TREADWEAR: '320',
//           TRACTION: 'A',
//           TEMPERATURE: 'A',
//           oeManufacture: 'RENAULT',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '5.9',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.35',
//           maxSpeed: '240',
//           statusTog: '7',
//           modificationDate: '04/21/22',
//           loadCapacityKey: 'XL',
//           seasonAllSeason3PMSF: 'Summer',
//           seasonAllWeather: 'Summer',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'EcoContact 6 ',
//           ppmStatus: '7',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl/03132300000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '13/12/2021',
//         },
//       ],
//       microsite_eulabel_link:
//         'https://www.contimediacenter.com/eulabel/sl/03132300000/',
//       pogSegment1MappingDTacs: 'passenger_car',
//       ppmProdSince: '13/12/2021',
//       ppmStatus: '7',
//     },
//     {
//       ID: '37820',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger All Season',
//       zmi_mkt_text_long: 'AllSeasonContact',
//       ev_compatible: 'yes',
//       exportName_approved: 'AllSeasonContact',
//       headline_approved: 'Imejte nadzor nad vsemi letnimi \u010dasi.',
//       introText_approved:
//         'V Nem\u010diji zasnova varnost varnosti skozi vse leto.',
//       techHighlightsDescriptionsB2C1_approved:
//         'Nadzor:Veselite se vsega, kar je pred vami.Mokre in blatne ceste ali globoke lu\u017ee: AllSeasonContact\u2122 je pripravljena na vse. S kombinacijo odprte konstrukcije ramen za odli\u010dno delovanje proti akvaplaningu in togega vzorca boste ohranili nadzor skozi vse leto.',
//       techHighlightsDescriptionsB2C2_approved:
//         'Varnost skozi vse leto:Kadar so vremenske razmere negotove, potrebujete nekaj, na kar se lahko zanesete.\u010ce je zimo te\u017eko lo\u010diti od jeseni in poletje od pomladi, AllSeasonContact\u2122 nima \u010disto ni\u010d proti. Z gumeno zmesjo Traction Silica Compound se lahko zanesete na brezhiben oprijem skozi vse leto in odli\u010dno zavorno zmogljivost.',
//       techHighlightsDescriptionsB2C3_approved:
//         'Izkoristek:Pomembno je ve\u010d, ne manj.Prihranite pri gorivu, zmanj\u0161ajte \u0161kodljive izpuste, pri tem pa ra\u010dunajte na izjemno u\u010dinkovitost vo\u017enje.',
//       techHighlightsDescriptionsB2B1_approved:
//         'Odli\u010den oprijem na zasne\u017eenih in mokrih zimskih cestah zaradi prilagodljive celoletne gumene zmesi.Nova pnevmatika AllSeasonContact\u2122 je izdelana iz gumene zmesi \u201eTraction Silica Compound\u201c, ki vsebuje smole za zimsko u\u010dinkovitost ter visok dele\u017e silike, zato stalno ohranja nespremenjeno pro\u017enost. Tak\u0161na prilagodljivost zagotavlja odli\u010dne rezultate na zasne\u017eenih in mokrih zimskih cestah.Za dobro zavorno u\u010dinkovitost na suhih in mokrih poletnih cestah skrbi pro\u017ena tekalna plast, ki je prilagojena za uporabo v vseh letnih \u010dasih.Nova pnevmatika AllSeasonContact\u2122 je zanesljiva sopotnica, ki prepri\u010da v vseh poletnih vremenskih razmerah. To ji omogo\u010da kombinacija odprto izdelanih ramen za u\u010dinkovito prepre\u010devanje akvaplaninga ter togega profila z minimalnim \u0161tevilom lamel za bolj\u0161o vodljivost in zavorno u\u010dinkovitost na suhih cestah.Zaradi strukture, ki je bila izpopolnjena za celoletno uporabo in optimizirana za majhno porabo energije, je pnevmatika najbolj\u0161a v svojem razredu po kotalnem uporu.Zaradi precej\u0161njega zmanj\u0161anja kotalnega upora, ki temelji na izpopolnitvi geometrije bo\u010dnic in optimalni globini profila, pnevmatika dosega opazno manj\u0161o porabo goriva \u2013 in to ob nezmanj\u0161ani varnosti.',
//       bulletpointsIntern1_approved:
//         'Odli\u010den oprijem na zasne\u017eenih in mokrih zimskih cestah.',
//       bulletpointsIntern2_approved:
//         'Dobra zavorna u\u010dinkovitost na suhih in mokrih poletnih cestah.',
//       bulletpointsIntern3_approved:
//         'Najbolj\u0161a v svojem razredu po kotalnem uporu.',
//       articles: [
//         {
//           ID: '38073',
//           artNo11Digit: '03553920000',
//           ean: '4019238029482',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'AllSeasonContact',
//           pogSegment1: 'Passenger All Season',
//           pogSegment1Key: 'PAS',
//           pogSegment2: "Pass. AS 17''",
//           pogSegment2Key: 'P15D',
//           tyreTypeKey: 'TL',
//           season: 'All Season',
//           seasonKey: 'AS',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '479413',
//           REG_REF_E2: '2020/740',
//           oeManufacture: 'FORD',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '89H',
//           speed1: 'H',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.9',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.685',
//           maxSpeed: '210',
//           statusTog: '2',
//           modificationDate: '05/19/22',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'All Season',
//           seasonAllWeather: 'All Weather',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'AllSeasonContact',
//           ppmStatus: '2',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03553920000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/11/2018',
//         },
//         {
//           ID: '37824',
//           artNo11Digit: '03550720000',
//           ean: '4019238791426',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'AllSeasonContact',
//           pogSegment1: 'Passenger All Season',
//           pogSegment1Key: 'PAS',
//           pogSegment2: "Pass. AS 17''",
//           pogSegment2Key: 'P15D',
//           tyreTypeKey: 'TL',
//           season: 'All Season',
//           seasonKey: 'AS',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '482929',
//           REG_REF_E2: '2020/740',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.9',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.406',
//           maxSpeed: '240',
//           statusTog: '7',
//           modificationDate: '05/19/22',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'All Season',
//           seasonAllWeather: 'All Weather',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'AllSeasonContact',
//           ppmStatus: '7',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03550720000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/07/2017',
//         },
//         {
//           ID: '38046',
//           artNo11Digit: '03553560000',
//           ean: '4019238024081',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'AllSeasonContact',
//           pogSegment1: 'Passenger All Season',
//           pogSegment1Key: 'PAS',
//           pogSegment2: "Pass. AS 17''",
//           pogSegment2Key: 'P15D',
//           tyreTypeKey: 'TL',
//           season: 'All Season',
//           seasonKey: 'AS',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'B',
//           ROLL_NOISE_E2: '72',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '482835',
//           REG_REF_E2: '2020/740',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93W',
//           speed1: 'W',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.9',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.406',
//           maxSpeed: '270',
//           statusTog: '7',
//           modificationDate: '05/19/22',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           seasonAllSeason3PMSF: 'All Season',
//           seasonAllWeather: 'All Weather',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'AllSeasonContact',
//           ppmStatus: '7',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03553560000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '24/04/2019',
//         },
//       ],
//     },
//     {
//       ID: '56199',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger Summer',
//       zmi_mkt_text_long: 'UltraContact',
//       ev_compatible: 'no',
//       exportName_approved: 'UltraContact',
//       headline_approved: 'Izdelana, da traja.',
//       introText_approved:
//         'V Nem\u010diji zasnovana dolga \u017eivljenjska doba.',
//       techHighlightsDescriptionsB2C1_approved:
//         'Na\u0161 recept za izjemno dolgotrajen u\u017eitek v vo\u017enji.Vo\u017enja je veliko zabavnej\u0161a od skrbi glede novih pnevmatik, zato vam pnevmatika UltraContact s svojo gumeno zmesjo YellowChili in usklajeno zasnovo profila omogo\u010da izredno veliko prevo\u017eenih kilometrov ter varno, uravnote\u017eeno do\u017eivljanje vo\u017enje. Vse to s ciljem, da vam \u0161e dolgo ne bo treba skrbeti glede nakupa novih pnevmatik.',
//       techHighlightsDescriptionsB2C2_approved:
//         'Na\u0161a pnevmatika odli\u010dno kljubuje tudi najbolj grobim vozi\u0161\u010dem.Vo\u017enja postane u\u017eitek, kadar ve\u0161, da te skoraj ni\u010d ne more ustaviti. Novo pnevmatiko UltraContact smo zasnovali s ciljem, da bi v vas prebudili natanko ta ob\u010dutek: Po zaslugi vsestranske za\u0161\u010ditne konstrukcije z debelo in robustno karkaso UltraShield zagotavlja izjemno za\u0161\u010dito pred po\u0161kodbami med vsakdanjo vo\u017enjo. Skratka, \u017eelimo vam obilo prijetnih trenutkov na ne\u0161teto razli\u010dnih ulicah, od skrajno grobih do najbolj gladkih.',
//       techHighlightsDescriptionsB2C3_approved:
//         'Za varne zmogljivosti na mokri cesti.\u017delimo, da u\u017eivate v vsakem prevo\u017eenem kilometru, tudi ko de\u017euje. Zaradi \u0161tevilnih edinstveno oblikovanih lamel se med vo\u017enjo po mokrem izbolj\u0161a tako imenovani brisalni u\u010dinek, kar omogo\u010da kratko zavorno pot, posebni bla\u017eilniki hrupa v kanalih pa poskrbijo za opazno zmanj\u0161anje kotalnega hrupa. Vse to z namenom, da bi bila vsaka pot, na katero se podate, varna in udobna.',
//       techHighlightsDescriptionsB2B1_approved:
//         'Na\u0161 recept za izjemno dolgotrajen u\u017eitek v vo\u017enji.Vo\u017enja je veliko zabavnej\u0161a od skrbi glede novih pnevmatik, zato vam pnevmatika UltraContact s svojo gumeno zmesjo YellowChili in usklajeno zasnovo profila omogo\u010da izredno veliko prevo\u017eenih kilometrov ter varno, uravnote\u017eeno do\u017eivljanje vo\u017enje. Vse to s ciljem, da vam \u0161e dolgo ne bo treba skrbeti glede nakupa novih pnevmatik.',
//       techHighlightsDescriptionsB2B2_approved:
//         'Na\u0161a pnevmatika odli\u010dno kljubuje tudi najbolj grobim vozi\u0161\u010dem.Vo\u017enja postane u\u017eitek, kadar ve\u0161, da te skoraj ni\u010d ne more ustaviti. Novo pnevmatiko UltraContact smo zasnovali s ciljem, da bi v vas prebudili natanko ta ob\u010dutek: Po zaslugi vsestranske za\u0161\u010ditne konstrukcije z debelo in robustno karkaso UltraShield zagotavlja izjemno za\u0161\u010dito pred po\u0161kodbami med vsakdanjo vo\u017enjo. Skratka, \u017eelimo vam obilo prijetnih trenutkov na ne\u0161teto razli\u010dnih ulicah, od skrajno grobih do najbolj gladkih.',
//       techHighlightsDescriptionsB2B3_approved:
//         'Za varne zmogljivosti na mokri cesti.\u017delimo, da u\u017eivate v vsakem prevo\u017eenem kilometru, tudi ko de\u017euje. Zaradi \u0161tevilnih edinstveno oblikovanih lamel se med vo\u017enjo po mokrem izbolj\u0161a tako imenovani brisalni u\u010dinek, kar omogo\u010da kratko zavorno pot, posebni bla\u017eilniki hrupa v kanalih pa poskrbijo za opazno zmanj\u0161anje kotalnega hrupa. Vse to z namenom, da bi bila vsaka pot, na katero se podate, varna in udobna.',
//       bulletpointsIntern1_approved:
//         'U\u017eivajte v nadpovpre\u010dno veliki kilometra\u017ei, ki jo omogo\u010da na\u0161a povsem nova gumena zmes YellowChili.',
//       bulletpointsIntern2_approved:
//         'Zanesite se na izjemno robustnost, ki jo nudi na\u0161a karkasa UltraShield.',
//       bulletpointsIntern3_approved:
//         'Do\u017eivite prepri\u010dljive zmogljivosti na mokrem in majhen kotalni hrup.',
//       articles: [
//         {
//           ID: '66190',
//           artNo11Digit: '03123550000',
//           ean: '4019238066142',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'UltraContact',
//           pogSegment1: 'Passenger Summer',
//           pogSegment1Key: 'PSU',
//           pogSegment2: "Pass. SU 17''",
//           pogSegment2Key: 'P10D',
//           tyreTypeKey: 'TL',
//           season: 'Summer',
//           seasonKey: 'SU',
//           FUEL_EFFI_BR: 'B',
//           WET_GRIP_BR: 'A',
//           ROLL_NOISE_BR: '69',
//           NOISE_UNIT_BR: 'dB',
//           NOISE_LEV_BR: '1',
//           TIRE_CLASS_BR: 'C2',
//           REG_REF_BR: '544/2012',
//           FUEL_EFFI_E2: 'B',
//           WET_GRIP_E2: 'A',
//           ROLL_NOISE_E2: '69',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           EPREL_REG_E2: '710187',
//           REG_REF_E2: '2020/740',
//           TREADWEAR: '460',
//           TRACTION: 'A',
//           TEMPERATURE: 'A',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '89V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.0',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.47',
//           maxSpeed: '240',
//           statusTog: '2',
//           modificationDate: '05/19/22',
//           seasonAllSeason3PMSF: 'Summer',
//           seasonAllWeather: 'Summer',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'UltraContact',
//           ppmStatus: '2',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03123550000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '21/03/2022',
//         },
//         {
//           ID: '66191',
//           artNo11Digit: '03123560000',
//           ean: '4019238066159',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'UltraContact',
//           pogSegment1: 'Passenger Summer',
//           pogSegment1Key: 'PSU',
//           pogSegment2: "Pass. SU 17''",
//           pogSegment2Key: 'P10D',
//           tyreTypeKey: 'TL',
//           season: 'Summer',
//           seasonKey: 'SU',
//           FUEL_EFFI_BR: 'B',
//           WET_GRIP_BR: 'A',
//           ROLL_NOISE_BR: '69',
//           NOISE_UNIT_BR: 'dB',
//           NOISE_LEV_BR: '1',
//           TIRE_CLASS_BR: 'C2',
//           REG_REF_BR: '544/2012',
//           FUEL_EFFI_E2: 'B',
//           WET_GRIP_E2: 'A',
//           ROLL_NOISE_E2: '69',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'A',
//           TIRE_CLASS_E2: 'C1',
//           EPREL_REG_E2: '710185',
//           REG_REF_E2: '2020/740',
//           TREADWEAR: '460',
//           TRACTION: 'A',
//           TEMPERATURE: 'A',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           loadSpeedIndex: '93W',
//           speed1: 'W',
//           tyreType: 'TUBELESS',
//           treadDepth: '7.0',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '8.47',
//           maxSpeed: '270',
//           statusTog: '2',
//           modificationDate: '05/19/22',
//           loadCapacityKey: 'XL',
//           seasonAllSeason3PMSF: 'Summer',
//           seasonAllWeather: 'Summer',
//           ev_compatible: 'yes',
//           zmi_mkt_text_long: 'UltraContact',
//           ppmStatus: '2',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl_SL/03123560000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '21/03/2022',
//         },
//       ],
//     },
//     {
//       ID: '37330',
//       mbrand_text: 'CONTINENTAL',
//       pogDivision: 'PLT',
//       product_group: 'Passenger and Light Truck Tire',
//       pogProductGroupSegment: 'Passenger All Season',
//       zmi_mkt_text_long: 'ContiContact TS 815',
//       ev_compatible: 'no',
//       activate: true,
//       exportName_approved: 'ContiContact TS 815',
//       articles: [
//         {
//           ID: '38583',
//           artNo11Digit: '03573120000',
//           ean: '4019238697261',
//           mbrand_text: 'CONTINENTAL',
//           productlinename: 'ContiContact TS 815',
//           pogSegment1: 'Passenger All Season',
//           pogSegment1Key: 'PAS',
//           pogSegment2: "Pass. AS 17''",
//           pogSegment2Key: 'P15D',
//           tyreTypeKey: 'TL',
//           season: 'All Season',
//           seasonKey: 'AS',
//           FUEL_EFFI_E2: 'C',
//           WET_GRIP_E2: 'C',
//           ROLL_NOISE_E2: '71',
//           NOISE_UNIT_E2: 'dB',
//           NOISE_LEV_E2: 'B',
//           TIRE_CLASS_E2: 'C1',
//           E2_3PMSF: 'X',
//           EPREL_REG_E2: '479497',
//           REG_REF_E2: '2020/740',
//           oeManufacture: 'VW',
//           widthMM: '205',
//           widthInch: '8.07',
//           aspectRatio: '50',
//           sizeDesignation: '205/50 R 17',
//           sealant: 'X',
//           loadSpeedIndex: '93V',
//           speed1: 'V',
//           tyreType: 'TUBELESS',
//           treadDepth: '8.3',
//           plyRating: '000',
//           tkph: '0',
//           rimDiameterInch: '17',
//           rimDiameterMm: '431.8',
//           tireWeight: '9.976',
//           maxSpeed: '240',
//           statusTog: '2',
//           modificationDate: '09/20/19',
//           loadCapacityKey: 'XL',
//           mSMark: 'X',
//           extMobilitySysKey: 'C_S',
//           seasonAllSeason3PMSF: 'All Season',
//           seasonAllWeather: 'All Weather',
//           ev_compatible: 'no',
//           zmi_mkt_text_long: 'ContiContact TS 815',
//           ppmStatus: '2',
//           microsite_eulabel_link:
//             'https://www.contimediacenter.com/eulabel/sl/03573120000/',
//           pogSegment1MappingDTacs: 'passenger_car',
//           ppmProdSince: '01/04/2015',
//         },
//       ],
//       microsite_eulabel_link:
//         'https://www.contimediacenter.com/eulabel/sl/03573120000/',
//       pogSegment1MappingDTacs: 'passenger_car',
//       ppmProdSince: '01/04/2015',
//       ppmStatus: '2',
//     },
//   ],
//   num_products: 10,
//   isMixedFitment: false,
// };
