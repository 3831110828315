import React from 'react';
import PropTypes from 'prop-types';
import {Slide} from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// components
import Container from './components/Container';
import Image from './components/Image';
import ImagesContainer from './components/ImagesContainer';
import LoginContainer from './components/LoginContainer';
import LoginButton from './components/LoginButton';
import Error from './components/Error';
import SlideImage from './components/SlideImage';

// images
import porscheLogo from '../../../assets/images/porsche-logo-white.png';
import img1 from '../../../assets/images/Conti-APP_banner-1.jpg';
import img2 from '../../../assets/images/Conti-APP_banner-2.jpg';
import img3 from '../../../assets/images/Conti-APP_banner-3.jpg';
import img4 from '../../../assets/images/Conti-APP_banner-4.jpg';
import img5 from '../../../assets/images/Conti-APP_banner-5.jpg';

const SignIn = ({error, onSignIn}) => {
  const images = [img1, img2, img3, img4, img5];
  return (
    <Container>
      <ImagesContainer className="slide-container">
        <Slide
          autoplay
          arrows={false}
          duration={3000}
          canSwipe={false}
          pauseOnHover={false}
        >
          {images.map((image, index) => (
            <ImagesContainer key={index}>
              <SlideImage src={image} />
            </ImagesContainer>
          ))}
        </Slide>
      </ImagesContainer>
      <LoginContainer>
        <Image src={porscheLogo} />
        {error && (
          <Error>
            Napaka pri prijavi.
            <br />
            Poskusite ponovno.
          </Error>
        )}
        <LoginButton onClick={onSignIn}>Prijava</LoginButton>
      </LoginContainer>
    </Container>
  );
};

SignIn.propTypes = {
  error: PropTypes.bool,
  onSignIn: PropTypes.func,
};

export default SignIn;
