import React from 'react';
import PropTypes from 'prop-types';

// style components
import Container from './style/Container.style';
import Select from './../../../components/Select/Select';

// constants
import statuses from '../../constants/statuses.order.const';

const StatusSelect = ({orderId, status, onStatusChange}) => {
  return (
    <Container>
      <Select
        showBorder={false}
        value={status}
        unknown="Napaka"
        onChange={(value) => {
          onStatusChange(orderId, value);
        }}
        options={statuses}
      >
        Status
      </Select>
    </Container>
  );
};

StatusSelect.propTypes = {
  orderId: PropTypes.string,
  status: PropTypes.number,
  onStatusChange: PropTypes.func,
};

export default StatusSelect;
