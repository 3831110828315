import {Component} from 'react';
import PropTypes from 'prop-types';

// containers
import Page from '../../layout/containers/Page/Page';
import HomeContainer from '../../containers/HomeContainer/HomeContainer';

// Constants
import page from './page';

class HomePage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    // this.props.history.replace(categoriesRoute());
  }

  render() {
    return (
      <Page page={page}>
        <HomeContainer />
      </Page>
    );
  }
}

export default HomePage;
