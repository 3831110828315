import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';

// Components
import TextArea from 'react-textarea-autosize';
import Container from './components/Container';
import Border from './components/Border';
import Label from './components/Label';
import ErrorLabel from './components/ErrorLabel';

// Styles
import textareaStyle from './styles/textarea.style';

class Textarea extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    resize: PropTypes.oneOf(['none', 'both', 'horizontal', 'vertical']),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    minRows: PropTypes.number,
    maxRows: PropTypes.number,
  };

  static defaultProps = {
    disabled: false,
    error: null,
    resize: 'none',
    placeholder: null,
    required: false,
    minRows: 1,
    maxRows: 4,
  };

  state = {
    focused: false,
  };

  id = v4();
  input = null;

  inputRef = (input) => {
    this.input = input;
  };

  onChange = (e) => {
    if (this.props.disabled) return;
    this.props.onChange(e.target.value);
  };

  onFocus = () => this.setState({focused: true});

  onBlur = () => this.setState({focused: false});

  blur = (e) => {
    e.preventDefault();
    setTimeout(() => {
      if (this.input) this.input.blur();
      this.onBlur();
    }, 100);
    return false;
  };

  onKeyDown = (e) => {};

  render() {
    const {
      children,
      required,
      value,
      error,
      disabled,
      onChange,
      placeholder,
      resize,
      minRows,
      maxRows,
      ...props
    } = this.props;
    const {focused} = this.state;
    return (
      <Container>
        <Label error={!!error} top={!!value || focused}>
          {children}
          {required && ` *`}
        </Label>
        <TextArea
          ref={this.inputRef}
          autoComplete={this.id}
          value={value === undefined || value === null ? '' : value}
          disabled={disabled}
          placeholder={
            focused && placeholder !== null && !!`${placeholder}`.trim().length
              ? placeholder
              : null
          }
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.blur}
          minRows={minRows}
          maxRows={maxRows}
          style={textareaStyle({resize})}
          {...props}
        />
        <Border focused={focused} error={!!error} hasValue={!!value} />
        {!!error && <ErrorLabel>{error}</ErrorLabel>}
      </Container>
    );
  }
}

export default Textarea;
