// Action constants
import {SET_TOKEN} from './constants';

const DEFAULT_STATE = {
  initialized: false,
  token: null,
  user: null,
};

const reducer = (state = DEFAULT_STATE, {type, data}) => {
  switch (type) {
    case SET_TOKEN: {
      return {
        ...state,
        initialized: true,
        ...data,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
