import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Container from './components/Container';
import SeasonButton from '../SeasonButton/SeasonButton';

class SeasonButtons extends Component {
  static propTypes = {
    seasonButtons: PropTypes.array,
    onClick: PropTypes.func,
  };

  render() {
    const {seasonButtons, onClick} = this.props;
    return (
      <Container>
        {seasonButtons.map((tyreBtn) => (
          <SeasonButton
            key={tyreBtn.id}
            id={tyreBtn.id}
            text={tyreBtn.text}
            icon={tyreBtn.icon}
            selected={tyreBtn.selected}
            onClick={() => {
              onClick(tyreBtn.id);
            }}
          />
        ))}
      </Container>
    );
  }
}

export default SeasonButtons;
