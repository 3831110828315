import doc1 from '../../assets/documents/garancijski-list.pdf';

const documents = [
  {
    name: 'Garancijski list',
    url: doc1,
    type: 'pdf',
  },
];

export default documents;
