import styled from "styled-components";

import greyTile from "../../../assets/images/grey-tile.jpeg";

const Container = styled.div`
  min-height: 200px;
  background-image: url(${greyTile});
  padding: 60px 10%;
  background-size: cover;
`;

export default Container;
