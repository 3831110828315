import styled from 'styled-components';

const ClearFilters = styled.div`
  margin-top: 20px;
  &:hover {
    text-decoration: underline;
  }
  ${'span'} {
    cursor: pointer;
    font-size: 12px;
  }
`;

export default ClearFilters;
