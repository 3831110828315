import styled from 'styled-components';

import greyTile from '../../../assets/images/grey-tile.jpeg';

const Container = styled.div`
  min-height: 70vh;
  padding: 20px 10% 20px 10%;
  background-image: url(${greyTile});
`;

export default Container;
