import styled from 'styled-components';

const Value = styled.div`
  width: 100%;
  display: block;
  padding-top: ${({square}) => (square ? '26px' : '0px')};
  padding-right: 30px;
  padding-left: 14px;
  font-size: 16px;
  height: ${({square}) => (square ? '100%' : '19px')};
  margin-bottom: 6px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  opacity: ${({square, disabled}) => (disabled && !square ? 0.8 : 1)};
`;

export default Value;
