import {Component} from 'react';
import GuaranteeDocuments from '../../components/GuaranteeDocuments/GuaranteeDocuments';

class GuaranteeContainer extends Component {
  render() {
    return <GuaranteeDocuments></GuaranteeDocuments>;
  }
}

export default GuaranteeContainer;
