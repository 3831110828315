import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';

const DisplayCartData = ({cartItemsCount, cartTotalPrice}) => (
  <Container>
    <div>Število izdelkov v košarici: {cartItemsCount}</div>
    <div>Celotna vrednost: {cartTotalPrice} €</div>
  </Container>
);

DisplayCartData.propTypes = {
  cartItemsCount: PropTypes.number,
  cartTotalPrice: PropTypes.any,
};

export default DisplayCartData;
