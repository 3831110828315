import styled from 'styled-components';

const Icon = styled.img`
  margin-top: ${({marginTop}) => `${marginTop}px`};
  width: 50px;
  height: 50px;
  margin-left: 5px;
`;

export default Icon;
