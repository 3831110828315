import styled from 'styled-components';
import {black} from '../../../style/colors';

const NoiseLevel = styled.span`
  position: absolute;
  left: 18px;
  top: 15px;
  font-size: 10px;
  font-weight: 600;
  color: ${black};
`;

export default NoiseLevel;
