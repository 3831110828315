const createMail = (tyre) => {
  const mailTo = 'cod.adria@conti.de';
  const cc =
    '?cc=vid.pucko@conti.de;andrej.supe@porsche.si;katja.crncec@conti.de;manuela.toplak@conti.de';
  const subject = 'Povpraševanje COPS-online';
  const body = `Pozdravljeni, %0D%0A %0D%0A prosim za informacijo o dobavljivosti pnevmatik s kataloško številko ${tyre.catalogNumber}. %0D%0A %0D%0A (${tyre.design} - ${tyre.name})`;

  window.location.href = `mailto:${mailTo}${cc}&subject=${subject}&body=${body}`;
};

export default createMail;
