import styled from 'styled-components';

const Input = styled.input`
  display: none;
  opacity: 0px;
  visibility: hidden;
  pointer-events: none;
`;

export default Input;
