import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Base64
import {decode as base64Decode} from 'js-base64';

// Components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// Events
import forceAuthorizationEvent from '../../events/forceAuthorization.event.auth';

// Loading
import loadingRoute from '../../../layout/pages/LoadingPage/route';

// PerformAuth
import performAuthRoute from '../../pages/PerformAuthPage/route';

// Router
import {withRouter} from 'react-router';

class AuthCallbackContainer extends Component {
  static propTypes = {
    code: PropTypes.string,
    state: PropTypes.string,
    history: PropTypes.object,
    window: PropTypes.object,
  };

  static defaultProps = {
    window,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {code, state, history, window} = this.props;

    try {
      const {code_verifier, redirectTo} = JSON.parse(base64Decode(state));
      const path = performAuthRoute({code, code_verifier});
      if (!redirectTo) return history.replace(path);
      window.location.href = `${redirectTo}${path}`;
    } catch (error) {
      this.cancelAuthorization();
    }
  };

  cancelAuthorization = () => {
    const {history} = this.props;
    history.replace(loadingRoute());
    forceAuthorizationEvent.publish();
  };

  render() {
    return <FullScreenLoader />;
  }
}

export default withRouter(AuthCallbackContainer);
