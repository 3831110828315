import styled from 'styled-components';

// Colors
import {black} from '../../../style/colors';

const Input = styled.input`
  width: 100%;
  display: block;
  padding: ${({rounded}) => (rounded ? '0px 14px' : '0 10px')};
  outline: none;
  border: none;
  color: ${black};
  background: none;
  font-size: 18px;
  margin-bottom: 0px;
  text-align: ${({center}) => (center ? 'center' : '')};

  &:disabled {
    color: ${black};
    opacity: 0.8;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    background: none;
    color: ${black};
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export default Input;
