import styled from 'styled-components';
import {black} from '../../../style/colors';

const SubName = styled.div`
  text-align: center;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 400;
  color: ${black};
`;

export default SubName;
