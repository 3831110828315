import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.constant.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const getUserInfoApi = ({token = null} = {}) =>
  f3tch('/auth/user')
    .profile(profile({authorize: false, url: API_URL()}))
    .headers({Authorization: token})
    .get();

export default getUserInfoApi;
