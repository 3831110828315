import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
    box-sizing: border-box;
  }
  html, body {
    height: 100%;
    margin: 0;
    font-family: 'Roboto';
    letter-spacing: 0.2px;
    padding: 0;
    /* padding: 0 20px; */
  }
`;

export default GlobalStyle;
