// Token
import authStore from '../../auth/lib/token.lib.auth';

const jsonHeader = ({authorize = true} = {}) => {
  const token = authStore.get();
  const h = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (!!token && authorize) h.authorization = token;
  return h;
};

export default jsonHeader;
