const statuses = [
  {
    value: 0,
    label: 'Zaključeno',
  },
  {
    value: -1,
    label: 'Potrjeno',
  },
  {
    value: -2,
    label: 'V pripravi',
  },
  {
    value: -3,
    label: 'Preklicano',
  },
];

export default statuses;
