import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: ${({dynamicError, error}) =>
    dynamicError && !error ? '0px' : '20px'};
`;

export default Container;
