import styled from "styled-components";

import { black, darkishGrey, darkGrey, white } from "../../../style/colors";

const Circle = styled.div`
  margin: auto;
  display: block;
  border-radius: 60px;
  text-align: center;
  background-color: ${({ selected }) => (selected ? black : darkishGrey)};
  color: ${black};
  border: none;
  cursor: pointer;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 25px;
  color: ${white};
  &:hover {
    transition: all 0.5s ease-in-out 0s;
    background-color: ${({ selected }) => (selected ? black : darkGrey)};
    color: ${white};
  }
`;

export default Circle;
