import styled from 'styled-components';
import {white} from '../../../style/colors';

const Container = styled.div`
  margin-right: 10px;
  height: 100%;
  position: relative;
  padding-top: 18px;
  border: ${({rounded}) => (rounded ? 'none' : '1px solid')};
  /* border-radius: ${({rounded}) => (rounded ? '22px' : 'none')}; */
  background-color: ${white};
  box-shadow: ${({rounded}) =>
    rounded ? '0 1px 0 0 rgb(0 0 0 / 40%);' : 'none'};
  width: ${({width}) => (width ? width : '100%')};
  min-width: 50px;
  min-height: 50px;
  overflow-x: auto;
`;

export default Container;
