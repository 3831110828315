import styled from 'styled-components';

// const Logo = styled.img`
//   height: 150px;
// `;

const Logo = styled.div`
  margin-top: 30px;
  display: block;
  font-weight: 600;
  font-size: 40px;
  line-height: 1.35em;
  margin-left: 20px;
  letter-spacing: 0;
`;

export default Logo;
