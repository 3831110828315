import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Icon from './components/Icon';
import Square from './components/Square';
import NoiseContainer from './components/NoiseContainer';
import NoiseLevel from './components/NoiseLevel';
import Name from './components/Name';
import Label from './components/Label';

// icons
import euFuelIcn from './icons/eu-fuel-efficency.svg';
import euNoiseB from './icons/eu-noise-levelB.svg';
import euWetGrip from './icons/eu-wet-grip.svg';

// libs
import {
  rollingResistanceColor,
  wetPerformanceColor,
} from '../../lib/tyrePropertyColor';
import Row from '../Grid/Row/Row';
import Column from '../Grid/Column/Column';
import SubName from './components/SubName';

const TyrePropertiesWithIcons = ({
  name,
  design,
  noiseLevel,
  wetPerformance,
  rollingResistance,
  labelUrl,
}) => (
  <>
    <Name>{name}</Name>
    <SubName>{design}</SubName>
    <Row>
      <Column size={3 / 12}>
        <Container>
          <Icon marginTop="4" src={euFuelIcn} />
          <Square
            className="mdi mdi-label"
            color={rollingResistanceColor(rollingResistance)}
          >
            <span>{rollingResistance}</span>
          </Square>
        </Container>
      </Column>
      <Column size={3 / 12}>
        <Container>
          <Icon marginTop="4" src={euWetGrip} />
          <Square
            className="mdi mdi-label"
            color={wetPerformanceColor(wetPerformance)}
          >
            <span> {wetPerformance}</span>
          </Square>
        </Container>
      </Column>
      <Column size={3 / 12}>
        <Container>
          <NoiseContainer>
            <Icon marginTop="0" after={true} src={euNoiseB} />
            <NoiseLevel>{noiseLevel}</NoiseLevel>
          </NoiseContainer>
        </Container>
      </Column>
      <Column size={3 / 12}>
        <Label onClick={() => openLabelUrl(labelUrl)}>
          <i className="mdi mdi-file-pdf-outline" />
        </Label>
      </Column>
    </Row>
  </>
);

const openLabelUrl = (labelUrl) => {
  window.open(labelUrl, '_blank');
};

TyrePropertiesWithIcons.propTypes = {
  name: PropTypes.string,
  design: PropTypes.string,
  noiseLevel: PropTypes.number,
  wetPerformance: PropTypes.string,
  rollingResistance: PropTypes.string,
  labelUrl: PropTypes.string,
};

export default TyrePropertiesWithIcons;
