import styled from 'styled-components';

// Colors
import {darkGrey, orange} from '../../../style/colors';

const Icon = styled.i`
  font-size: 24px;
  pointer-events: none;
  color: ${({visible}) => (visible ? orange : darkGrey)};
  display: block;
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
`;

export default Icon;
