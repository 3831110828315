import {Component} from 'react';
import PropTypes from 'prop-types';

// components
import OrdersTable from '../../order/components/OrdersTable/OrdersTable';
import Toggle from '../../components/Toggle/Toggle';

// style
import Container from './style/Container.style';
import {v4} from 'uuid';
import listOrdersApi from '../../order/api/list.api.order';
import OrdersTableFilters from '../../order/components/OrdersTableFilters/OrdersTableFilters.component';
import updateOrderApi from '../../order/api/patch.api.order';
import statuses from '../../order/constants/statuses.order.const';
import InfiniteList from '../../layout/components/InfiniteList/InfiniteList';
import ToggleContainer from './style/ToggleContainer.style';

// redux
import {connect} from 'react-redux';

// events
import subscriptionHoc from '../../event/hoc/subscription.hoc.event';

class OrdersContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  state = {
    loading: false,
    sort: {key: 'createdAt', direction: 'desc'},
    orders: [],
    options: [...statuses],
    more: false,
    multiValue: [],
    showAllOrders: false,
  };

  API_ID = null;
  PER_PAGE = 25;

  componentDidMount() {
    this.mounted = true;
    this.getOrders({refresh: true, page: 1});
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getOrders = async ({
    sort = this.state.sort,
    page = this.state.page,
    refresh = false,
  } = {}) => {
    const API_ID = v4();
    this.API_ID = API_ID;

    const q = {page, sort};
    const state = {
      loading: true,
      sort,
      ...q,
    };
    if (refresh) state.orders = [];
    this.setState(state);
    try {
      const results = await listOrdersApi(this.query(q));
      if (!this.mounted || API_ID !== this.API_ID) return;
      this.setState({
        orders: [...(refresh ? [] : this.state.orders), ...results],
        loading: false,
        more: results.length >= this.PER_PAGE,
      });
    } catch (error) {
      console.log(error);
      if (!this.mounted || API_ID !== this.API_ID) return;
      this.setState({loading: false, orders: [], page: 1, more: false});
    }
  };

  query = ({
    sort = this.state.sort,
    page = this.state.page,
    showAllOrders = this.state.showAllOrders,
    multiValue = this.state.multiValue,
  } = {}) => {
    const userId = this.props.user._id;
    const query = {
      limit: this.PER_PAGE,
      offset: (page - 1) * this.PER_PAGE,
    };
    if (!showAllOrders) {
      query.createdBy = userId;
    }

    if (!!sort)
      query.order = `${sort.direction === 'asc' ? '' : '-'}${sort.key}`;

    if (multiValue && multiValue.length) {
      query['status__isin'] = multiValue.join(',');
    }

    query.populate = `orderRows`;
    if (showAllOrders) query.populate += ',createdBy,department';
    return query;
  };

  onSort = (sort) => this.getOrders({sort, refresh: true, page: 1});

  onRowClicked = (id, open) => {};

  onStatusChange = async (orderId, status) => {
    await updateOrderApi(orderId, {status});
    this.setState({
      orders: this.state.orders.map((order) =>
        order._id === orderId ? {...order, status} : order
      ),
    });
  };

  onMultiSelect = (data) => {
    this.setState(
      {
        multiValue: data,
      },
      () => {
        this.getOrders({refresh: true, page: 1});
      }
    );
  };

  loadMore = () => {
    const {loading, page, more} = this.state;
    if (loading || !more) return;
    this.getOrders({page: page + 1});
  };

  onAllOrdersToggle = () => {
    console.log('test');
    this.setState(
      {
        showAllOrders: !this.state.showAllOrders,
      },
      () => {
        this.getOrders({refresh: true, page: 1});
      }
    );
  };

  render() {
    const {sort, orders, options, multiValue, loading, more, showAllOrders} =
      this.state;
    return (
      <Container>
        <OrdersTableFilters
          onChange={this.onMultiSelect}
          options={options}
          multiValue={multiValue}
        />
        {this.props?.user?.isAdmin && (
          <ToggleContainer>
            <span>Vsa naročila</span>
            <Toggle
              value={showAllOrders}
              onChange={this.onAllOrdersToggle}
              checkboxKey="allOrdersToggle"
            />
          </ToggleContainer>
        )}
        <InfiniteList onLoad={this.loadMore} hasMore={more}>
          {loading && !orders.length ? (
            ''
          ) : (
            <OrdersTable
              sort={sort}
              rows={orders}
              onSort={this.onSort}
              rowClicked={this.onRowClicked}
              onStatusChange={this.onStatusChange}
              showAdminRows={
                (this.props?.user?.isAdmin && showAllOrders) || false
              }
            />
          )}
        </InfiniteList>
      </Container>
    );
  }
}

export default subscriptionHoc(
  connect((state) => ({...state.auth}))(OrdersContainer)
);
