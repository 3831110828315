import styled from "styled-components";
import PropTypes from "prop-types";

// Stlyes
import { white, red, orange, blue, green } from "../../../style/colors";

const types = {
  error: red,
  warning: orange,
  info: blue,
  success: green,
};

const Container = styled.div`
  width: 100%;
  color: ${white};
  background: ${({ type }) => types[type]};
  box-shadow: ${({ flat }) =>
    flat ? "none" : "0 2px 20px 0 rgba(0, 0, 0, 0.1)"};
  border-radius: ${({ boxStyle }) => {
    switch (boxStyle) {
      case "round":
        return "30px";
      case "square":
        return "10px";
      default:
        return "0px";
    }
  }};
  padding: 10px;
  display: flex;
  align-items: center;
  user-select: none;
`;

Container.propTypes = {
  type: PropTypes.string,
};

export default Container;
