import styled, {css} from 'styled-components';

// Colors
import {red} from '../../../style/colors';

const ErrorLabel = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${red};
  padding: 0 14px;
  ${({dynamicError}) =>
    !dynamicError
      ? css`
          bottom: 0px;
          left: 0px;
          position: absolute;
        `
      : ''};
`;

export default ErrorLabel;
