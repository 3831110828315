import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Api
import getTokensApi from '../../api/getTokens.api.auth';
import getUserInfoApi from '../../api/getUserInfo.api.auth';

// Actions
import {signIn as signInAct} from '../../redux/actions';

// Components
import FullScreenLoader from '../../../layout/components/FullScreenLoader/FullScreenLoader';

// Events
import forceAuthorizationEvent from '../../events/forceAuthorization.event.auth';

// Home
import homeRoute from '../../../pages/HomePage/route';

// Loading
import loadingRoute from '../../../layout/pages/LoadingPage/route';

// Redux
import {connect} from 'react-redux';

// Router
import {withRouter} from 'react-router';

class PerformAuthContainer extends Component {
  static propTypes = {
    code: PropTypes.string,
    state: PropTypes.string,
    history: PropTypes.object,
    dispatch: PropTypes.func,
    window: PropTypes.object,
  };

  static defaultProps = {
    window,
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    const {code, code_verifier, history, dispatch} = this.props;

    try {
      const {token} = await getTokensApi({
        code,
        code_verifier,
      });
      const user = await getUserInfoApi({token});
      dispatch(signInAct({token, user}));
      history.replace(homeRoute());
    } catch (error) {
      this.cancelAuthorization();
    }
  };

  cancelAuthorization = () => {
    const {history} = this.props;
    history.replace(loadingRoute());
    forceAuthorizationEvent.publish();
  };

  render() {
    return <FullScreenLoader />;
  }
}

export default withRouter(connect()(PerformAuthContainer));
