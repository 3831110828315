import styled from 'styled-components';

const Label = styled.div`
  width: 100%;
  flex: 1;
  margin-left: 8px;
  font-size: ${({large}) => (large ? '14px' : '12px')};
  line-height: ${({large}) => (large ? '32px' : '20px')};
  pointer-events: none;
`;

export default Label;
