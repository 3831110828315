import React from 'react';
import PropTypes from 'prop-types';

// Components
import InfiniteScroll from 'react-infinite-scroller';
import Loader from './components/Loader';

const InfiniteList = ({children, hasMore, onLoad, ...props}) => (
  <InfiniteScroll
    pageStart={0}
    loadMore={onLoad}
    hasMore={hasMore}
    loader={<Loader key={0}>Loading</Loader>}
    initialLoad
    useWindow
    {...props}
  >
    {children}
  </InfiniteScroll>
);

InfiniteList.propTypes = {
  children: PropTypes.node,
  hasMore: PropTypes.bool,
  onLoad: PropTypes.func,
};

export default InfiniteList;
