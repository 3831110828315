import styled from "styled-components";

const Container = styled.div`
  margin-top: 5px;
  min-height: 570px;
  background: linear-gradient(
    to bottom,
    #000 0,
    #000 20%,
    #363636 40%,
    #000 80%,
    #000 100%
  );
  padding-bottom: 20px;
`;

export default Container;
