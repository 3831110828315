import React, {useState} from 'react';
import PropTypes from 'prop-types';

// components
import Container from './components/Container';
import ButtonContainer from './components/ButtonContainer';
import SquareButton from './components/SquareButton';
import Text from '../Text/Text';
import {Row, Column} from '../Grid/Grid';
import VerticalSeparator from './components/VerticalSeparator';
import TyreButtonsContainer from './components/TyreButtonsContainer';
import SeasonButton from '../SeasonButton/SeasonButton';
import SearchByDimensions from './Search/SearchByDimensions/SearchByDimensions';
import SearchByModel from './Search/SearchByModel/SearchByModel';
import SearchByChassis from './Search/SearchByChassis/SearchByChassis';

const QuickSearch = ({
  seasonButtons,
  onQuickSearchButtonClick,
  onTyreClick,
  search,
  onSearch,
  onDimensionSearch,
  brands,
  onBrandChange,
  selectedBrand,
  onChassisSearch,
  onModelSearch,
}) => {
  const [selected, setSelected] = useState('catalogNumber');

  return (
    <Container>
      <Text fontSize="24px" weight="bold">
        Hitro iskanje
      </Text>
      <ButtonContainer>
        <SquareButton
          selected={selected === 'catalogNumber'}
          onClick={() => {
            setSelected('catalogNumber');
            onQuickSearchButtonClick('catalogNumber');
          }}
        >
          Po kataloški/dimenziji
        </SquareButton>

        <SquareButton
          selected={selected === 'brand'}
          onClick={() => {
            setSelected('brand');
          }}
        >
          Po modelu avta
        </SquareButton>
        <SquareButton
          selected={selected === 'chassis'}
          onClick={() => {
            setSelected('chassis');
          }}
        >
          Po številki šasije
        </SquareButton>
      </ButtonContainer>
      <Row>
        <Column size={9 / 12}>
          {selected === 'catalogNumber' && (
            <SearchByDimensions
              search={search}
              onSearch={onSearch}
              onDimensionSearch={onDimensionSearch}
              brands={brands}
              onBrandChange={onBrandChange}
              selectedBrand={selectedBrand}
            />
          )}
          {selected === 'brand' && (
            <SearchByModel onSearchByModel={onModelSearch} />
          )}
          {selected === 'chassis' && (
            <SearchByChassis onChassisSearch={onChassisSearch} />
          )}
        </Column>
        <Column size={0.5 / 12}>
          <VerticalSeparator />
        </Column>
        <Column size={1.5 / 12}>
          <TyreButtonsContainer>
            {seasonButtons.map((tyreBtn) => (
              <SeasonButton
                key={tyreBtn.id}
                id={tyreBtn.id}
                text={tyreBtn.text}
                icon={tyreBtn.icon}
                selected={tyreBtn.selected}
                onClick={() => {
                  onTyreClick(tyreBtn.id);
                }}
              />
            ))}
          </TyreButtonsContainer>
        </Column>
      </Row>
    </Container>
  );
};

QuickSearch.propTypes = {
  search: PropTypes.string,
  seasonButtons: PropTypes.array,
  brands: PropTypes.array,
  selectedBrand: PropTypes.string,
  onSearch: PropTypes.func,
  onQuickSearchButtonClick: PropTypes.func,
  onDimensionSearch: PropTypes.func,
  onTyreClick: PropTypes.func,
  onBrandChange: PropTypes.func,
  onChassisSearch: PropTypes.func,
  onModelSearch: PropTypes.func,
};

export default QuickSearch;
