import styled from 'styled-components';
import {green, red, white} from '../../../style/colors';

const Icon = styled.i`
  margin-top: 10px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding-top: 3px;
  color: ${white};
  background-color: ${({valid}) => (valid ? green : red)};
`;

export default Icon;
