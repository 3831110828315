import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {v4} from 'uuid';

import Container from './components/Container';
import Label from './components/Label';
import InputField from './components/Input';

class Input extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    required: PropTypes.bool,
    value: PropTypes.any,
    type: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    inputRef: PropTypes.func,
    width: PropTypes.string,
    rounded: PropTypes.bool,
    enterPressed: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    disabled: false,
    error: null,
    placeholder: null,
    required: false,
    inputRef: () => null,
    width: '100%',
    rounded: true,
  };

  state = {
    focused: false,
    highlight: null,
  };

  id = v4();
  input = null;

  inputRef = (input) => {
    this.input = input;
    this.props.inputRef(input);
  };

  onChange = (e) => {
    if (this.props.disabled) return;
    this.props.onChange(e.target.value);
    this.setState({highlight: null});
  };

  highlight = (next = true) => {
    // const val = this.props.value;
    // this.props.onChange(next ? val - 1 : val + 1);
  };

  onFocus = () => this.setState({focused: true, highlight: null});

  onBlur = () => this.setState({focused: false, highlight: null});

  blur = (e) => {
    e.preventDefault();
    setTimeout(() => {
      if (this.input) this.input.blur();
      this.onBlur();
    }, 100);
    return false;
  };

  onKeyDown = (e) => {
    const {focused} = this.state;
    const optionsVisible = focused;

    if (!optionsVisible) return;

    switch (e.key) {
      case 'ArrowUp':
        this.highlight(false);
        this.props.onChange(e.target.value);
        break;
      case 'ArrowDown':
        this.highlight(true);
        this.props.onChange(e.target.value);
        break;
      case 'Enter':
        // if (!highlight) return;
        // if (this.input) this.input.blur();
        // this.onBlur();
        // e.preventDefault();
        const enterPressed = this.props.enterPressed;
        if (enterPressed) enterPressed();
        break;
      case 'Escape':
        if (this.input) this.input.blur();
        this.onBlur();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  render() {
    const {
      children,
      required,
      value,
      type,
      error,
      disabled,
      onChange,
      placeholder,
      rounded,
      width,
      ...props
    } = this.props;
    const {focused} = this.state;
    return (
      <Container width={width} rounded={rounded}>
        <Label error={!!error} top={!!value || focused}>
          {children}
          {required && ` *`}
        </Label>
        <InputField
          rounded={rounded}
          ref={this.inputRef}
          type={type}
          autoComplete={this.id}
          value={value === undefined || value === null ? '' : value}
          disabled={disabled}
          placeholder={
            focused && placeholder !== null && !!`${placeholder}`.trim().length
              ? placeholder
              : null
          }
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.blur}
          {...props}
        />
      </Container>
    );
  }
}

export default Input;
