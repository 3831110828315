import React from 'react';
import {Route, Switch, BrowserRouter} from 'react-router-dom';

// pages
import HomePage from '../pages/HomePage/HomePage';
import SearchPage from '../pages/SearchPage/SearchPage';
import CartPage from '../pages/CartPage/CartPage';
import WarrantyPage from '../pages/WarrantyPage/WarrantyPage';
import GuaranteePage from '../pages/GuaranteePage/GuaranteePage';
import StatisticsPage from '../pages/StatisticsPage/StatisticsPage';
import ModelsPage from '../pages/ModelsPage/ModelsPage';
import OrdersPage from '../pages/OrdersPage/OrdersPage';
import DocumentsPage from '../pages/DocumentsPage/DocumentsPage';

// routes
import routeHomePage from '../pages/HomePage/route';
import routeSearchPage from '../pages/SearchPage/route';
import routeCartPage from '../pages/CartPage/route';
import routeWarrantyPage from '../pages/WarrantyPage/route';
import routeGuaranteePage from '../pages/GuaranteePage/route';
import routeStatisticsPage from '../pages/StatisticsPage/route';
import routeModelsPage from '../pages/ModelsPage/route';
import routeOrdersPage from '../pages/OrdersPage/route';
import routeDocumentsPage from '../pages/DocumentsPage/route';

// AuthCallback
import AuthCallbackPage from '../auth/pages/AuthCallbackPage/AuthCallbackPage';
import routeAuthCallback from '../auth/pages/AuthCallbackPage/route';

// PerformAuth
import PerformAuthPage from '../auth/pages/PerformAuthPage/PerformAuthPage';
import routePerformAuth from '../auth/pages/PerformAuthPage/route';

// Loading
import LoadingPage from '../layout/pages/LoadingPage/LoadingPage';
import routeLoading from '../layout/pages/LoadingPage/route';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path={routeHomePage()} component={HomePage} />
      <Route exact path={routeSearchPage()} component={SearchPage} />
      <Route exact path={routeCartPage()} component={CartPage} />
      <Route exact path={routeWarrantyPage()} component={WarrantyPage} />
      <Route exact path={routeModelsPage()} component={ModelsPage} />
      <Route exact path={routeDocumentsPage()} component={DocumentsPage} />
      <Route exact path={routeGuaranteePage()} component={GuaranteePage} />
      <Route exact path={routeStatisticsPage()} component={StatisticsPage} />
      <Route exact path={routeOrdersPage()} component={OrdersPage} />
      <Route exact path={routeAuthCallback()} component={AuthCallbackPage} />
      <Route exact path={routePerformAuth()} component={PerformAuthPage} />
      <Route exact path={routeLoading()} component={LoadingPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
