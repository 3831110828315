export const rollingResistanceColor = (rolllingResistance) => {
  switch (rolllingResistance) {
    case 'A':
      return '#00A651';
    case 'B':
      return '#9ACA3C';
    case 'C':
      return '#FFF200';
    case 'D':
      return '#FDB913';
    case 'E':
      return '#F8B810';
    default:
      return '#000';
  }
};

export const wetPerformanceColor = (wetPerformance) => {
  switch (wetPerformance) {
    case 'A':
      return '#0066B3';
    case 'B':
      return '#0080C6';
    case 'C':
      return '#4BA6DD';
    case 'D':
      return '#0000d8';
    case 'E':
      return '#00008b';
    default:
      return '#000';
  }
};
