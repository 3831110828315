import styled from "styled-components";

const TyreButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
`;

export default TyreButtonsContainer;
