const parseError = (error) =>
  error.type !== undefined &&
  error.fields !== undefined &&
  error.message !== undefined
    ? error
    : {
        type: 'unknown',
        fields: [],
        message: error.message || 'Unknown error',
        error,
      };

export default parseError;
