import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Container from './components/Container';
import Icon from './components/Icon';

class SeasonButton extends Component {
  static propTypes = {
    season: PropTypes.object,
  };

  render() {
    const {season} = this.props;
    return (
      <Container>
        <Icon color={season.color} className={`mdi mdi-${season.icon}`} />
      </Container>
    );
  }
}

export default SeasonButton;
