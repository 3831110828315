import styled from 'styled-components';
import {orange} from '../../../style/colors';

const Title = styled.div`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 25px;
  background-color: ${({current}) => (current ? orange : '')};
`;

export const TitleSeparator = styled.div`
  border-left: 1px solid;
  margin-top: 25px;
  margin-bottom: 20px;
`;

export default Title;
