import {Component} from 'react';
import WarrantyDocuments from '../../components/WarrantyDocuments/WarrantyDocuments';

class WarrantyContainer extends Component {
  render() {
    return <WarrantyDocuments></WarrantyDocuments>;
  }
}

export default WarrantyContainer;
