import styled from 'styled-components';

const Value = styled.div`
  width: 100%;
  display: block;
  padding: 0px 30px 0px 14px;
  font-size: 16px;
  height: 19px;
  margin-bottom: 6px;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'auto')};
  opacity: ${({disabled}) => (disabled ? 0.8 : 1)};
`;

export default Value;
