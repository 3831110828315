import isObject from 'is-object';
import isArray from 'isarray';
import isString from 'is-string';

const serverError = (error) => {
  if (isString(error)) return {fields: [], message: error};
  if (!isObject(error) || Object.keys(error).length === 0)
    return {message: 'Unknown server error'};
  if (!!error.text) return {fields: [], message: error.text};
  if (!!error.message) return {fields: [], message: error.message};
  if (!!error.error) return {fields: [], message: error.error};
  if (!!error.detail) return {fields: [], message: error.detail};
  return {
    fields: Object.keys(error),
    origin: error,
    message: Object.values(error)
      .map((val) => {
        if (isArray(val) && val.length > 0) return val[0];
        if (isObject(val)) return null;
        return val;
      })
      .filter((val) => val !== null && `${val}`.trim().length > 0)
      .join(' '),
  };
};

export default serverError;
