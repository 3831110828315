import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.constant.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const listOrdersApi = (query = {}) =>
  f3tch('/order')
    .profile(profile({url: API_URL()}))
    .query(query)
    .get();

export default listOrdersApi;
