import {Component} from 'react';
import ModelsDocuments from '../../components/ModelsDocuments/ModelsDocuments';

class ModelsContainer extends Component {
  render() {
    return <ModelsDocuments></ModelsDocuments>;
  }
}

export default ModelsContainer;
