const getErrorMessageFromErrorCode = (code) => {
  switch (code) {
    case 303:
      return 'Napaka pri povezavi z EDI sistemom';
    case 902:
      return 'Preveliko število izdelkov.';
    case 906:
      return 'Naročilo s to številko naročila že obstaja.';

    default:
      return `Prišlo je do napake - napaka ${code}`;
  }
};

export default getErrorMessageFromErrorCode;
