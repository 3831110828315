import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container, {
  SearchContainer,
  InputContainer,
  ButtonContainer,
} from './components/Container';
import Input from '../Input/Input';
import Button from './components/Button';
import CheckBox from '../CheckBox/CheckBox';
import CheckBoxContainer from './components/CheckBoxContainer';

const SearchFilters = ({
  value,
  searchOnlyInStock,
  onSearchOnlyInStockClick,
  onInputChange,
  onSearch,
}) => (
  <Container>
    <SearchContainer>
      <InputContainer>
        <Input
          value={value}
          onChange={(value) => onInputChange(value)}
          rounded={false}
          enterPressed={onSearch}
        >
          Iskanje po kataloški številki / dimenziji
        </Input>
      </InputContainer>
      <CheckBoxContainer>
        <CheckBox
          selected={searchOnlyInStock}
          onSelect={onSearchOnlyInStockClick}
        >
          Iskanje samo teh izdelkov, ki so na zalogi.
        </CheckBox>
      </CheckBoxContainer>
    </SearchContainer>
    <ButtonContainer>
      <Button type="submit" onClick={() => onSearch()}>
        <i className="mdi mdi-magnify" /> Poišči
      </Button>
    </ButtonContainer>
  </Container>
);

SearchFilters.propTypes = {
  value: PropTypes.string,
  searchOnlyInStock: PropTypes.bool,
  onSearchOnlyInStockClick: PropTypes.func,
  onInputChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SearchFilters;
