const getResponseData = async (response) => {
  try {
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    // Do nothing
  }

  try {
    const textResponse = await response.text();
    return textResponse;
  } catch (error) {
    // Do nothing
  }

  return null;
};

export default getResponseData;
