import styled from "styled-components";
import { orange } from "../../../style/colors";

const TitleHeader = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  border-top: 4px solid ${orange};
`;

export default TitleHeader;
