import styled from 'styled-components';

const Data = styled.span`
  margin-top: 12px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
`;

export default Data;
