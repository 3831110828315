import styled from "styled-components";

const VerticalSeparator = styled.div`
  border-left: 1px solid;
  margin-top: 20px;
  height: 100%;
  min-height: 100px;
`;

export default VerticalSeparator;
