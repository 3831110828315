import React from 'react';
import PropTypes from 'prop-types';

// style components
import Container from './style/Container.style';

const Status = ({props}) => {
  return <Container color={props.color}>{props.text}</Container>;
};

Status.propTypes = {
  props: PropTypes.object,
};

export default Status;
