import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Circle from './components/Circle';
import Container from './components/Container';
import Text from './components/Text';

class SeasonButton extends Component {
  static propTypes = {
    id: PropTypes.number,
    text: PropTypes.string,
    icon: PropTypes.string,
    selected: PropTypes.bool,
    onClick: PropTypes.func,
  };

  render() {
    const {id, text, icon, selected, onClick} = this.props;
    return (
      <Container>
        <Circle
          onClick={() => onClick(id)}
          className={`mdi mdi-${icon}`}
          selected={selected}
        />
        <Text selected={selected}>{text}</Text>
      </Container>
    );
  }
}

export default SeasonButton;
