import styled from 'styled-components';
import PropTypes from 'prop-types';

const horizontalPadding = (size) => {
  switch (size) {
    case 'small':
      return '5px';
    case 'medium':
      return '10px';
    case 'large':
      return '15px';
    default:
      return '0px';
  }
};

const verticalPadding = (size) => {
  switch (size) {
    case 'small':
      return '10px';
    case 'medium':
      return '20px';
    case 'large':
      return '30px';
    default:
      return '0px';
  }
};

const setPadding = ({padding, size}) => {
  const horizontal = horizontalPadding(size);
  const vertical = verticalPadding(size);
  switch (padding) {
    case 'none':
      return '0px';
    case 'horizontal':
      return `0px ${horizontal}`;
    case 'vertical':
      return `${vertical} 0px`;
    default:
      return `${vertical} ${horizontal}`;
  }
};

const width = ({portions, span}) => `${span * (100 / portions)}%`;

const TableCol = styled.div`
  padding: ${setPadding};
  font-weight: ${({bold}) => (bold ? 500 : 300)};
  text-transform: ${({uppercase}) => (uppercase ? 'uppercase' : 'none')};
  text-align: ${({align}) => align};
  width: ${width};
  max-width: ${width};
  min-width: ${width};
  flex: 1;
  cursor: ${({onClick}) => (onClick ? 'pointer' : 'inherit')};
`;

TableCol.propTypes = {
  padding: PropTypes.oneOf(['all', 'none', 'vertical', 'horizontal']),
  size: PropTypes.string,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  span: PropTypes.number,
  portions: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

TableCol.defaultProps = {
  portions: 1,
  padding: 'all',
  bold: false,
  uppercase: false,
  align: 'left',
  span: 1,
  onClick: null,
};

export default TableCol;
