import styled from 'styled-components';

const OptionsContainer = styled.div`
  width: 100%;
  height: 100%;
  ${({height}) => (!!height ? `max-height: ${height};` : '')};
  flex: 1;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
`;

export default OptionsContainer;
