import styled from 'styled-components';
import {black} from '../../../style/colors';

const Label = styled.div`
  height: 25px;
  width: 25px;
  font-size: 25px;
  margin-top: 15px;
  &:hover {
    color: ${black};
  }
`;

export default Label;
