import styled from 'styled-components';

const Action = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 5px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    text-decoration: underline;
  }
`;

export default Action;
