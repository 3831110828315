// Api
import getUserApi from '../api/getUserInfo.api.auth';

// Action constants
import {SET_TOKEN} from './constants';

// Events
import unauthorizedEvt from '../events/unauthorized.event.auth';

// Lib
import authToken from '../lib/token.lib.auth';

// Storage
import tokenStorage from '../storage/token.storage.auth';

// Store
import store from '../../redux/store';

const checkToken =
  ({token}) =>
  async (dispatch) => {
    try {
      const user = await getUserApi({token});
      dispatch(
        signIn({
          token,
          user,
        })
      );
    } catch (error) {
      dispatch(signOut());
    }
  };

export const init = () => (dispatch) => {
  const token = tokenStorage.get();
  if (!token)
    return dispatch({
      type: SET_TOKEN,
      data: {
        token: null,
        user: null,
      },
    });
  dispatch(checkToken({token}));
};

export const signIn =
  ({token, user}) =>
  (dispatch) => {
    try {
      tokenStorage.add(token);
      authToken.set(token);
      dispatch({
        type: SET_TOKEN,
        data: {
          token,
          user,
        },
      });
    } catch (error) {
      dispatch(signOut());
    }
  };

export const signOut = () => (dispatch) => {
  tokenStorage.remove();
  authToken.remove();
  dispatch({
    type: SET_TOKEN,
    data: {
      token: null,
      user: null,
    },
  });
};

unauthorizedEvt.subscribe(() => {
  store.dispatch(signOut());
});
