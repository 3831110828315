import React from 'react';
import Text from '../../../components/Text/Text';

// Components
import Container from './components/Container';
import Content from './components/Content';
import Label from './components/Label';
import Logo from './components/Logo';

const FullScreenLoader = () => (
  <Container>
    <Content>
      <Logo>
        <Text color="orange">COPS</Text>{' '}
        <Text color="black">Online Portal</Text>
      </Logo>
      <Label>Loading</Label>
    </Content>
  </Container>
);

export default FullScreenLoader;
