import f3tch from 'f3tch';

// Constants
import API_URL from '../../api/constants/url.constant.api';

// Profiles
import profile from '../../api/profiles/json.profile.api';

const updateOrderApi = (orderId, data = {}) =>
  f3tch(`/order/${orderId}`)
    .profile(profile({url: API_URL()}))
    .body(data)
    .patch();

export default updateOrderApi;
