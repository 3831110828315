import { Component } from "react";
import PropTypes from "prop-types";

// containers
import Page from "../../layout/containers/Page/Page";

// Constants
import page from "./page";
import SearchContainer from "../../containers/SearchContainer/SearchContainer";

class HomePage extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    // this.props.history.replace(categoriesRoute());
  }

  render() {
    return (
      <Page page={page}>
        <SearchContainer></SearchContainer>
      </Page>
    );
  }
}

export default HomePage;
