import React, {Component} from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import $ from 'jquery';

// components
import Container from './components/Container';
import Content from './components/Content';
import DropDownContent from './components/DropDown';
import Input from './components/Input';
import InputContainer from './components/InputContainer';
import Option from './components/Option';
import OptionsContainer from './components/OptionsContainer';
import OptionIcon from './components/OptionIcon';
import SearchIcon from './components/SearchIcon';

// lib
import generatePosition from './lib/generatePosition.lib';

class DropDown extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string,
        icon: PropTypes.string,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
      })
    ),
    onOption: PropTypes.func,
    inline: PropTypes.bool,
    searchable: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    top: PropTypes.oneOf(['normal', 'bellow', 'withMargin']),
    syncVisibility: PropTypes.func,
    position: PropTypes.oneOf(['left', 'right']),
    border: PropTypes.string,
    stopCloseOnSelect: PropTypes.bool,
    hoverColor: PropTypes.string,
    hoverBackground: PropTypes.string,
    noPadding: PropTypes.bool,
  };

  static defaultProps = {
    syncVisibility: () => {},
    border: 'darkGrey',
    hoverColor: 'orange',
    hoverBackground: 'transparent',
  };

  componentDidMount() {
    const {position} = this.props;
    if (!!position) return this.setState({position});
    window.addEventListener('resize', this.generatePosition);
    window.addEventListener('deviceorientation', this.generatePosition);
  }

  componentWillUnmount() {
    if (!!this.props.position) return;
    window.removeEventListener('resize', this.generatePosition);
    window.removeEventListener('deviceorientation', this.generatePosition);
  }

  state = {
    search: '',
    visible: false,
    position: 'left',
  };

  handleClickOutside = () => this.hide();

  display = () => {
    this.setState({visible: true, search: ''});
    this.props.syncVisibility(true);
    if (!this.input) return;
    setTimeout(() => $(this.input).trigger('focus'), 110);
  };

  hide = () => {
    this.setState({visible: false});
    this.props.syncVisibility(false);
  };

  onInput = (input) => {
    this.input = input;
  };

  onSearch = ({target: {value: search}}) => {
    if (!this.state.visible) return;
    this.setState({search});
  };

  options = () => {
    const {options} = this.props;
    const {search} = this.state;
    return !search.trim().length
      ? options
      : [...options].filter(({label}) =>
          `${label}`.toLowerCase().includes(search.toLowerCase())
        );
  };

  onChange = (option) => () => {
    const {onOption, stopCloseOnSelect} = this.props;
    onOption(option);
    if (!stopCloseOnSelect) this.hide();
  };

  onContainerDom = (containerDom) => {
    this.containerDom = containerDom;
    this.generatePosition();
  };

  onDropDownDom = (dropDownDom) => {
    this.dropDownDom = dropDownDom;
    this.generatePosition();
  };

  generatePosition = () => {
    if (!!this.props.position || !this.dropDownDom || !this.containerDom)
      return;
    const containerPosition = {
      x: $(this.containerDom).offset().left,
      y: $(this.containerDom).offset().top,
    };
    const containerSize = {
      width: $(this.containerDom).width(),
      height: $(this.containerDom).height(),
    };
    const dropDownSize = {
      width: $(this.dropDownDom).width(),
      height: $(this.dropDownDom).height(),
    };
    const windowDimensions = {
      width: $(window).width(),
      height: $(window).height(),
    };

    const position = generatePosition({
      containerPosition,
      containerSize,
      dropDownSize,
      windowDimensions,
    });
    this.setState({position});
  };

  render() {
    const {
      children,
      inline,
      searchable,
      width,
      top,
      height,
      border,
      hoverColor,
      hoverBackground,
      noPadding,
    } = this.props;
    const {search, visible, position} = this.state;
    return (
      <Container inline={inline} ref={this.onContainerDom}>
        <Content inline={inline} onClick={this.display}>
          {children}
        </Content>
        <DropDownContent
          width={width}
          visible={visible}
          top={top}
          position={position}
          ref={this.onDropDownDom}
          border={border}
          noPadding={noPadding}
        >
          {searchable && (
            <InputContainer>
              <Input
                ref={this.onInput}
                value={search}
                onChange={this.onSearch}
              />
              <SearchIcon className="mdi mdi-magnify" />
            </InputContainer>
          )}
          <OptionsContainer height={height}>
            {this.options().map(({icon, value, label, loading, disabled}) => (
              <Option
                key={value}
                hoverBackground={hoverBackground}
                hoverColor={hoverColor}
                onClick={this.onChange(value)}
                disabled={loading || disabled}
                noPadding={noPadding}
              >
                {(!!icon || loading) && (
                  <OptionIcon
                    className={`mdi mdi-${loading ? 'loading mdi-spin' : icon}`}
                  />
                )}
                {label}
              </Option>
            ))}
          </OptionsContainer>
        </DropDownContent>
      </Container>
    );
  }
}

export default onClickOutside(DropDown);
