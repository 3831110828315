import React from 'react';
import PropTypes from 'prop-types';

// Containers
import PerformAuthContainer from '../../containers/PerformAuthContainer/PerformAuthContainer';

// Query
import qs from 'query-string';

const PerformAuthPage = ({location}) => (
  <PerformAuthContainer {...qs.parse(location.search)} />
);

PerformAuthPage.propTypes = {
  location: PropTypes.object,
};

export default PerformAuthPage;
