import {v4} from 'uuid';

// Store
import alertStore from '../store/store.alert';

class Alertify {
  alerts = [];

  add(content, {action, label}, type) {
    const id = v4();
    this.alerts = [...this.alerts, {id, content, action, label, type}];
    alertStore.pub([...this.alerts]);
    return () => this.remove(id);
  }

  remove(id) {
    this.alerts = [...this.alerts].filter((alert) => alert.id !== id);
    alertStore.pub([...this.alerts]);
  }
}

const alertify = new Alertify();

const alert = (type) => (content, {action = null, label = 'Undo'} = {}) =>
  alertify.add(content, {action, label}, type);

export const success = alert('success');
export const info = alert('info');
export const error = alert('error');
export const warning = alert('warning');
export const remove = (id) => alertify.remove(id);
