import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

// components
import Text from '../../../Text/Text';
import InputsContainer from '../../components/InputsContainer';
import SelectContainer from '../../components/SelectContainer';
import Select from '../../../Select/Select';
import Button from '../../../Button/Button';
import SearchByModelContainer from '../../components/SearchByModelContainer.style';

// redux
import {connect} from 'react-redux';

import PRODUCT_API_URL from '../../../../api/constants/productApi.constant.api';
import {
  getEanFromProducts,
  parseBrands,
  parseFitments,
  parseModels,
  parseVersions,
  parseYears,
} from './api.parse.utils';

// vendor=AUDI&model=A6&year=2018%20-%20&version=35%20TDI%20163&frontSizeDesignation=225%2F55%20R%2018&products=true&articles=true

const apiHeaders = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    // 'X-Api-Key': 'vzuaAG5Zahap0CzysiMcW6UPdDFXQTNm3i6FOtzM',
    'X-Api-Key': 'eln1kj8v4j1GTfrTxIbnr4sbE52nfRSu9DMoltTp',
  },
};

const SearchByModel = ({
  hideSearchButton,
  onSearchByModel,
  onValueChange,
  brand,
  model,
  year,
  version,
  fitment,
}) => {
  const apiUrl = PRODUCT_API_URL();
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [years, setYears] = useState([]);
  const [versions, setVersions] = useState([]);
  const [fitments, setFitments] = useState([]);
  const [newEanNumbers, setNewEanNumbers] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState(brand);
  const [selectedModel, setSelectedModel] = useState(model);
  const [selectedYear, setSelectedYear] = useState(year);
  const [selectedVersion, setSelectedVersion] = useState(version);
  const [selectedFitment, setSelectedFitment] = useState(fitment);

  const fetchData = async (url, signal) => {
    try {
      const response = await fetch(url, {...apiHeaders, signal}); // Pass the signal to fetch
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('Fetch was aborted');
        throw error;
      }
      console.error('Error fetching data:', error);
      throw error;
    }
  };

  const fetchWithTimeout = async (url, initialTimeout, retryTimeout) => {
    // Helper to create a timeout promise
    const timeout = (ms, result) =>
      new Promise((resolve) => setTimeout(() => resolve(result), ms));

    const controller = new AbortController(); // Create an AbortController

    // Race the fetch against the initial timeout
    const response = await Promise.race([
      fetchData(url, controller.signal),
      timeout(initialTimeout, 'timeout'),
    ]);

    // If the initial fetch times out, abort it and then retry
    if (response === 'timeout') {
      controller.abort(); // Abort the fetch
      console.warn('Initial fetch timed out. Retrying...');
      const retryResponse = await Promise.race([
        fetchData(url),
        timeout(retryTimeout, 'timeout'),
      ]);

      if (retryResponse === 'timeout') {
        throw new Error('Retry fetch also timed out.');
      }

      return retryResponse;
    }

    return response;
  };

  useEffect(() => {
    if (selectedBrand && brand === '') {
      setSelectedBrand('');
      setSelectedModel('');
      setSelectedYear('');
      setSelectedVersion('');
      setSelectedFitment('');
      setNewEanNumbers([]);
      if (onValueChange) {
        onValueChange('eanNumbers', []);
      }
    }
    const fetchBrands = async () => {
      try {
        // const response = await fetch(`${apiUrl}req=vendor`, apiHeaders);
        // const jsonResponse = await response.json();
        const jsonResponse = await fetchWithTimeout(
          `${apiUrl}req=vendor`,
          5000,
          2000
        );
        const brands = parseBrands(jsonResponse);
        setBrands(brands);
      } catch (error) {
        console.log(error);
        setBrands([]);
      }
    };

    fetchBrands();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, brand]);

  useEffect(() => {
    const fetchModels = async () => {
      try {
        // const response = await fetch(
        //   `${apiUrl}vendor=${selectedBrand}&req=model`,
        //   apiHeaders
        // );
        // const jsonResponse = await response.json();
        const jsonResponse = await fetchWithTimeout(
          `${apiUrl}vendor=${selectedBrand}&req=model`,
          5000,
          2000
        );
        const models = parseModels(jsonResponse);
        setModels(models);
      } catch (error) {
        console.log(error);
        setModels([]);
      }
    };

    if (selectedBrand) {
      fetchModels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, selectedBrand]);

  useEffect(() => {
    const fetchYears = async () => {
      try {
        // const response = await fetch(
        //   `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&req=year`,
        //   apiHeaders
        // );
        // const jsonResponse = await response.json();
        const jsonResponse = await fetchWithTimeout(
          `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&req=year`,
          5000,
          2000
        );

        const years = parseYears(jsonResponse);
        setYears(years);
      } catch (error) {
        setYears([]);
      }
    };

    if (selectedModel && selectedBrand) {
      fetchYears();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, selectedBrand, selectedModel]);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        // const response = await fetch(
        //   `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&year=${selectedYear}&req=version`,
        //   apiHeaders
        // );
        // const jsonResponse = await response.json();
        const jsonResponse = await fetchWithTimeout(
          `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&year=${selectedYear}&req=version`,
          5000,
          2000
        );
        const versions = parseVersions(jsonResponse);
        setVersions(versions);
      } catch (error) {
        setVersions([]);
      }
    };

    if (selectedYear && selectedModel && selectedBrand) {
      fetchVersions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, selectedYear, selectedBrand, selectedModel]);

  useEffect(() => {
    const fetchFitments = async () => {
      try {
        // const response = await fetch(
        //   `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&year=${selectedYear}&version=${selectedVersion}&req=frontSizeDesignation`,
        //   apiHeaders
        // );
        // const jsonResponse = await response.json();
        const jsonResponse = await fetchWithTimeout(
          `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&year=${selectedYear}&version=${selectedVersion}&req=frontSizeDesignation`,
          5000,
          2000
        );
        const fitments = parseFitments(jsonResponse);
        setFitments(fitments);
      } catch (error) {
        setFitments([]);
      }
    };

    if (selectedVersion && selectedYear && selectedModel && selectedBrand) {
      fetchFitments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiUrl, selectedVersion, selectedBrand, selectedModel, selectedYear]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // const response = await fetch(
        //   `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&year=${selectedYear}&version=${selectedVersion}&frontSizeDesignation=${selectedFitment}&products=true&articles=true`,
        //   apiHeaders
        // );
        // const jsonResponse = await response.json();
        const jsonResponse = await fetchWithTimeout(
          `${apiUrl}vendor=${selectedBrand}&model=${selectedModel}&year=${selectedYear}&version=${selectedVersion}&frontSizeDesignation=${selectedFitment}&products=true&articles=true`,
          5000,
          2000
        );
        const eanNumbersFromApi = getEanFromProducts(jsonResponse);
        if (onValueChange) {
          if (
            !(
              newEanNumbers.length === eanNumbersFromApi.length &&
              newEanNumbers.every((item) => eanNumbersFromApi.includes(item)) &&
              eanNumbersFromApi.every((item) => newEanNumbers.includes(item))
            )
          ) {
            onValueChange('eanNumbers', eanNumbersFromApi);
            setNewEanNumbers(eanNumbersFromApi);
          }
        } else {
          setNewEanNumbers(eanNumbersFromApi);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (
      selectedFitment &&
      selectedVersion &&
      selectedYear &&
      selectedModel &&
      selectedBrand
    ) {
      fetchProducts();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFitment]);

  const getProducts = async () => {
    onSearchByModel({
      brand: selectedBrand,
      model: selectedModel,
      year: selectedYear,
      version: selectedVersion,
      fitment: selectedFitment,
      eanNumbers: newEanNumbers,
    });
  };

  return (
    <SearchByModelContainer>
      <Text fontSize="18px">Izberite znamko in model avtomobila</Text>
      <InputsContainer>
        <SelectContainer>
          <Select
            showBorder={false}
            value={selectedBrand}
            onChange={(value) => {
              setSelectedBrand(value);
              setSelectedModel('');
              setSelectedVersion('');
              setSelectedYear('');
              setSelectedFitment('');
              if (hideSearchButton) {
                onValueChange('vehicleBrand', value);
              }
            }}
            options={brands}
          >
            Znamka
          </Select>
        </SelectContainer>
        <SelectContainer disabled={!selectedBrand}>
          <Select
            disabled={!selectedBrand}
            showBorder={false}
            value={selectedModel}
            onChange={(value) => {
              setSelectedModel(value);
              setSelectedVersion('');
              setSelectedYear('');
              setSelectedFitment('');
              if (hideSearchButton) {
                onValueChange('vehicleModel', value);
              }
            }}
            options={models}
          >
            Model
          </Select>
        </SelectContainer>
        <SelectContainer disabled={!selectedModel}>
          <Select
            disabled={!selectedModel}
            showBorder={false}
            value={selectedYear}
            onChange={(value) => {
              setSelectedYear(value);
              setSelectedVersion('');
              setSelectedFitment('');
              if (hideSearchButton) {
                onValueChange('vehicleYear', value);
              }
            }}
            options={years}
          >
            Leto
          </Select>
        </SelectContainer>
        <SelectContainer disabled={!selectedYear}>
          <Select
            disabled={!selectedYear}
            showBorder={false}
            value={selectedVersion}
            onChange={(value) => {
              setSelectedVersion(value);
              setSelectedFitment('');
              if (hideSearchButton) {
                onValueChange('vehicleVersion', value);
              }
            }}
            options={versions}
          >
            Verzija
          </Select>
        </SelectContainer>
        <SelectContainer disabled={!selectedVersion}>
          <Select
            disabled={!selectedVersion}
            showBorder={false}
            value={selectedFitment}
            onChange={(value) => {
              setSelectedFitment(value);
              if (hideSearchButton) {
                onValueChange('vehicleFitment', value);
              }
            }}
            options={fitments}
          >
            Fitment
          </Select>
        </SelectContainer>

        {!hideSearchButton && (
          <Button
            disabled={!selectedFitment}
            onClick={() => {
              getProducts();
            }}
          >
            Išči
          </Button>
        )}
      </InputsContainer>
    </SearchByModelContainer>
  );
};

SearchByModel.propTypes = {
  hideSearchButton: PropTypes.bool,
  onSearchByModel: PropTypes.func,
  onValueChange: PropTypes.func,
};

export default connect((state) => ({
  brand: state.vehicle.vehicleBrand,
  model: state.vehicle.vehicleModel,
  year: state.vehicle.vehicleYear,
  version: state.vehicle.vehicleVersion,
  fitment: state.vehicle.vehicleFitment,
  eanNumbers: state.vehicle.eanNumbers,
}))(SearchByModel);
