import styled from 'styled-components';
import PropTypes from 'prop-types';

// Colors
import * as colors from '../../style/colors';

const TextDiv = styled.div`
  padding: 10px 0px;
  color: ${({color}) => (color === 'inherit' ? 'inherit' : colors[color])};
  font-weight: ${({weight}) => weight};
  font-size: ${({fontSize}) => fontSize};
  text-align: ${({align}) => align};
  background-color: ${({bgColor}) => bgColor};
`;

TextDiv.propTypes = {
  color: PropTypes.oneOf(['inherit', ...Object.keys(colors)]),
  weight: PropTypes.oneOf(['inherit', 'normal', 'bold']),
  fontSize: PropTypes.string,
  align: PropTypes.oneOf(['inherit', 'left', 'center', 'right']),
  bgColor: PropTypes.oneOf(['inherit', ...Object.keys(colors)]),
};

TextDiv.defaultProps = {
  color: 'inherit',
  weight: 'inherit',
  fontSize: 'inherit',
  align: 'inherit',
  bgColor: 'inherit',
};

export default TextDiv;
