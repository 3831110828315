import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container from './components/Container';
import Button from './components/Button';

const RemoveFromCart = ({onClick, id}) => (
  <Container>
    <Button className={`mdi mdi-delete`} onClick={() => onClick(id)}></Button>
  </Container>
);

RemoveFromCart.propTypes = {
  id: PropTypes.string,
  onClick: PropTypes.func,
};

export default RemoveFromCart;
