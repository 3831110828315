import React from 'react';
import PropTypes from 'prop-types';

// Components
import Container, {
  InputButtonContainer,
  StockText,
} from './components/Container';
import Input from '../../components/Input/Input';
import Button from './components/Button';

const AddToCart = ({
  id,
  value,
  stock,
  onQuantityChange,
  onAddToCart,
  addToCartLoading,
}) => (
  <Container>
    {getStockText(stock)}
    <InputButtonContainer>
      <Input
        value={value}
        onChange={(value) => onQuantityChange(id, value)}
        enterPressed={() => onAddToCart(id)}
        type="number"
        rounded={false}
        width="50px"
        center
      >
        {' '}
      </Input>
      <Button
        disabled={addToCartLoading || value === null || value === undefined}
        className={`mdi ${
          addToCartLoading ? 'mdi-loading mdi-spin' : 'mdi-cart'
        }`}
        onClick={() => onAddToCart(id)}
      ></Button>
    </InputButtonContainer>
  </Container>
);

const getStockText = (stock) => {
  switch (true) {
    case stock === 0:
      return <StockText color="red">Pošlji povpraševanje</StockText>;
    case stock === 1:
    case stock === 2:
    case stock === 3:
      return <StockText color="contiOrange">Zaloga: {stock}</StockText>;
    case stock === 4:
      return <StockText color="green">Zaloga: {stock}</StockText>;
    case stock > 4:
      return <StockText color="green">Zaloga: 4+</StockText>;

    default:
      return <StockText color="red">Ni na zalogi</StockText>;
  }
};

AddToCart.propTypes = {
  value: PropTypes.number,
  onQuantityChange: PropTypes.func,
  stock: PropTypes.number,
  onAddToCart: PropTypes.func,
  addToCartLoading: PropTypes.bool,
  deliveryDate: PropTypes.string,
};

export default AddToCart;
