import styled from "styled-components";
import { black, darkGrey } from "../../../style/colors";

const Text = styled.div`
  transition: all 0.5s ease-in-out 0s;
  margin-top: 5px;
  color: ${({ selected }) => (selected ? black : darkGrey)};
  font-weight: 400;
  font-size: 14px;
`;
export default Text;
