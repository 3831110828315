import styled from 'styled-components';

const InputContainer = styled.div`
  width: 100%;
  height: 30px;
  max-height: 30px;
  min-height: 30px;
  position: relative;
`;

export default InputContainer;
