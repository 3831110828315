import styled from "styled-components";
import { white } from "../../../style/colors";

const Container = styled.div`
  background: ${white};
  margin-top: 20px;
  padding: 30px;
  width: 100%;
  min-height: 10vh;
  display: flex;
`;

export default Container;
