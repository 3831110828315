import styled from 'styled-components';

// Colors
import {orange, white} from '../../../../style/colors';

const LoginButton = styled.button`
  background-color: ${orange};
  color: ${white};
  font-size: 18px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  margin-top: 10px;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

export default LoginButton;
