import styled from 'styled-components';

const Label = styled.div`
  margin-top: 20px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
`;

export default Label;
