import styled from 'styled-components';

// Colors
import {black} from '../../../../style/colors';

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${black};
`;

export default Container;
