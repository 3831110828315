import styled, { css } from "styled-components";

// Colors
import { black, darkGrey, orange } from "../../../style/colors";

const fontSize = ({ headerSize }) => {
  switch (headerSize) {
    case "small":
      return css`
        color: ${black};
        font-size: 11px;
        font-weight: 300;
      `;
    case "medium":
      return css`
        color: ${black};
        font-size: 14px;
        font-weight: 300;
      `;
    case "large":
      return css`
        color: ${black};
        font-size: 14px;
        font-weight: 700;
      `;
    default:
      return css`
        color: ${darkGrey};
        font-size: 11px;
        font-weight: 300;
      `;
  }
};

const Header = styled.div`
  color: ${black};
  width: 100%;
  display: flex;
  position: relative;
  background: ${orange};
  user-select: none;
  margin-bottom: 1px;
  padding-left: ${({ labelSpace }) => (labelSpace ? "50px" : "0px")};
  ${fontSize};
`;

export default Header;
