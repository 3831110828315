import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 100px 20px;
`;

export default Container;
